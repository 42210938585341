import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Psycology() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
                fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const zm={
            boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
           }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="psycology">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Psychology<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/psy1.jfif"}/>Psychological Assessment and Diagnosis</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Mental Health Evaluation</span>: Conducting thorough assessments of patients' psychological functioning to diagnose mental health disorders such as depression, anxiety, schizophrenia, and bipolar disorder.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cognitive and Neuropsychological Testing</span>:Assessing memory, problem-solving, attention, and other cognitive abilities, especially for patients with brain injuries, neurodegenerative diseases, or developmental disorders.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Personality Assessments</span>: Evaluating personality traits and characteristics to understand the patient’s behavior, thoughts, and emotions.</CListGroupItem>
                       </CListGroup>
                       </CCol>
                       <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/psy2.jfif"}/>Individual Therapy</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cognitive Behavioral Therapy (CBT)</span>: A common type of therapy that helps individuals change negative thought patterns and behaviors.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Psychodynamic Therapy</span>: Focuses on exploring unconscious thoughts and emotions to understand past experiences that affect current behavior.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Dialectical Behavioral Therapy (DBT)</span>:  A therapy designed to help people manage intense emotions and develop healthier coping mechanisms, especially useful for borderline personality disorder.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Trauma-focused Therapy</span>: Specialized therapy to help individuals cope with the effects of traumatic events.</CListGroupItem>
                       </CListGroup>
                       </CCol>
                       <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/psy3.jfif"}/>Group Therapy</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Support Groups</span>: Patients with similar issues (e.g., addiction, depression, eating disorders) come together for therapy in a group setting.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cognitive-Behavioral Group Therapy (CBGT)</span>: Group-based therapy focusing on changing negative thoughts and behaviors through shared experiences.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Family and Couples Therapy</span>: Therapy to address relationship issues and dynamics within families or couples dealing with mental health problems.</CListGroupItem>
                         </CListGroup>
                        </CCol>
                        <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/psy4.jfif"}/>Crisis Intervention and Emergency Services</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Suicide Prevention</span>: Immediate intervention for individuals at risk of suicide or self-harm.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Emergency Psychiatric Care</span>: Crisis intervention for patients experiencing acute psychological distress, such as psychosis or severe anxiety.</CListGroupItem>
                         </CListGroup>
                        </CCol>
                        <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/rehab.jfif"}/>Rehabilitation and Support for Chronic Illness</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Psycho-Oncology</span>: Psychological support for cancer patients and their families, helping them cope with the emotional and psychological impact of cancer.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Pain Management</span>: Psychological approaches to help patients manage chronic pain, including coping strategies and relaxation techniques.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cardiac Psychology</span>: Assisting patients recovering from heart attacks or heart surgery, helping them manage anxiety and depression often associated with cardiovascular conditions.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Neurological and Stroke Rehabilitation</span>: Psychological support for patients recovering from brain injuries, strokes, or neurological conditions, often including cognitive retraining and emotional adjustment therapy.</CListGroupItem>
                         </CListGroup>
                        </CCol>
                        <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/addiction.jfiff"}/>Addiction Counseling</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Substance Abuse Counseling</span>: Therapy and support for individuals dealing with alcohol, drug, or prescription medication addiction.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Behavioral Addiction Therapy</span>: Addressing other types of addictions, such as gambling, internet, or food addiction.</CListGroupItem>
                         </CListGroup>
                        </CCol>
                        <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/adoles.jfif"}/>Pediatric and Adolescent Services</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Child and Adolescent Therapy</span>: Specialized therapy for children and teenagers dealing with anxiety, depression, ADHD, or other behavioral disorders.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Developmental Assessments</span>: Evaluating developmental delays or learning difficulties in children.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Parental Guidance Counseling</span>: Helping parents manage behavioral issues in children or adjust to a child’s mental health diagnosis.</CListGroupItem>
                         </CListGroup>
                         </CCol>
                         <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/psycology.png"}/>Consultation-Liaison Psychology (Health Psychology)</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Psychological Support for Medical Patients</span>: Providing counseling and support for patients dealing with chronic illnesses, surgery, or life-threatening medical conditions.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Pre- and Post-Surgical Counseling</span>: Helping patients manage anxiety, depression, or emotional stress before and after major surgeries.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Behavioral Medicine</span>: Addressing behavioral and lifestyle changes that affect medical conditions, such as diabetes management, hypertension, or weight management.</CListGroupItem>
                         </CListGroup>
                         </CCol>
                         <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/rd.jfif"}/>Research and Education</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Clinical Research</span>: Conducting studies to advance the understanding of mental health disorders and the efficacy of various therapies.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Training and Education</span>: Educating medical students, residents, and other healthcare professionals about psychological principles and mental health issues.</CListGroupItem>
                         </CListGroup>
                         </CCol>
                         <CCol xs={12} style={zm}>
                         <CListGroup><h5><CImage style={lmc} fluid src={"http://localhost/Hospital/image/preventive.jfif"}/>Community Outreach and Preventive Care</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Mental Health Awareness Programs</span>: Educating the community about mental health issues and reducing the stigma associated with mental illness.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Preventive Care</span>: Offering workshops, seminars, and screenings to promote mental well-being and prevent mental health problems.</CListGroupItem>
                         </CListGroup>
                         </CCol>
                   </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Psycology;