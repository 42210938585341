import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Gastroentrology() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
                fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="gastroentrology">
       {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Gastroenterology<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                         <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/gastrology.png"}/>Our Gastroenterology Department is well equiped to deal with:</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Diagnosis</span>: Utilizing tools like endoscopy, colonoscopy, and imaging techniques to detect conditions such as ulcers, cancers, or inflammatory bowel disease.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Treatment</span>: Management of conditions such as acid reflux, Crohn’s disease, ulcerative colitis, irritable bowel syndrome (IBS), liver diseases (like hepatitis), and pancreatic disorders.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Patient Care</span>: Offering both inpatient and outpatient services for individuals with chronic digestive issues, cancers, and infections.</CListGroupItem>
                       </CListGroup>
                       </CCol>
                   </CRow>
                   <CRow>
                       <CCol xs={12} style={{padding:'6%'}}>
                         <CListGroup><h5 style={lml}><span style={lml}>Procedure:</span><span style={{color:'black'}}>Performing specialized interventions such as</span>:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Endoscopy</span>: A camera is used to visualize the inside of the upper GI tract.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Colonoscopy</span>: Examination of the colon to detect polyps, cancer, or other abnormalities.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>ERCP (Endoscopic Retrograde Cholangiopancreatography)</span>: To diagnose and treat bile duct and pancreatic duct conditions.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Biopsies</span>: Taking tissue samples from the digestive tract for further analysis.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Gastroentrology;