import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import JSAlert from 'js-alert';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import React,{ useState, useEffect }  from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { CWidgetStatsB,CWidgetStatsF,CFormSelect,CCard,CCardImage,CCardBody,CCardTitle,CCardText,CFormCheck,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { MdContactPhone,MdManageAccounts,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdHealthAndSafety,MdOutlineMail,MdOutlineRssFeed,MdLocalPharmacy,MdDoneOutline,MdElderly,MdOutlineHome,MdMyLocation,MdOutlineReportProblem,MdOutlineWorkspacePremium,MdOutlineTrackChanges } from "react-icons/md";
import { FaHome,FaFileMedicalAlt,FaMobileAlt,FaFileMedical,FaHospitalAlt,FaWhatsapp,FaSave,FaSchool,FaRegUser,FaHistory,FaRegHospital } from "react-icons/fa";
import { TbAmbulance,TbMapPinCode } from "react-icons/tb";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { PiGavel,PiOfficeChair } from "react-icons/pi";
import { BiCustomize } from "react-icons/bi";
import {RiTwitterXFill} from "react-icons/ri";
import { GiTechnoHeart,GiMedicalDrip } from "react-icons/gi";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { FaNetworkWired,FaUserDoctor,FaHandHoldingMedical,FaStaffSnake,FaPersonCirclePlus,FaVialVirus }  from "react-icons/fa6";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import { SiRoamresearch } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import Footer from './footer.js';
function Hospital() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
    
    useEffect(() => {
      
        setIsLoaded(true);
    }, []);
    
    useEffect(() => {//on load
        if (isLoaded) {
            axios.post('http://localhost/hospital/php/country_code.php',
        ).then(res=>
        {
            var lgn=res.data;
         
            $("#ccode").empty();
            $("#ccode").append('<option selected>Code</option>');
            if(lgn!=null){
                var gpl=lgn.length;
                for(var i=0;i<gpl;i++){
                var tmu=lgn[i];
                $("#ccode").append('<option>'+tmu[0]+"("+tmu[2]+")"+tmu[3]+'</option>')
                }
            }
         /* var scrn_width=window.screen.width;
          var col_dct=Math.round(0.125*scrn_width);
          document.getElementById("apt_txt").cols=col_dct;*/
          
        });
      
        }
        },[isLoaded]);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
     
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const rb={
        color:'green'
    }
      const cl_lg={
        // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
         borderRadius:'1%',
         width:'auto',
         float:'left',
         zIndex:'3999 !important',
         position:'fixed',
         bottom:'3.4%',
         left:'87.3%',
         height:'auto',
         color:'black',
         fontWeight:'700',
         cursor:'pointer'
       }
       const cl_lgw={
        // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
         borderRadius:'1%',
         width:'auto',
         float:'left',
         zIndex:'3999 !important',
         position:'fixed',
         bottom:'3.4%',
         left:'95.3%',
         height:'auto',
         color:'black',
         fontWeight:'700',
         cursor:'pointer'
       }
       const cl_lgw_tb={
        // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
         borderRadius:'1%',
         width:'auto',
         float:'left',
         zIndex:'3999 !important',
         position:'fixed',
         bottom:'3.4%',
         left:'92.0%',
         height:'auto',
         color:'black',
         fontWeight:'700',
         cursor:'pointer'
       }
       const cl_lg2={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'0.0%',  
        left:'47%', 
        width: '50px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'100px',
        borderTopRightRadius:'100px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg3={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'36%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg4={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'60%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg5={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'4.8%',  
        left:'48%',
        visibility:'hidden',
        display:'none', 
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       } 
       const hsptl_mbl_hdr={
        background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const hsptl_nm={
           fontFamily:'Baskerville',
           fontWeight:'700',
           textAlign:'center',
           fontSize:'1.0em'
       }
       const hsptl_nm_lptp={
        fontFamily:'Baskerville',
        fontWeight:'700',
        textAlign:'center',
        fontSize:'1.4em'
    }
       const hsptl_sldr={
        padding:'1%',   
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const ire={
        width:'100%',
        height:'80%',  
       }
       const wlppr1={
        width:'90%',
        height:'100%',
        paddingLeft:'1%',
        paddingTop:'1%',
        boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
       }
       const wlppr1b={
        width:'90%',
        height:'50%',
        paddingLeft:'1%',
        paddingTop:'1%',
        boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
       }
       const wlppr2={
        width:'90%',
        height:'100%',
        paddingLeft:'1%',
        paddingTop:'1%',
        boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
       }
       const mnu_icn={
        fontSize:'1.8em',
        color:'#FFD700',
        float:'right',
        position:'relative',
        fontWeight:'900'
    }
    const ftr={
        padding:'1%',
        fontSize:'0.69em',
        height:'2em',
        width:'100%',
        boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
        background:'linear-gradient(90deg, #C4D7FF, #87A2FF)',
        color:'black',
        fontWeight:'600'
      }
    const hsptl_apntmnt={
        width:'100%',
        height:'auto',
        padding:'1%',
        boxShadow:'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px'
    }
    const hsptl_departments={
        width:'100%',
        boxShadow:'rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        padding:'1%',
        height:'auto'
    }
    const lbl={
        background: 'linear-gradient(90deg, white,white)',   
        color:'black',
        fontWeight:'500',
        border:'none'
    }
    const lblx={
        background: 'linear-gradient(90deg, white,white)',  
        color:'black',
        fontSize:'0.70em',
        fontStyle:'italic',
        border:'none'
    }
    const apt_bx={
        border:'none',
        borderBottom:'1px outset black'
    }
    const icn_btn={
        fontSize:'1.0em',
        fontWeight:'700',
        width:'99%'
      }
      const lm={
          background:'linear-gradient(45deg, #6A9AB0 7%, white 7%)'
      }
      const mlm={
        background:'linear-gradient(-45deg, #6A9AB0 7%, white 7%)'
      }
      const okl={
          border:'none',
          textAlign:'left',
          background:'linear-gradient(90deg, #87A2FF,#87A2FF)',
          color:'white',
      }
      const ol1={
        fontWeight:'600',
        textAlign:'center',
        padding:'1%'
      }
     
      const oklv={
          display:'none',
          visibility:'hidden',
        border:'none',
        textAlign:'left',
        background:'linear-gradient(90deg, #87A2FF,#87A2FF)',
        color:'white',
    }
      const hsptl_facility={
         padding:'2%'
       }
       const dctr={
           color:'black',
           boxShadow:'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
       }
       const dctr_ql={
           color:'red',
           boxShadow:'rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset'
       }
       const dctr_exp={
           color:'blue',
           boxShadow:'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px 0px'
       }
       const dwn_mnu={
        textAlign:'left',   
        color:'white',
        fontFamily:'Lucida Sans',
        border:'none',
        background:'linear-gradient(90deg, #001F3F,#001F3F)'
       }
       const lptp_lst_vw={
        textAlign:'center',
        fontWeight:'600',
        border:'none',
       width:'60%',
        fontSize:'95%' ,
        textDecoration:'underline',
       cursor:'pointer'

      }
      const tblt_lst_vw={
        textAlign:'center',
        fontWeight:'600',
        border:'none',
       width:'60%',
        fontSize:'87%' ,
        textDecoration:'underline',
       cursor:'pointer'

      }
     
       function mdl(e){
        e.preventDefault();
        if($("#rd4").css('visibility')!='visible'){
        document.getElementById("rd4").style.visibility='visible';
        document.getElementById("rd4").style.display="inline-block";
        document.getElementById("rd6").style.visibility='visible';
        document.getElementById("rd6").style.display="inline-block";
        document.getElementById("rd5").style.visibility='visible';
        document.getElementById("rd5").style.display="inline-block";
        }
        else
        {
            document.getElementById("rd4").style.visibility='hidden';
            document.getElementById("rd4").style.display="none";
            document.getElementById("rd6").style.visibility='hidden';
            document.getElementById("rd6").style.display="none";
            document.getElementById("rd5").style.visibility='hidden';
            document.getElementById("rd5").style.display="none";
        }
        return false;
       }
       function ts2(e){
         e.preventDefault();
         $(".dde2").css({
             "visibility":"visible",
             "display":"inline-block"
         });
         $(".dde").css({
            "visibility":"hidden",
            "display":"none"
        });
         return false;
       }
       function ts1(e){
        e.preventDefault();   
        $(".dde").css({
            "visibility":"visible",
            "display":"inline-block"
        });
        $(".dde2").css({
           "visibility":"hidden",
           "display":"none"
       });
        return false;
       }
       function ts2v(e){
        e.preventDefault();
        $(".dde2v").css({
            "visibility":"visible",
            "display":"inline-block"
        });
        $(".ddev").css({
           "visibility":"hidden",
           "display":"none"
       });
        return false;
      }
      function ts1v(e){
       e.preventDefault();   
       $(".ddev").css({
           "visibility":"visible",
           "display":"inline-block"
       });
       $(".dde2v").css({
          "visibility":"hidden",
          "display":"none"
      });
       return false;
      }
      function ts3(e){
          e.preventDefault();
          $(".tmzv").css({
            "visibility":"visible",
            "display":"inline-block"
        });
        $(".tgzv").css({
           "visibility":"hidden",
           "display":"none"
       });
          return false;
      }
      function ts4(e){
        e.preventDefault();
        $(".tgzv").css({
            "visibility":"visible",
            "display":"inline-block"
        });
        $(".tmzv").css({
           "visibility":"hidden",
           "display":"none"
       });
        return false;
    }
    function ts5(e){
        e.preventDefault();
        $(".tt1").css({
          "visibility":"visible",
          "display":"inline-block"
      });
      $(".tt2").css({
         "visibility":"hidden",
         "display":"none"
     });
        return false;
    }
    function ts6(e){
      e.preventDefault();
      $(".tt2").css({
          "visibility":"visible",
          "display":"inline-block"
      });
      $(".tt1").css({
         "visibility":"hidden",
         "display":"none"
     });
      return false;
  }
    return (
        <div className="hospital">
     {isDesktopOrLaptop && (
         <>
        <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm_lptp}>Lifeline  Multi-Speciality Hospital  <CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
        </CContainer>
        <CContainer   id="lptp_vw" style={{padding:'0.6%',textAlign:'center'}}>
         <CListGroup layout={`horizontal`}>
         <CListGroupItem style={lptp_lst_vw}><FaFileMedical style={{color:'#D91656'}}/>&nbsp;Appointment</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><FaHandHoldingMedical style={{color:'#D91656'}}/>&nbsp;Departments</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><GiTechnoHeart style={{color:'#D91656'}}/>&nbsp;Facilities</CListGroupItem>
  <CListGroupItem style={lptp_lst_vw}><FaUserDoctor style={{color:'#D91656'}}/>&nbsp;Faculty</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><MdContactPhone style={{color:'#D91656'}}/>&nbsp;Contact</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><MdManageAccounts style={{color:'#D91656'}}/>&nbsp;Login</CListGroupItem>
    </CListGroup>
    </CContainer>
    <CContainer style={hsptl_sldr}>
                   <CCarousel controls transition="crossfade">
                   <CCarouselItem>
                   <CRow>
                   <CCol lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90"  style={wlppr1} src={"http://localhost/Hospital/image/i4.jpeg"} alt="hsptl_sldr_1" />
                   </CCol>
                   <CCol lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="One of the best mutlispeciality hospital in Lucknow,for your  every medical assistance and help round the clock"
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i9.jpeg"} alt="hsptl_sldr_2" />
                   </CCol>
                   <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="State of art equipment and highly experienced medical professionals dedicated for your well being."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i8.jpeg"} alt="hsptl_sldr_3" />
                   </CCol>
                   <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Maternity care for mother's and new born babies.24/7 monitoring of essential parameters ensuring best of health for mom and baby."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i11.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Cutting edge surgical and operational facility,doctors with world of experience available across all departments"
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i5.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Multibedded hospital with adequate resources to handle critical health services."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i6.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Healing Never Sleeps Here.Uninterrupted Care, Every Hour of Every Day."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  lg={12} xl={12} xxl={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i14.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Muti bedded  ICU units with Continuous Monitoring with  help of best in class devices and equipment."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>

                   </CCarousel>
    </CContainer>
    <CContainer style={hsptl_apntmnt}>
                   <CRow>
                        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Book Appointment<FaFileMedical style={{color:'#D91656'}}/></h5>
                        </CCol>
                    </CRow>
                      <CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                        <CInputGroup className="mb-3">
  <CInputGroupText id="basic-addon1" style={lbl}><FaPersonCirclePlus/>&nbsp;Name</CInputGroupText>
  <CFormInput placeholder="Enter your Full name" style={apt_bx} aria-label="Username" aria-describedby="basic-addon1"/>
</CInputGroup>
                        </CCol>
                        
                        <CCol lg={12} xl={12} xxl={12}>
                        <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1" style={lbl}><FaMobileAlt/>&nbsp;Mobile<select id="ccode" style={{fontSize:'0.8em',width:'50px',height:'40px',border:'none',borderBottom:'1px outset  black'}}></select></CInputGroupText>
                        <CFormInput placeholder="Enter your Mobile number" style={apt_bx} aria-label="Username" aria-describedby="basic-addon1"/>
                        </CInputGroup>
                        </CCol>
                        <CCol lg={12} xl={12} xxl={12}>
                        <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1" style={lbl}><FaStaffSnake/>&nbsp;Department</CInputGroupText>
                        <select style={{color:'black',fontSize:'0.8em',width:'62%',border:'none',borderBottom:'1px outset  black'}}><option selected>Select</option>
                        <option>Obstetrics & Gynaecology</option>
                        <option>Orthopedics & Spine</option>
                        <option>General Surgery</option>
                        <option>Gastroenterology</option>
                        <option>Pediatrics</option>
                        <option>Neurology</option>
                        <option>Urology</option>
                        <option>Oncology</option>
                        <option>Plastic Surgery</option>
                        <option>Cardiology</option>
                        <option>Dental</option>
                        <option>Psychology</option>
                        <option>ENT</option>
                        </select>
                        <CInputGroupText id="basic-addon1" style={lblx}>**Not Mandatory</CInputGroupText>
                        </CInputGroup>
                        </CCol>
                        <CCol lg={12} xl={12} xxl={12}  style={{textAlign:'left',fontSize:'0.8em',fontStyle:'italic'}}>
                        Reason[Word Limit]<span id="om1">(0)</span>/<span id="om2">(200)</span>
                        </CCol>
                        <CCol lg={12} xl={12} xxl={12}>
                           <textarea id="apt_txt" name="postContent" rows={9} cols={120} placeholder="If you could let us know about your troubles it would be great else allow us to help you out .Go ahead and click on Book Appointment." />
                        </CCol>
                        <CCol lg={12} xl={12} xxl={12}>
                        <CButton  color="primary" variant="outline" style={icn_btn}>Book</CButton>
                        </CCol>
                      </CRow>
                   </CContainer>
    <CContainer style={hsptl_departments}>
                   <CRow>
                        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Departments<FaHandHoldingMedical style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="primary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/gynacology_icon.png"}/>}
                     title="Obstetrics & Gynaecology"
                     footer={<Link to="/obstretic">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="warning"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/orthopadic_spine.png"}/>}
                     title="Orthopedics & Spine"
                     footer={<Link to="/orthopedic">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="success"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/surgery.png"}/>}
                     title="General Surgery"
                     footer={<Link to="/general_surgery">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                     <CRow>
                     <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/gastrology.png"}/>}
                     title="Gastroenterology"
                     footer={<Link to="/gastroenterology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="info"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/pediatric.png"}/>}
                     title="Pediatrics"
                     footer={<Link to="/pediatric">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="secondary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/ent.png"}/>}
                     title="ENT(Ear Nose Throat)"
                     footer={<Link to="/ent">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="primary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/neuorology.png"}/>}
                     title="Neurology"
                     footer={<Link to="/neurology">View more</Link>}
                     style={mlm}
                     />
                    </CCol> 
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="warning"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/urology.png"}/>}
                     title="Urology"
                     footer={<Link to="/urology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="success"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/oncology.png"}/>}
                     title="Oncology"
                     footer={<Link to="/oncology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                   
                    <CRow>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/cardiology.png"}/>}
                     title="Cardiology"
                     footer={<Link to="/cardiology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/plastic_surgery.png"}/>}
                     title="Plastic SUrgery"
                     footer={<Link to="/plastic_surgery">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="info"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/dental.png"}/>}
                     title="Dental"
                     footer={<Link to="/dental">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                   
                   </CRow>
                   <CRow>
                   <CCol lg={4} xl={4} xxl={4}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="secondary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/psycology.png"}/>}
                     title="Psychology"
                     footer={<Link to="/psycology">View more</Link>}
                     style={mlm}
                     />
                     </CCol>
                   </CRow>
                   </CContainer>
                   </CContainer>
                   <CContainer style={hsptl_facility}>
                   <CRow>
                        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Facilities<GiTechnoHeart style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol lg={12} xl={12} xxl={12} style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts1}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts2}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>     
                     <CListGroup>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Cardiac Monitoring</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Respiratory Failure</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Severe Trauma</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neurological Emergencies</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Hemodynamic Instability</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Post-Surgical Care</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Sepsis and Septic Shock</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Multi-Organ Failure</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Ventilators</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Heart Monitors</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Infusion Pumps</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Dialysis Machines</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Specialized Beds</CListGroupItem>
                         </CListGroup>
                         </>
                        }
                     title="ICU"/>
                   </CCol>
                   <CCol lg={12} xl={12} xxl={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts1v}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts2v}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>     
                     <CListGroup>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Premature Birth</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Respiratory Distress</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Low Birth Weight</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Infections</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Congenital Conditions</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Jaundice</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Hypoglycemia</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neurological Issues</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neonatal Abstinence Syndrome</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Post-Surgical Care</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Incubators</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Ventilators and CPAP</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Feeding Tubes</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Heart Monitors</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Phototherapy Lights</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;IV Lines</CListGroupItem>
                         </CListGroup>
                         </>
                        }
                     title="NICU"/> 
                   </CCol>
                   <CCol lg={12} xl={12} xxl={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts5}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts6}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>  
                     <CListGroup>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Diagnosing Heart Arrhythmias</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Chest Pain</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Detecting Heart Attacks</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Monitoring Heart Health</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Heart Function Post-Surgery</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Evaluating the Effects of Medication</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Detecting Electrolyte Imbalances</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Structural Abnormalities</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Pre-Surgical Evaluation</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Resting ECG</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Exercise (Stress) ECG</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Holter Monitor</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Event Monitor</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="ECG"/>
                    
                     </CCol>
                     <CCol lg={12} xl={12} xxl={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts3}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol lg={6} xl={6} xxl={6} style={ol1} onClick={ts4}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>  
                     <CListGroup>
                         <CListGroupItem className="tmzv" style={okl}><IoIosArrowDroprightCircle/>Pregnancy Monitoring</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Cardiac (Echocardiography)</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Abdominal Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Blood Flow (Doppler Ultrasound)</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Musculoskeletal Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Thyroid and Neck Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Pelvic Ultrasound</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Breast Ultrasound</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Vascular Access</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Gynecological Conditions</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>2D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>3D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>4D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Doppler Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Endoscopic Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Transvaginal/Transrectal Ultrasound</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="Ultrasound"/>
                     
                     </CCol>
                     <CCol lg={12} xl={12} xxl={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Nutritional Assessment</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Diet Planning</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Medical Nutrition Therapy (MNT)</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Monitoring Heart Health</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Nutritional Counseling</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Weight Management</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Managing Food Allergies and Intolerances</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Pediatric Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Sports Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Community Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Corporate Wellness Programs</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Eating Disorders</CListGroupItem>
                        
                      </CListGroup>   
                     </>}
                     title="Dietician"/>
                    
                     </CCol>
                     <CCol lg={12} xl={12} xxl={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Manual Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Exercise Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Electrotherapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Heat and Cold Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Hydrotherapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Kinesiology Taping</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Postural Training</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Gait Training</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Breathing Exercises</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Traction</CListGroupItem>
                        
                      </CListGroup>   
                     </>}
                     title="Physiotherapy"/>
                    
                     </CCol>
                     <CCol lg={12} xl={12} xxl={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Bone Fractures</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Arthritis</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Osteoporosis</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Dental Issues</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Chest Conditions</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Digestive System</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Radiation Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Plain X-ray</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Fluoroscopy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>CT Scans (Computed Tomography)</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Mammography</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Radiation Exposure</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Lead Shields</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="X-Ray"/>
                    
                     </CCol>
                   </CRow>
                   </CContainer>
                   </CContainer>
                   <CContainer style={hsptl_facility}>
                   <CRow>
                        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Faculty<FaUserDoctor style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sashank Nigam</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MS,DNB,MRCS,MNAMS</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Fellowship, Surgical Oncology<br/>,Oncoplastic Breast Surgery</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Dharamvir Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS,MCH(Urology)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Urologist,Laproscopic<br/>,Transplant Surgeon</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Abhishek Verma</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MS(General Surgeon),FIAGES</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={{visibility:'hidden'}}>a</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Rohit Saxena</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS(Opthalmologist)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={{visibility:'hidden'}}>a</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Ayush Pratap Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS, FSR, DSN</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Former Resident Doctor:<br/> Trauma & Orthopedics, PGI Lucknow
                      Emergency Medicine ,<br/>Dr. Baba Saheb Ambedkar Hospital, New Delhi.</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Nitesh Singh Sodi</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>Psychology D(Honors)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Neuro-psycologist,<br/> Counsellor& Psycotherapist</CBadge>
                      </CCol> 
                     </CRow> 
                     </CCol>
                     </CRow>
                   </CContainer>
                  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol lg={4} xl={4} xxl={4}>
                   <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Harish Kumar Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MD</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Physician</CBadge>
                      </CCol> 
                     </CRow>
                   </CCol>
                   <CCol lg={4} xl={4} xxl={4}>
                   <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Pankaj Pathak</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MDS(Oro-Maxicofasical)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Consultant Oro & Facial Trauma</CBadge>
                      </CCol> 
                     </CRow>
                   </CCol>
                   <CCol lg={4} xl={4} xxl={4}>
                   <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Pranjal Saxena</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>BDS(Dental Surgeon)</CBadge>
                      </CCol> 
                     </CRow>
                   </CCol>
                   </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Harsh Vardhan</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>BDS,Fage(Dental Surgeon)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>  
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sudhanshu Verma</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS(D.Cardiology)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_fem.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Ankita Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,FIGO(Gynecologist)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_fem.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Nilima</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Obstetrics& Gynecologist</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sharad Kumar Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MD(Pediatrics)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={4} xl={4} xxl={4}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Arshad Khalid</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS(Anaesthosiology)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     </CRow>
                   </CContainer>
                   </CContainer>
                   <CContainer fluid style={cl_lgw}>
            <CRow>
             <CCol  lg={12} xl={12} xxl={12} style={{background:'linear-gradient(90deg, white, white)'}}>
           <FaWhatsapp style={{color:'#006769',fontSize:'2.0em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
          <Footer/>
       
         </>
          )}
          {isTabletOrMobile && (
               <>
             <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm_lptp}>Lifeline  Multi-Speciality Hospital  <CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
        </CContainer>
        <CContainer fluid   id="lptp_vw" style={{padding:'0.6%',textAlign:'center'}}>
         <CListGroup layout={`horizontal`}>
         <CListGroupItem style={tblt_lst_vw}><FaFileMedical style={{color:'#D91656'}}/><br/>Appointment</CListGroupItem>
    <CListGroupItem style={tblt_lst_vw}><FaHandHoldingMedical style={{color:'#D91656'}}/><br/>Departments</CListGroupItem>
   <CListGroupItem style={tblt_lst_vw}><GiTechnoHeart style={{color:'#D91656'}}/><br/>Facilities</CListGroupItem>
  <CListGroupItem style={tblt_lst_vw}><FaUserDoctor style={{color:'#D91656'}}/><br/>Faculty</CListGroupItem>
   <CListGroupItem style={tblt_lst_vw}><MdContactPhone style={{color:'#D91656'}}/><br/>Contact</CListGroupItem>
   <CListGroupItem style={tblt_lst_vw}><MdManageAccounts style={{color:'#D91656'}}/><br/>Login</CListGroupItem>
    </CListGroup>
    </CContainer>
    <CContainer fluid style={hsptl_sldr}>
                   <CCarousel controls transition="crossfade">
                   <CCarouselItem>
                   <CRow>
                   <CCol sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90"  style={wlppr1} src={"http://localhost/Hospital/image/i4.jpeg"} alt="hsptl_sldr_1" />
                   </CCol>
                   <CCol sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="One of the best mutlispeciality hospital in Lucknow,for your  every medical assistance and help round the clock"
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i9.jpeg"} alt="hsptl_sldr_2" />
                   </CCol>
                   <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="State of art equipment and highly experienced medical professionals dedicated for your well being."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i8.jpeg"} alt="hsptl_sldr_3" />
                   </CCol>
                   <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Maternity care for mother's and new born babies.24/7 monitoring of essential parameters ensuring best of health for mom and baby."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i11.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Cutting edge surgical and operational facility,doctors with world of experience available across all departments"
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i5.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Multibedded hospital with adequate resources to handle critical health services."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i6.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Healing Never Sleeps Here.Uninterrupted Care, Every Hour of Every Day."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol  sm={12} md={12} lg={12} style={{height:'35em'}}>
                   <CImage fluid rounded className="d-inline w-90" style={wlppr1} src={"http://localhost/Hospital/image/i14.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol  sm={12} md={12} lg={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Muti bedded  ICU units with Continuous Monitoring with  help of best in class devices and equipment."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>

                   </CCarousel>
    </CContainer>
    <CContainer fluid style={hsptl_apntmnt}>
                   <CRow>
                        <CCol sm={12} md={12} lg={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Book Appointment<FaFileMedical style={{color:'#D91656'}}/></h5>
                        </CCol>
                    </CRow>
                      <CRow>
                        <CCol sm={12} md={12} lg={12}>
                        <CInputGroup className="mb-3">
  <CInputGroupText id="basic-addon1" style={lbl}><FaPersonCirclePlus/>&nbsp;Name</CInputGroupText>
  <CFormInput placeholder="Enter your Full name" style={apt_bx} aria-label="Username" aria-describedby="basic-addon1"/>
</CInputGroup>
                        </CCol>
                        
                        <CCol sm={12} md={12} lg={12}>
                        <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1" style={lbl}><FaMobileAlt/>&nbsp;Mobile<select id="ccode" style={{fontSize:'0.8em',width:'50px',height:'40px',border:'none',borderBottom:'1px outset  black'}}></select></CInputGroupText>
                        <CFormInput placeholder="Enter your Mobile number" style={apt_bx} aria-label="Username" aria-describedby="basic-addon1"/>
                        </CInputGroup>
                        </CCol>
                        <CCol sm={12} md={12} lg={12}>
                        <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1" style={lbl}><FaStaffSnake/>&nbsp;Department</CInputGroupText>
                        <select style={{color:'black',fontSize:'0.8em',width:'62%',border:'none',borderBottom:'1px outset  black'}}><option selected>Select</option>
                        <option>Obstetrics & Gynaecology</option>
                        <option>Orthopedics & Spine</option>
                        <option>General Surgery</option>
                        <option>Gastroenterology</option>
                        <option>Pediatrics</option>
                        <option>Neurology</option>
                        <option>Urology</option>
                        <option>Oncology</option>
                        <option>Plastic Surgery</option>
                        <option>Cardiology</option>
                        <option>Dental</option>
                        <option>Psychology</option>
                        <option>ENT</option>
                        </select>
                        <CInputGroupText id="basic-addon1" style={lblx}>**Not Mandatory</CInputGroupText>
                        </CInputGroup>
                        </CCol>
                        <CCol sm={12} md={12} lg={12}  style={{textAlign:'left',fontSize:'0.8em',fontStyle:'italic'}}>
                        Reason[Word Limit]<span id="om1">(0)</span>/<span id="om2">(200)</span>
                        </CCol>
                        <CCol sm={12} md={12} lg={12}>
                           <textarea id="apt_txt" name="postContent" rows={9} cols={70} placeholder="If you could let us know about your troubles it would be great else allow us to help you out .Go ahead and click on Book Appointment." />
                        </CCol>
                        <CCol sm={12} md={12} lg={12}>
                        <CButton  color="primary" variant="outline" style={icn_btn}>Book</CButton>
                        </CCol>
                      </CRow>
                   </CContainer>
    <CContainer fluid style={hsptl_departments}>
                   <CRow>
                        <CCol sm={12} md={12} lg={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Departments<FaHandHoldingMedical style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="primary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/gynacology_icon.png"}/>}
                     title="Obstetrics & Gynaecology"
                     footer={<Link to="/obstretic">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="warning"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/orthopadic_spine.png"}/>}
                     title="Orthopedics & Spine"
                     footer={<Link to="/orthopedic">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                   
                    </CRow>
                     <CRow>
                     <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="success"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/surgery.png"}/>}
                     title="General Surgery"
                     footer={<Link to="/general_surgery">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                     <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/gastrology.png"}/>}
                     title="Gastroenterology"
                     footer={<Link to="/gastroenterology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="info"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/pediatric.png"}/>}
                     title="Pediatrics"
                     footer={<Link to="/pediatric">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="secondary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/ent.png"}/>}
                     title="ENT(Ear Nose Throat)"
                     footer={<Link to="/ent">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                  
                    </CRow>
                   
                    <CRow>
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="primary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/neuorology.png"}/>}
                     title="Neurology"
                     footer={<Link to="/neurology">View more</Link>}
                     style={mlm}
                     />
                    </CCol> 
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="warning"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/urology.png"}/>}
                     title="Urology"
                     footer={<Link to="/urology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                   
                   </CRow>
                   <CRow>
                   <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="success"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/oncology.png"}/>}
                     title="Oncology"
                     footer={<Link to="/oncology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/cardiology.png"}/>}
                     title="Cardiology"
                     footer={<Link to="/cardiology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                   
                   </CRow>
                   <CRow>
                   <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/plastic_surgery.png"}/>}
                     title="Plastic SUrgery"
                     footer={<Link to="/plastic_surgery">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="info"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/dental.png"}/>}
                     title="Dental"
                     footer={<Link to="/dental">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                   </CRow>
                   <CRow>
                   <CCol sm={3} md={3} lg={3} style={{visibility:'hidden'}}>
                   a
                   </CCol>
                   <CCol sm={6} md={6} lg={6}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="secondary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/psycology.png"}/>}
                     title="Psychology"
                     footer={<Link to="/psycology">View more</Link>}
                     style={mlm}
                     />
                     </CCol>
                     <CCol sm={3} md={3} lg={3} style={{visibility:'hidden'}}>
                   a
                   </CCol>
                   </CRow>
                   </CContainer>
                   </CContainer>
                   <CContainer fluid style={hsptl_facility}>
                   <CRow>
                        <CCol sm={12} md={12} lg={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Facilities<GiTechnoHeart style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol sm={12} md={12} lg={12} style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts1}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts2}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>     
                     <CListGroup>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Cardiac Monitoring</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Respiratory Failure</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Severe Trauma</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neurological Emergencies</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Hemodynamic Instability</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Post-Surgical Care</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Sepsis and Septic Shock</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Multi-Organ Failure</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Ventilators</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Heart Monitors</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Infusion Pumps</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Dialysis Machines</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Specialized Beds</CListGroupItem>
                         </CListGroup>
                         </>
                        }
                     title="ICU"/>
                   </CCol>
                   <CCol sm={12} md={12} lg={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts1v}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts2v}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>     
                     <CListGroup>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Premature Birth</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Respiratory Distress</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Low Birth Weight</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Infections</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Congenital Conditions</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Jaundice</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Hypoglycemia</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neurological Issues</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neonatal Abstinence Syndrome</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Post-Surgical Care</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Incubators</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Ventilators and CPAP</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Feeding Tubes</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Heart Monitors</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Phototherapy Lights</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;IV Lines</CListGroupItem>
                         </CListGroup>
                         </>
                        }
                     title="NICU"/> 
                   </CCol>
                   <CCol sm={12} md={12} lg={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts5}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts6}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>  
                     <CListGroup>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Diagnosing Heart Arrhythmias</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Chest Pain</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Detecting Heart Attacks</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Monitoring Heart Health</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Heart Function Post-Surgery</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Evaluating the Effects of Medication</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Detecting Electrolyte Imbalances</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Structural Abnormalities</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Pre-Surgical Evaluation</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Resting ECG</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Exercise (Stress) ECG</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Holter Monitor</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Event Monitor</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="ECG"/>
                    
                     </CCol>
                     <CCol sm={12} md={12} lg={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts3}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol sm={6} md={6} lg={6} style={ol1} onClick={ts4}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>  
                     <CListGroup>
                         <CListGroupItem className="tmzv" style={okl}><IoIosArrowDroprightCircle/>Pregnancy Monitoring</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Cardiac (Echocardiography)</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Abdominal Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Blood Flow (Doppler Ultrasound)</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Musculoskeletal Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Thyroid and Neck Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Pelvic Ultrasound</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Breast Ultrasound</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Vascular Access</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Gynecological Conditions</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>2D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>3D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>4D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Doppler Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Endoscopic Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Transvaginal/Transrectal Ultrasound</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="Ultrasound"/>
                     
                     </CCol>
                     <CCol sm={12} md={12} lg={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Nutritional Assessment</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Diet Planning</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Medical Nutrition Therapy (MNT)</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Monitoring Heart Health</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Nutritional Counseling</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Weight Management</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Managing Food Allergies and Intolerances</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Pediatric Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Sports Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Community Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Corporate Wellness Programs</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Eating Disorders</CListGroupItem>
                        
                      </CListGroup>   
                     </>}
                     title="Dietician"/>
                    
                     </CCol>
                     <CCol sm={12} md={12} lg={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Manual Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Exercise Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Electrotherapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Heat and Cold Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Hydrotherapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Kinesiology Taping</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Postural Training</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Gait Training</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Breathing Exercises</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Traction</CListGroupItem>
                        
                      </CListGroup>   
                     </>}
                     title="Physiotherapy"/>
                    
                     </CCol>
                     <CCol sm={12} md={12} lg={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Bone Fractures</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Arthritis</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Osteoporosis</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Dental Issues</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Chest Conditions</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Digestive System</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Radiation Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Plain X-ray</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Fluoroscopy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>CT Scans (Computed Tomography)</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Mammography</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Radiation Exposure</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Lead Shields</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="X-Ray"/>
                    
                     </CCol>
                   </CRow>
                   </CContainer>
                   </CContainer>
                   <CContainer fluid style={hsptl_facility}>
                   <CRow>
                        <CCol sm={12} md={12} lg={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Faculty<FaUserDoctor style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sashank Nigam</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MS,DNB,MRCS,MNAMS</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Fellowship, Surgical Oncology,<br/>Oncoplastic Breast Surgery</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Dharamvir Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS,MCH(Urology)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Urologist,Laproscopic,<br/>Transplant Surgeon</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                    
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Abhishek Verma</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MS(General Surgeon),FIAGES</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={{visibility:'hidden'}}>a</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                   <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Rohit Saxena</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS(Opthalmologist)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={{visibility:'hidden'}}>a</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     
                     </CRow>
                   </CContainer>
                  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Ayush Pratap Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS, FSR, DSN</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Former Resident Doctor:<br/> Trauma & Orthopedics,<br/> PGI Lucknow
Emergency Medicine ,<br/>Dr. Baba Saheb Ambedkar Hospital,<br/> New Delhi.</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Nitesh Singh Sodi</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>Psychology D(Honors)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Neuro-psycologist,<br/> Counsellor& Psycotherapist</CBadge>
                      </CCol> 
                     </CRow> 
                     </CCol>
                   
                   </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol sm={6} md={6} lg={6}>
                   <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Harish Kumar Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MD</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Physician</CBadge>
                      </CCol> 
                     </CRow>
                   </CCol>
                   <CCol sm={6} md={6} lg={6}>
                   <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Pankaj Pathak</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MDS(Oro-Maxicofasical)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Consultant Oro & Facial Trauma</CBadge>
                      </CCol> 
                     </CRow>
                   </CCol>
                   
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol sm={6} md={6} lg={6}>
                   <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Pranjal Saxena</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>BDS(Dental Surgeon)</CBadge>
                      </CCol> 
                     </CRow>
                   </CCol>
                     <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Harsh Vardhan</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>BDS,Fage(Dental Surgeon)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>  
                   
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
    <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sudhanshu Verma</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS(D.Cardiology)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
  <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_fem.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Ankita Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,FIGO(Gynecologist)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     </CRow>
                     </CContainer>
                     <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_fem.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Nilima</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Obstetrics& Gynecologist</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol sm={6} md={6} lg={6}>
                     <CRow>
                     <CCol sm={12} md={12} lg={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sharad Kumar Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MD(Pediatrics)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                      </CRow>
                      </CContainer>
                      <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol lg={3} xl={3} xxl={3} style={{visibility:'hidden',display:'none'}}>a</CCol>
                     <CCol lg={6} xl={6} xxl={6}>
                     <CRow>
                     <CCol lg={12} xl={12} xxl={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Arshad Khalid</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS(Anaesthosiology)</CBadge>
                      </CCol> 
                     </CRow>
                     </CCol>
                     <CCol lg={3} xl={3} xxl={3} style={{visibility:'hidden',display:'none'}}>a</CCol>
                      </CRow>
                      </CContainer>
                   </CContainer>
                   <CContainer fluid style={cl_lgw_tb}>
            <CRow>
             <CCol  sm={12} md={12} lg={12} style={{background:'linear-gradient(90deg, white, white)'}}>
           <FaWhatsapp style={{color:'#006769',fontSize:'1.8em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
            <Footer/>
               </>
              )}
              {isMobile && (
                   <>
                   <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                   <CCarousel controls transition="crossfade">
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i4.jpeg"} alt="hsptl_sldr_1" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="One of the best mutlispeciality hospital in Lucknow,for your  every medical assistance and help round the clock"
                     />
                  </CCol>

                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i9.jpeg"} alt="hsptl_sldr_2" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="State of art equipment and highly experienced medical professionals dedicated for your well being."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i8.jpeg"} alt="hsptl_sldr_3" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Maternity care for mother's and new born babies.24/7 monitoring of essential parameters ensuring best of health for mom and baby."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i11.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Cutting edge surgical and operational facility,doctors with world of experience available across all departments"
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i5.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Multibedded hospital with adequate resources to handle critical health services."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i6.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Healing Never Sleeps Here.Uninterrupted Care, Every Hour of Every Day."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   <CCarouselItem>
                   <CRow>
                   <CCol xs={12}>
                   <CImage fluid rounded className="d-inline w-100" style={wlppr1} src={"http://localhost/Hospital/image/i14.jpeg"} alt="hsptl_sldr_4" />
                   </CCol>
                   <CCol xs={12} style={{textAlign:'left',paddingTop:'2%',fonWeight:'600',fontFamily:'Montserrat',fontSize:'0.85em'}}>
                   <CWidgetStatsF style={{background:'linear-gradient(45deg, #0d0d0d, #2a0038, #2a0038,  #0d0d0d)',color:'#b3ffff'}}
                     className="mb-3"
                     value="Muti bedded  ICU units with Continuous Monitoring with  help of best in class devices and equipment."
                     />
                  </CCol>
                   </CRow>
                   </CCarouselItem>
                   </CCarousel>
                   </CContainer>
                   <CContainer fluid style={hsptl_apntmnt}>
                   <CRow>
                        <CCol xs={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Book Appointment<FaFileMedical style={{color:'#D91656'}}/></h5>
                        </CCol>
                    </CRow>
                      <CRow>
                        <CCol xs={12}>
                        <CInputGroup className="mb-3">
  <CInputGroupText id="basic-addon1" style={lbl}><FaPersonCirclePlus/>&nbsp;Name</CInputGroupText>
  <CFormInput placeholder="Enter your Full name" style={apt_bx} aria-label="Username" aria-describedby="basic-addon1"/>
</CInputGroup>
                        </CCol>
                        
                        <CCol xs={12}>
                        <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1" style={lbl}><FaMobileAlt/>&nbsp;Mobile<select id="ccode" style={{fontSize:'0.8em',width:'50px',height:'40px',border:'none',borderBottom:'1px outset  black'}}></select></CInputGroupText>
                        <CFormInput placeholder="Enter your Mobile number" style={apt_bx} aria-label="Username" aria-describedby="basic-addon1"/>
                        </CInputGroup>
                        </CCol>
                        <CCol xs={12}>
                        <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1" style={lbl}><FaStaffSnake/>&nbsp;Department</CInputGroupText>
                        <select style={{color:'black',fontSize:'0.8em',width:'62%',border:'none',borderBottom:'1px outset  black'}}><option selected>Select</option>
                        <option>Obstetrics & Gynaecology</option>
                        <option>Orthopedics & Spine</option>
                        <option>General Surgery</option>
                        <option>Gastroenterology</option>
                        <option>Pediatrics</option>
                        <option>Neurology</option>
                        <option>Urology</option>
                        <option>Oncology</option>
                        <option>Plastic Surgery</option>
                        <option>Cardiology</option>
                        <option>Dental</option>
                        <option>Psychology</option>
                        <option>ENT</option>
                        </select>
                        <CInputGroupText id="basic-addon1" style={lblx}>**Not Mandatory</CInputGroupText>
                        </CInputGroup>
                        </CCol>
                        <CCol xs={12}  style={{textAlign:'left',fontSize:'0.8em',fontStyle:'italic'}}>
                        Reason[Word Limit]<span id="om1">(0)</span>/<span id="om2">(200)</span>
                        </CCol>
                        <CCol xs={12}>
                           <textarea id="apt_txt" name="postContent" rows={9} cols={50} placeholder="If you could let us know about your troubles it would be great else allow us to help you out .Go ahead and click on Book Appointment." />
                        </CCol>
                        <CCol xs={12}>
                        <CButton  color="primary" variant="outline" style={icn_btn}>Book</CButton>
                        </CCol>
                      </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_departments}>
                   <CRow>
                        <CCol xs={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Departments<FaHandHoldingMedical style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="primary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/gynacology_icon.png"}/>}
                     title="Obstetrics & Gynaecology"
                     footer={<Link to="/obstretic">View more</Link>}
                     style={lm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="warning"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/orthopadic_spine.png"}/>}
                     title="Orthopedics & Spine"
                     footer={<Link to="/orthopedic">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="success"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/surgery.png"}/>}
                     title="General Surgery"
                     footer={<Link to="/general_surgery">View more</Link>}
                     style={lm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/gastrology.png"}/>}
                     title="Gastroenterology"
                     footer={<Link to="/gastroenterology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="info"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/pediatric.png"}/>}
                     title="Pediatrics"
                     footer={<Link to="/pediatric">View more</Link>}
                     style={lm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="secondary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/ent.png"}/>}
                     title="ENT(Ear Nose Throat)"
                     footer={<Link to="/ent">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="primary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/neuorology.png"}/>}
                     title="Neurology"
                     footer={<Link to="/neurology">View more</Link>}
                     style={lm}
                     />
                    </CCol> 
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="warning"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/urology.png"}/>}
                     title="Urology"
                     footer={<Link to="/urology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="success"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/oncology.png"}/>}
                     title="Oncology"
                     footer={<Link to="/oncology">View more</Link>}
                     style={lm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/cardiology.png"}/>}
                     title="Cardiology"
                     footer={<Link to="/cardiology">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="danger"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/plastic_surgery.png"}/>}
                     title="Plastic SUrgery"
                     footer={<Link to="/plastic_surgery">View more</Link>}
                     style={lm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="info"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/dental.png"}/>}
                     title="Dental"
                     footer={<Link to="/dental">View more</Link>}
                     style={mlm}
                     />
                    </CCol>
                    </CRow>
                    <CRow>
                    <CCol xs={12}>
                    <CWidgetStatsF
                     className="mb-3"
                     color="secondary"
                     icon={<CImage fluid src={"http://localhost/Hospital/image/psycology.png"}/>}
                     title="Psychology"
                     footer={<Link to="/psycology">View more</Link>}
                     style={lm}
                     />
                     </CCol>
                   </CRow>
                   
                   </CContainer>
                   </CContainer>
                   <CContainer fluid style={hsptl_facility}>
                   <CRow>
                        <CCol xs={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Facilities<GiTechnoHeart style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                   <CRow>
                   <CCol xs={12} style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol xs={6} style={ol1} onClick={ts1}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol xs={6} style={ol1} onClick={ts2}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>     
                     <CListGroup>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Cardiac Monitoring</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Respiratory Failure</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Severe Trauma</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neurological Emergencies</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Hemodynamic Instability</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Post-Surgical Care</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Sepsis and Septic Shock</CListGroupItem>
                         <CListGroupItem className="dde" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Multi-Organ Failure</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Ventilators</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Heart Monitors</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Infusion Pumps</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Dialysis Machines</CListGroupItem>
                         <CListGroupItem className="dde2" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Specialized Beds</CListGroupItem>
                         </CListGroup>
                         </>
                        }
                     title="ICU"/>
                   </CCol>
                   <CCol xs={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol xs={6} style={ol1} onClick={ts1v}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol xs={6} style={ol1} onClick={ts2v}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>     
                     <CListGroup>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Premature Birth</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Respiratory Distress</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Low Birth Weight</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Infections</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Congenital Conditions</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Jaundice</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Hypoglycemia</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neurological Issues</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Neonatal Abstinence Syndrome</CListGroupItem>
                         <CListGroupItem className="ddev" style={okl}><IoIosArrowDroprightCircle/>&nbsp;Post-Surgical Care</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Incubators</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Ventilators and CPAP</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Feeding Tubes</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Heart Monitors</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;Phototherapy Lights</CListGroupItem>
                         <CListGroupItem className="dde2v" style={oklv}><IoIosArrowDroprightCircle/>&nbsp;IV Lines</CListGroupItem>
                         </CListGroup>
                         </>
                        }
                     title="NICU"/> 
                   </CCol>
                   <CCol xs={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol xs={6} style={ol1} onClick={ts5}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol xs={6} style={ol1} onClick={ts6}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>  
                     <CListGroup>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Diagnosing Heart Arrhythmias</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Chest Pain</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Detecting Heart Attacks</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Monitoring Heart Health</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Heart Function Post-Surgery</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Evaluating the Effects of Medication</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Detecting Electrolyte Imbalances</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Assessing Structural Abnormalities</CListGroupItem>
                         <CListGroupItem className="tt1" style={okl}><IoIosArrowDroprightCircle/>Pre-Surgical Evaluation</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Resting ECG</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Exercise (Stress) ECG</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Holter Monitor</CListGroupItem>
                         <CListGroupItem className="tt2" style={oklv}><IoIosArrowDroprightCircle/>Event Monitor</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="ECG"/>
                    
                     </CCol>
                     <CCol xs={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                     <CContainer fluid>
                     <CRow>
                         <CCol xs={6} style={ol1} onClick={ts3}><GiMedicalDrip style={{fontWeight:'800',fontSize:'2.0em'}}/>Treatment</CCol>
                         <CCol xs={6} style={ol1} onClick={ts4}><FaFileMedicalAlt style={{fontWeight:'800',fontSize:'2.0em'}}/>Equipment</CCol>
                     </CRow>
                     </CContainer>  
                     <CListGroup>
                         <CListGroupItem className="tmzv" style={okl}><IoIosArrowDroprightCircle/>Pregnancy Monitoring</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Cardiac (Echocardiography)</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Abdominal Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Blood Flow (Doppler Ultrasound)</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Musculoskeletal Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Thyroid and Neck Imaging</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Pelvic Ultrasound</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Breast Ultrasound</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Vascular Access</CListGroupItem>
                         <CListGroupItem className="tmzv"  style={okl}><IoIosArrowDroprightCircle/>Gynecological Conditions</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>2D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>3D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>4D Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Doppler Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Endoscopic Ultrasound</CListGroupItem>
                         <CListGroupItem className="tgzv" style={oklv}><IoIosArrowDroprightCircle/>Transvaginal/Transrectal Ultrasound</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="Ultrasound"/>
                     
                     </CCol>
                     <CCol xs={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Nutritional Assessment</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Diet Planning</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Medical Nutrition Therapy (MNT)</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Monitoring Heart Health</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Nutritional Counseling</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Weight Management</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Managing Food Allergies and Intolerances</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Pediatric Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Sports Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Community Nutrition</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Corporate Wellness Programs</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Eating Disorders</CListGroupItem>
                        
                      </CListGroup>   
                     </>}
                     title="Dietician"/>
                    
                     </CCol>
                     <CCol xs={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Manual Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Exercise Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Electrotherapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Heat and Cold Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Hydrotherapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Kinesiology Taping</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Postural Training</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Gait Training</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Breathing Exercises</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Traction</CListGroupItem>
                        
                      </CListGroup>   
                     </>}
                     title="Physiotherapy"/>
                    
                     </CCol>
                     <CCol xs={12}  style={{height:'auto'}}>
                   <CWidgetStatsB
                     className="mb-3"
                     color="danger"
                     inverse
                     text={<>
                
                     <CListGroup>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Bone Fractures</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Arthritis</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Osteoporosis</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Dental Issues</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Chest Conditions</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Digestive System</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Radiation Therapy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Plain X-ray</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Fluoroscopy</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>CT Scans (Computed Tomography)</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Mammography</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Radiation Exposure</CListGroupItem>
                         <CListGroupItem className="tt1z" style={okl}><IoIosArrowDroprightCircle/>Lead Shields</CListGroupItem>
                      </CListGroup>   
                     </>}
                     title="X-Ray"/>
                    
                     </CCol>
                   </CRow>
                   </CContainer>
                   </CContainer>
                   <CContainer fluid style={hsptl_facility}>
                   <CRow>
                        <CCol xs={12} style={{textAlign:'center',textDecoration:'underline'}}>
                        <h5>Faculty<FaUserDoctor style={{color:'#D91656'}}/></h5>
                        </CCol>
                   </CRow>  
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sashank Nigam</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MS,DNB,MRCS,MNAMS</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Fellowship, Surgical Oncology,Oncoplastic Breast Surgery</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Dharamvir Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS,MCH(Urology)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Urologist,Laproscopic,Transplant Surgeon</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Abhishek Verma</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MS(General Surgeon),FIAGES</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Rohit Saxena</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS(Opthalmologist)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Ayush Pratap Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS, FSR, DSN</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Former Resident Doctor:<br/> Trauma & Orthopedics, PGI Lucknow
Emergency Medicine ,<br/>Dr. Baba Saheb Ambedkar Hospital, New Delhi.</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Nitesh Singh Sodi</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>Psychology D(Honors)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Neuro-psycologist, Counsellor& Psycotherapist</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Harish Kumar Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MD</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Physician</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Pankaj Pathak</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MDS(Oro-Maxicofasical)</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Consultant Oro & Facial Trauma</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Pranjal Saxena</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>BDS(Dental Surgeon)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Harsh Vardhan</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>BDS,Fage(Dental Surgeon)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sudhanshu Verma</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS(D.Cardiology)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_fem.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Ankita Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,FIGO(Gynecologist)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_fem.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Nilima</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_exp}>Obstetrics& Gynecologist</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Sharad Kumar Singh</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MD(Pediatrics)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hsptl_sldr}>
                     <CRow>
                     <CCol xs={12} style={{padding:'1%'}}>
                     <CImage fluid src={"http://localhost/Hospital/image/doc_male.png"}/>
                     </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr}>Dr. Arshad Khalid</CBadge>
                      </CCol> 
                     </CRow>
                     <CRow>
                     <CCol style={{padding:'1%'}}>
                      <CBadge style={dctr_ql}>MBBS,MS(Anaesthosiology)</CBadge>
                      </CCol> 
                     </CRow>
                   </CContainer>
                   </CContainer>
                   
                   <CContainer fluid style={cl_lg}>
            <CRow>
             <CCol  lg={12} xl={12} xxl={12} style={{background:'linear-gradient(90deg, white, white)'}}>
           <FaWhatsapp style={{color:'#006769',fontSize:'2.0em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
          
         <Footer/>
         
                   </>
              )}
              </div>
    );
}

export default Hospital;