import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance,TbArrowBadgeRightFilled } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Oncology() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
            fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const jwl={
            border:'none',
            fontWeight:'500'
        }
        const vml={
            color:'red'
        }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="oncology">
          {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Oncology<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                         <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/Oncology.png"}/>Oncology</h5>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/oncology2.jfif"}/>Medical Oncologists (Doctors)
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:These are physicians who specialize in the diagnosis and treatment of cancer, primarily using non-surgical treatments like chemotherapy, immunotherapy, and targeted therapy.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Diagnosing cancer through biopsies, blood tests, and imaging.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Developing and overseeing chemotherapy and medication plans.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Monitoring the effectiveness of treatments.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing side effects and overall patient care during treatment.</CListGroupItem>                     
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/rad_oncology.jfif"}/>Radiation Oncologists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>Specialize in treating cancer using radiation therapy. They plan and deliver radiation treatments to shrink or destroy tumors while minimizing harm to surrounding tissues.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Planning radiation treatment with the help of imaging and advanced software.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Administering radiation and adjusting doses as needed.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Monitoring patient progress and managing side effects.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Collaborating with other oncologists to optimize treatment plans.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/surgical_oncology.jfif"}/>Surgical Oncologists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Surgeons who focus on the surgical removal of cancerous tumors. They may also perform biopsies or work in reconstructive surgery after tumor removal.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Performing surgeries to remove tumors or cancerous tissues.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Conducting biopsies to confirm cancer diagnoses.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Collaborating with other specialists for pre- and post-surgical care.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Reconstructive surgery after tumor removal (e.g., mastectomy, colorectal surgery).</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/hematology.jfif"}/>Hematologist-Oncologists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specialists who focus on cancers of the blood (e.g., leukemia, lymphoma, multiple myeloma).</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Diagnosing and treating blood cancers.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing bone marrow transplants or stem cell therapies.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Patient education on disease management (e.g., lifestyle changes for incontinence)Administering chemotherapy, targeted therapies, and immunotherapies for blood cancer patients.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/ped_onco.jfif"}/>Pediatric Oncologists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specialize in diagnosing and treating cancer in children. This can include leukemia, brain tumors, bone cancer, and more.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Tailoring cancer treatments to children’s unique needs.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Coordinating care with pediatricians and other specialists.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Monitoring long-term outcomes and developmental issues post-treatment.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/nurse.jfif"}/>Oncology Nurses
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Nurses who specialize in providing care to cancer patients, including administering chemotherapy, managing symptoms, and supporting patients emotionally.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Administering chemotherapy, immunotherapy, and other cancer treatments.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing side effects like nausea, fatigue, and pain.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Educating patients about their treatment plans and post-treatment care.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Offering emotional support to patients and families.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/rad_therapy.jfif"}/>Radiation Therapists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Operate the machinery used to deliver radiation therapy to cancer patients.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Administering prescribed radiation treatments.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Ensuring patients are positioned correctly for each session.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Monitoring patients’ conditions during and after radiation therapy.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Collaborating with radiation oncologists to adjust treatment as needed.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/onco_pharma.jfif"}/>Oncology Pharmacists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specialize in preparing and managing chemotherapy drugs and other cancer treatments.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Preparing and dispensing chemotherapy and other cancer-related drugs.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Counseling patients on medication side effects and management.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Ensuring the correct dosing and delivery of chemotherapy.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Working closely with the oncology team to adjust treatment as needed.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/pallative.jfif"}/>Palliative Care Specialists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Focus on improving the quality of life for cancer patients, particularly those with advanced cancer, by managing pain and symptoms.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing pain, nausea, fatigue, and other symptoms.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Providing emotional and psychological support.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Coordinating with the oncology team for holistic care.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Supporting end-of-life care when necessary.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>   
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/socialwork.jfif"}/>Oncology Social Workers
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Provide emotional and psychological support to patients and their families. They assist with coping mechanisms and help navigate the complex healthcare system.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Providing counseling and emotional support to patients and families.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Helping patients access financial resources or support services.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Assisting in navigating insurance or treatment logistics.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Facilitating support groups for patients and caregivers.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/nutrition.jfif"}/>Oncology Dietitians
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specialize in nutrition and diet for cancer patients to help manage side effects, boost energy, and support recovery.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Developing personalized nutrition plans to help patients maintain strength.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing diet-related side effects such as loss of appetite or digestive issues.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Advising on foods that boost the immune system or counteract treatment effects.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                       
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/pathology.jfif"}/>Pathologists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Doctors who examine tissue samples to diagnose cancer and determine its type, stage, and grade.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Analyzing biopsy and surgical samples under a microscope.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Providing reports to oncologists on the type and stage of cancer.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Offering insights into the aggressiveness of the cancer for treatment planning.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/oncology_research.jfif"}/>Oncology Research Coordinators
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Oversee clinical trials and cancer research studies. They ensure that research protocols are followed and that patients receive experimental treatments safely.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Recruiting patients for clinical trials.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Monitoring patient safety and data collection during trials.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Ensuring compliance with ethical and legal guidelines.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Coordinating between research teams, doctors, and patients.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/genetic_counselor.jfif"}/>Genetic Counselors
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Work with patients who may have inherited cancer syndromes or a family history of cancer to provide genetic testing and counseling.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Conducting genetic testing for cancer predispositions (e.g., BRCA1/2 mutations).</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Counseling patients on their risk of developing cancer.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Helping patients make informed decisions about screening and preventive care.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/administrative.jfif"}/>Administrative and Support Staff
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Handle administrative tasks such as scheduling, billing, and managing patient records.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Scheduling appointments and coordinating treatment plans.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing insurance and billing for oncology services.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Maintaining and updating patient medical records.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                       </CListGroup>
                       </CCol>
                   </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Oncology;