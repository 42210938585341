import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import { useMediaQuery } from 'react-responsive';
import React,{ useState, useEffect }  from 'react';
import { CFormSelect,CCard,CCardImage,CCardBody,CCardTitle,CCardText,CFormCheck,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { MdLocalPharmacy,MdDoneOutline,MdElderly,MdOutlineHome,MdMyLocation,MdOutlineReportProblem,MdOutlineWorkspacePremium,MdOutlineTrackChanges } from "react-icons/md";
import { FaHospitalAlt,FaWhatsapp,FaSave,FaSchool,FaRegUser,FaHistory,FaRegHospital } from "react-icons/fa";
import { TbAmbulance } from "react-icons/tb";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { PiGavel,PiOfficeChair } from "react-icons/pi";
import { BiCustomize } from "react-icons/bi";
import { FaVialVirus }  from "react-icons/fa6";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


function Pharmacy() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
    
    useEffect(() => {
      
        setIsLoaded(true);
    }, []);
    
    useEffect(() => {//on load
        if (isLoaded) {

        }
        },[isLoaded]);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
     
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const rb={
        color:'green'
    }
      const cl_lg={
        // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
         borderRadius:'1%',
         width:'auto',
         float:'left',
         zIndex:'3999 !important',
         position:'fixed',
         bottom:'3.4%',
         left:'87.3%',
         height:'auto',
         color:'black',
         fontWeight:'700',
         cursor:'pointer'
       }
       const cl_lg2={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'0.0%',  
        left:'46%', 
        width: '50px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'100px',
        borderTopRightRadius:'100px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg3={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'36%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg4={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'60%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg5={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'4.8%',  
        left:'48%',
        visibility:'hidden',
        display:'none', 
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       } 
       function mdl(e){
        e.preventDefault();
        if($("#rd4").css('visibility')!='visible'){
        document.getElementById("rd4").style.visibility='visible';
        document.getElementById("rd4").style.display="inline-block";
        document.getElementById("rd6").style.visibility='visible';
        document.getElementById("rd6").style.display="inline-block";
        document.getElementById("rd5").style.visibility='visible';
        document.getElementById("rd5").style.display="inline-block";
        }
        else
        {
            document.getElementById("rd4").style.visibility='hidden';
            document.getElementById("rd4").style.display="none";
            document.getElementById("rd6").style.visibility='hidden';
            document.getElementById("rd6").style.display="none";
            document.getElementById("rd5").style.visibility='hidden';
            document.getElementById("rd5").style.display="none";
        }
        return false;
       }
    return (
        <div className="pharmacy">
     {isDesktopOrLaptop && (
         <>
        <CContainer fluid>
        <CRow>
        <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
        Pharmacy
        </CCol>
        </CRow>
        </CContainer>
         </>
          )}
          {isTabletOrMobile && (
               <>
               </>
              )}
              {isMobile && (
                   <>
                       <CContainer fluid style={cl_lg}>
            <CRow>
             <CCol  lg={12} xl={12} xxl={12} style={{background:'linear-gradient(90deg, white, white)'}}>
           <FaWhatsapp style={{color:'#006769',fontSize:'2.0em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
            <CContainer fluid style={cl_lg2}>
            <CImage onClick={mdl} className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'40em'} height={'40em'}/>
            <CContainer fluid id="rd6" style={cl_lg5}><Link  style={{color:'black',textDecoration:'none'}} to="/ambulance"><TbAmbulance style={rb}/></Link></CContainer>
           <CContainer fluid id="rd4" style={cl_lg3}><Link  style={{color:'black',textDecoration:'none'}} to="/hospital"><FaHospitalAlt style={rb}/></Link></CContainer>
           <CContainer fluid id="rd5" style={cl_lg4}><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><FaVialVirus style={rb}/></Link></CContainer>
            </CContainer>
                   </>
              )}
              </div>
    );
}

export default Pharmacy;