import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';function Patientcare() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
        const mnu_icn_tbllpt={
          fontSize:'1.8em',
          color:'#921A40',
          float:'left',
          position:'relative',
          fontWeight:'900'
      }
      const lptp_lst_vw={
        cursor:'pointer',
        background:'linear-gradient(90deg, white, white)',
        color:'black',
        fontFamily:'Montserrat',
        textAlign:'left',
        fontWeight:'800',
        boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'

      }
      const tbl_sd_vw={
        fontFamily:'Montserrat',
        zIndex:2999,
        position:'absolute',
        float:'left',
        width:'70%',
        marginLeft:'-50%',
        top:'5.6%',
       height:'auto',
        visibility:'hidden',
        display:'none',
        boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
      }
        function mnu_ovr(e){
          e.preventDefault();
         if($("#lptp_vw").css('visibility')!='visible'){
              document.getElementById("lptp_vw").style.visibility='visible';
              document.getElementById("lptp_vw").style.display='inline-block';
              document.getElementById("mnu_hsp").style.color="white";
          }
              else
              {
                document.getElementById("lptp_vw").style.visibility='hidden';
                document.getElementById("lptp_vw").style.display='none';
                document.getElementById("mnu_hsp").style.color="#921A40";
              }
          return false;
      }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
              var tmo=$(".rdz");
             var amo=tmo.length;
             var dmv=0;
             while(amo!=0){
               var kbm=amo-1;
               var klm1=$(".rdz").eq(kbm).height();
               if(klm1>dmv){
                 dmv=klm1;
               }
                amo--;
             }
            
             $(".rdz").css({"height" : dmv+"px"});
  
              var tmo1=$(".rfz");
              var amo1=tmo1.length;
              var dmv1=0;
              while(amo1!=0){
                var kbm=amo1-1;
                var klm1=$(".rfz").eq(kbm).height();
                if(klm1>dmv1){
                  dmv1=klm1;
                }
                 amo1--;
              }
             
              $(".rfz").css({"height" : dmv1+"px"});
            }
          }, [isLoaded]);
        
          return (
            <div className="patient_care">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol sm={12} md={12} lg={12} style={hsptl_nm}><MenuIcon  onClick={mnu_ovr}  id="mnu_hsp" style={mnu_icn_tbllpt}/>Lifeline  Multi-Speciality Hospital Patient Care<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                     </CContainer>
                     <CContainer   id="lptp_vw" style={tbl_sd_vw}>
         <CListGroup>
         <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Inpatient Wards</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Intensive Care Units (ICUs)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Emergency and Trauma Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Operating Theaters (OT)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Labor and Delivery Rooms</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Outpatient Department (OPD)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Rehabilitation and Physiotherapy</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Diagnostic and Imaging Facilities</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Pediatric Care Units</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Infection Control and Isolation Wards</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Supportive Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Blood Bank and Transfusion Services</CListGroupItem>
   </CListGroup>
    </CContainer>
   <CContainer style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                    <CRow>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/inpt.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Inpatient Wards</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>General Wards</span>: Accommodate multiple patients in a shared space with basic amenities and nursing care.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Private and Semi-Private Rooms</span>: Offer more privacy, with private rooms providing individual space for patients and semi-private rooms shared between two patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Luxury or VIP Rooms</span>: Enhanced with extra amenities like premium bedding, personal nurse, TV, Wi-Fi, and special food services.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/icu.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Intensive Care Units (ICUs)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Medical ICU (MICU)</span>: Specialized care for patients recovering from major surgeries.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Surgical ICU (SICU)</span>: Intensive care for premature newborns or infants with serious health conditions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Neonatal ICU (NICU)</span>: Critical care for children and adolescents.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Cardiac ICU (CICU)</span>: Dedicated to patients with heart conditions such as heart attacks, cardiac surgeries, or severe arrhythmias.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/trauma.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Emergency and Trauma Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>24/7 Emergency Room (ER)</span>: First point of contact for patients with life-threatening injuries or medical emergencies.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Trauma Units</span>:Specially equipped to handle severe injuries like accidents, falls, and violence-related traumas.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Ambulance Services</span>:  Emergency transport to the hospital with advanced life support and trained paramedics.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/ot.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Operating Theaters (OT)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>General Surgery</span>: Equipped for all types of surgical procedures including appendectomy, hernia repair, etc.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialized Operating Theaters</span>:For advanced surgeries like cardiac, neurological, orthopedic, or organ transplants.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Day Surgery Units</span>:  For minor surgical procedures that do not require an overnight stay.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/labor.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Labor and Delivery Rooms</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Labor Rooms</span>:Designed for childbirth, with facilities to monitor the mother and baby throughout labor.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Maternity Wards</span>:Post-delivery care, with nursing staff and facilities for new mothers and newborns.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Neonatal Intensive Care</span>:  Special care for babies born prematurely or with medical complications.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/opd.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Outpatient Department (OPD)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Consultation Rooms</span>:Patients receive diagnosis and treatment without being admitted to the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Diagnostic Clinics</span>:Routine tests like blood tests, X-rays, ultrasounds, and ECGs are performed for walk-in patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialty Clinics</span>:Departments for various medical specialties (cardiology, dermatology, neurology, etc.) for outpatient consultations.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/rehab.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Rehabilitation and Physiotherapy</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Physical Therapy</span>:Helps patients recover from surgeries, injuries, or chronic conditions through movement exercises and therapies.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Occupational Therapy</span>:Focuses on helping patients regain daily living skills.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Speech Therapy</span>:Assists patients with speech or swallowing difficulties, often after stroke or injury.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/diagnostic.webp'} />
  <CCardBody>
    <CCardText>
    <h5>Diagnostic and Imaging Facilities</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Radiology Department</span>:Equipped with advanced imaging technologies like MRI, CT scan, X-ray, and ultrasound for accurate diagnosis.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Laboratory Services</span>:On-site labs for blood tests, biopsies, urine tests, and other diagnostic services.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Endoscopy/Colonoscopy Units</span>:Non-invasive diagnostic procedures to examine internal organs.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/baby.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Pediatric Care Units</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Pediatric Wards</span>:Special rooms and facilities designed to accommodate children with medical needs.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialized Pediatric ICU (PICU)</span>: For critically ill children requiring intensive care.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/infection.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Infection Control and Isolation Wards</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Isolation Rooms</span>:For patients with infectious diseases such as tuberculosis, COVID-19, or other contagious conditions requiring quarantine.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Strict Hygiene and Monitoring</span>: Designed to prevent the spread of infections within the hospital.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/serv.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Supportive Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Dietary Services</span>:Customized meal plans for patients with specific dietary needs, prepared by hospital nutritionists and kitchen staff.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Social Services</span>: Assist patients with discharge planning, accessing community resources, and coordinating home care services.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Patient Counseling</span>:  Psychological and emotional support for patients and their families dealing with illness, surgery, or long-term care.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/trans.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Blood Bank and Transfusion Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>On-site blood banks ensure that patients in need of blood transfusions receive blood quickly and safely, especially during surgeries or emergencies.</CListGroupItem>
      </CListGroup>
    </CCardText>
      </CCardBody>
</CCard>
</CCol>
                        </CRow>
                        </CContainer>
                   <Footer/>  
              </>
               )}
               {isTabletOrMobile && (
                    <>
                    <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol sm={12} md={12} lg={12} style={hsptl_nm}><MenuIcon  onClick={mnu_ovr}  id="mnu_hsp" style={mnu_icn_tbllpt}/>Lifeline  Multi-Speciality Hospital Patient Care<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                     </CContainer>
                     <CContainer   id="lptp_vw" style={tbl_sd_vw}>
         <CListGroup>
         <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Inpatient Wards</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Intensive Care Units (ICUs)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Emergency and Trauma Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Operating Theaters (OT)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Labor and Delivery Rooms</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Outpatient Department (OPD)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Rehabilitation and Physiotherapy</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Diagnostic and Imaging Facilities</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Pediatric Care Units</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Infection Control and Isolation Wards</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Supportive Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Blood Bank and Transfusion Services</CListGroupItem>
   </CListGroup>
    </CContainer>
   <CContainer fluid style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                    <CRow>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/inpt.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Inpatient Wards</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>General Wards</span>: Accommodate multiple patients in a shared space with basic amenities and nursing care.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Private and Semi-Private Rooms</span>: Offer more privacy, with private rooms providing individual space for patients and semi-private rooms shared between two patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Luxury or VIP Rooms</span>: Enhanced with extra amenities like premium bedding, personal nurse, TV, Wi-Fi, and special food services.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/icu.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Intensive Care Units (ICUs)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Medical ICU (MICU)</span>: Specialized care for patients recovering from major surgeries.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Surgical ICU (SICU)</span>: Intensive care for premature newborns or infants with serious health conditions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Neonatal ICU (NICU)</span>: Critical care for children and adolescents.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Cardiac ICU (CICU)</span>: Dedicated to patients with heart conditions such as heart attacks, cardiac surgeries, or severe arrhythmias.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/trauma.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Emergency and Trauma Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>24/7 Emergency Room (ER)</span>: First point of contact for patients with life-threatening injuries or medical emergencies.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Trauma Units</span>:Specially equipped to handle severe injuries like accidents, falls, and violence-related traumas.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Ambulance Services</span>:  Emergency transport to the hospital with advanced life support and trained paramedics.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/ot.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Operating Theaters (OT)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>General Surgery</span>: Equipped for all types of surgical procedures including appendectomy, hernia repair, etc.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialized Operating Theaters</span>:For advanced surgeries like cardiac, neurological, orthopedic, or organ transplants.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Day Surgery Units</span>:  For minor surgical procedures that do not require an overnight stay.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/labor.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Labor and Delivery Rooms</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Labor Rooms</span>:Designed for childbirth, with facilities to monitor the mother and baby throughout labor.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Maternity Wards</span>:Post-delivery care, with nursing staff and facilities for new mothers and newborns.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Neonatal Intensive Care</span>:  Special care for babies born prematurely or with medical complications.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/opd.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Outpatient Department (OPD)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Consultation Rooms</span>:Patients receive diagnosis and treatment without being admitted to the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Diagnostic Clinics</span>:Routine tests like blood tests, X-rays, ultrasounds, and ECGs are performed for walk-in patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialty Clinics</span>:Departments for various medical specialties (cardiology, dermatology, neurology, etc.) for outpatient consultations.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/rehab.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Rehabilitation and Physiotherapy</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Physical Therapy</span>:Helps patients recover from surgeries, injuries, or chronic conditions through movement exercises and therapies.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Occupational Therapy</span>:Focuses on helping patients regain daily living skills.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Speech Therapy</span>:Assists patients with speech or swallowing difficulties, often after stroke or injury.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/diagnostic.webp'} />
  <CCardBody>
    <CCardText>
    <h5>Diagnostic and Imaging Facilities</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Radiology Department</span>:Equipped with advanced imaging technologies like MRI, CT scan, X-ray, and ultrasound for accurate diagnosis.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Laboratory Services</span>:On-site labs for blood tests, biopsies, urine tests, and other diagnostic services.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Endoscopy/Colonoscopy Units</span>:Non-invasive diagnostic procedures to examine internal organs.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/baby.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Pediatric Care Units</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Pediatric Wards</span>:Special rooms and facilities designed to accommodate children with medical needs.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialized Pediatric ICU (PICU)</span>: For critically ill children requiring intensive care.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/infection.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Infection Control and Isolation Wards</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Isolation Rooms</span>:For patients with infectious diseases such as tuberculosis, COVID-19, or other contagious conditions requiring quarantine.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Strict Hygiene and Monitoring</span>: Designed to prevent the spread of infections within the hospital.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/serv.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Supportive Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Dietary Services</span>:Customized meal plans for patients with specific dietary needs, prepared by hospital nutritionists and kitchen staff.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Social Services</span>: Assist patients with discharge planning, accessing community resources, and coordinating home care services.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Patient Counseling</span>:  Psychological and emotional support for patients and their families dealing with illness, surgery, or long-term care.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol sm={6} md={6} lg={6}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/trans.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Blood Bank and Transfusion Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>On-site blood banks ensure that patients in need of blood transfusions receive blood quickly and safely, especially during surgeries or emergencies.</CListGroupItem>
      </CListGroup>
    </CCardText>
      </CCardBody>
</CCard>
</CCol>
                        </CRow>
                        </CContainer>
                   <Footer/>  
               </>
              )}
              {isMobile && (
                   <>
                  <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Patient Care<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                    <CRow>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/inpt.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Inpatient Wards</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>General Wards</span>: Accommodate multiple patients in a shared space with basic amenities and nursing care.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Private and Semi-Private Rooms</span>: Offer more privacy, with private rooms providing individual space for patients and semi-private rooms shared between two patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Luxury or VIP Rooms</span>: Enhanced with extra amenities like premium bedding, personal nurse, TV, Wi-Fi, and special food services.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/icu.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Intensive Care Units (ICUs)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Medical ICU (MICU)</span>: Specialized care for patients recovering from major surgeries.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Surgical ICU (SICU)</span>: Intensive care for premature newborns or infants with serious health conditions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Neonatal ICU (NICU)</span>: Critical care for children and adolescents.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Cardiac ICU (CICU)</span>: Dedicated to patients with heart conditions such as heart attacks, cardiac surgeries, or severe arrhythmias.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/trauma.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Emergency and Trauma Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>24/7 Emergency Room (ER)</span>: First point of contact for patients with life-threatening injuries or medical emergencies.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Trauma Units</span>:Specially equipped to handle severe injuries like accidents, falls, and violence-related traumas.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Ambulance Services</span>:  Emergency transport to the hospital with advanced life support and trained paramedics.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/ot.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Operating Theaters (OT)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>General Surgery</span>: Equipped for all types of surgical procedures including appendectomy, hernia repair, etc.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialized Operating Theaters</span>:For advanced surgeries like cardiac, neurological, orthopedic, or organ transplants.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Day Surgery Units</span>:  For minor surgical procedures that do not require an overnight stay.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/labor.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Labor and Delivery Rooms</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Labor Rooms</span>:Designed for childbirth, with facilities to monitor the mother and baby throughout labor.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Maternity Wards</span>:Post-delivery care, with nursing staff and facilities for new mothers and newborns.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Neonatal Intensive Care</span>:  Special care for babies born prematurely or with medical complications.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/opd.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Outpatient Department (OPD)</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Consultation Rooms</span>:Patients receive diagnosis and treatment without being admitted to the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Diagnostic Clinics</span>:Routine tests like blood tests, X-rays, ultrasounds, and ECGs are performed for walk-in patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialty Clinics</span>:Departments for various medical specialties (cardiology, dermatology, neurology, etc.) for outpatient consultations.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/rehab.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Rehabilitation and Physiotherapy</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Physical Therapy</span>:Helps patients recover from surgeries, injuries, or chronic conditions through movement exercises and therapies.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Occupational Therapy</span>:Focuses on helping patients regain daily living skills.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Speech Therapy</span>:Assists patients with speech or swallowing difficulties, often after stroke or injury.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/diagnostic.webp'} />
  <CCardBody>
    <CCardText>
    <h5>Diagnostic and Imaging Facilities</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Radiology Department</span>:Equipped with advanced imaging technologies like MRI, CT scan, X-ray, and ultrasound for accurate diagnosis.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Laboratory Services</span>:On-site labs for blood tests, biopsies, urine tests, and other diagnostic services.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Endoscopy/Colonoscopy Units</span>:Non-invasive diagnostic procedures to examine internal organs.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/baby.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Pediatric Care Units</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Pediatric Wards</span>:Special rooms and facilities designed to accommodate children with medical needs.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Specialized Pediatric ICU (PICU)</span>: For critically ill children requiring intensive care.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/infection.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Infection Control and Isolation Wards</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Isolation Rooms</span>:For patients with infectious diseases such as tuberculosis, COVID-19, or other contagious conditions requiring quarantine.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Strict Hygiene and Monitoring</span>: Designed to prevent the spread of infections within the hospital.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/serv.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Supportive Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Dietary Services</span>:Customized meal plans for patients with specific dietary needs, prepared by hospital nutritionists and kitchen staff.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Social Services</span>: Assist patients with discharge planning, accessing community resources, and coordinating home care services.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Patient Counseling</span>:  Psychological and emotional support for patients and their families dealing with illness, surgery, or long-term care.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
</CCol>
<CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/trans.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Blood Bank and Transfusion Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>On-site blood banks ensure that patients in need of blood transfusions receive blood quickly and safely, especially during surgeries or emergencies.</CListGroupItem>
      </CListGroup>
    </CCardText>
      </CCardBody>
</CCard>
</CCol>
                        </CRow>
                        </CContainer>
                   <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Patientcare;