import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
function Footer() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const dwn_mnu={
        textAlign:'left',   
        color:'white',
        fontFamily:'Lucida Sans',
        border:'none',
        background:'linear-gradient(90deg, #001F3F,#001F3F)'
       }  
       const dwn_mnut={
        padding:'2%', 
        fontSize:'0.89em',
        textAlign:'center',   
        color:'white',
        fontFamily:'Lucida Sans',
        border:'none',
        background:'linear-gradient(90deg, #001F3F,#001F3F)'
       }
       const ftr={
        padding:'1%',
        fontSize:'0.69em',
        height:'2em',
        width:'100%',
        boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
        background:'linear-gradient(90deg, #C4D7FF, #87A2FF)',
        color:'black',
        fontWeight:'600'
      }
      const cl_lg2={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'0.0%',  
        left:'45%', 
        width: '50px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'100px',
        borderTopRightRadius:'100px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg2z={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'0.0%',  
        left:'49.2%', 
        width: '50px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'100px',
        borderTopRightRadius:'100px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg2ztb={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'0.0%',  
        left:'48.2%', 
        width: '50px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'100px',
        borderTopRightRadius:'100px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px', 
       }
       const cl_lg3={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'36%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg4={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'60%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg5={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'4.8%',  
        left:'48%',
        visibility:'hidden',
        display:'none', 
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg3z={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'45%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg4z={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'53%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg5z={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'4.8%',  
        left:'49%',
        visibility:'hidden',
        display:'none', 
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg3zt={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'42%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg4zt={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'56%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg5zt={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'4.8%',  
        left:'49%',
        visibility:'hidden',
        display:'none', 
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const rb={
        color:'green'
    }
    const ire={
      width:'100%',
      height:'80%',  
     }
     const irex={
      width:'auto',
      height:'auto',  
     }
     const icn_btn={
      fontSize:'1.0em',
      fontWeight:'700',
      width:'99%',
      
    }
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
     window.scrollTo(0, 0);
    useEffect(() => {
      setIsLoaded(true);
  }, []);
  
  useEffect(() => {//on load
      if (isLoaded) {
        var fm=new Date();
        var xm=fm.getFullYear();
        document.getElementById("cprt_yr").innerText=xm;
        window.scrollTo(0, 0);
      }
    });
       function mdl(e){
        e.preventDefault();
        if($("#rd4").css('visibility')!='visible'){
        document.getElementById("rd4").style.visibility='visible';
        document.getElementById("rd4").style.display="inline-block";
        document.getElementById("rd6").style.visibility='visible';
        document.getElementById("rd6").style.display="inline-block";
        document.getElementById("rd5").style.visibility='visible';
        document.getElementById("rd5").style.display="inline-block";
        }
        else
        {
            document.getElementById("rd4").style.visibility='hidden';
            document.getElementById("rd4").style.display="none";
            document.getElementById("rd6").style.visibility='hidden';
            document.getElementById("rd6").style.display="none";
            document.getElementById("rd5").style.visibility='hidden';
            document.getElementById("rd5").style.display="none";
        }
        return false;
       }
    

      return (
    <div className="footr">
     {isDesktopOrLaptop && (
             <>
          <CContainer style={{width:'100%',height:'auto',background:'linear-gradient(90deg, #001F3F,#001F3F)',boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}>
             <CRow>
                 <CCol lg={12} xl={12} xxl={12}>
                 <CImage fluid src={"http://localhost/Hospital/image/logo.jpg"} style={irex}/>
                </CCol>
                <CCol lg={12} xl={12} xxl={12}>
                <span style={{fontWeight:'600',color:'white',fontSize:'1.0em'}}> Dedicated to a healthy and hearty life!</span>
                <br/>
                <span style={{fontWeight:'600',color:'aqua',fontSize:'0.96em'}}> Subscribe to our feed<MdOutlineRssFeed /></span>
                <br/>
                </CCol>
                <CCol lg={12} xl={12} xxl={12}>
                <CInputGroup className="mb-3">
  <CFormInput placeholder="Enter your Email Address"  aria-label="Username" aria-describedby="basic-addon1"/>
</CInputGroup>
<CButton color="secondary"   style={{width:'98%'}}><MdOutlineMail/>Subscribe</CButton>
  
</CCol>
<CCol lg={12} xl={12} xxl={12}>
                 <CListGroup  layout={`horizontal`}>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/health_bulletin"><MdHealthAndSafety/>&nbsp;Health Bulletin</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/hospital_network"><FaNetworkWired/>&nbsp;Hospital  Network</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/latest_fac"><MdOutlineWallpaper/>&nbsp;Latest Facilities</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/home_care"><FaHome/>&nbsp;Home Care</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/patient_care"><MdMedicalServices/>&nbsp;Patient Care</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/hospital_jobs"><MdOutlineAddTask/>&nbsp;Hospital Jobs</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="https://hair.lifelinehealth.in/"><BsFileMedical/>&nbsp;Tie Ups</Link></CListGroupItem>
</CListGroup>
                 </CCol> 
                
             </CRow>
             </CContainer>
             <CContainer fluid style={{background:'linear-gradient(90deg, #FFF4B5,#FFF4B5)'}}>
             <CRow>
              <CCol style={{textAlign:'left'}}><TiSocialFacebookCircular /></CCol>
              <CCol style={{textAlign:'left'}}><TiSocialInstagramCircular/></CCol>
              <CCol style={{textAlign:'left'}}><TiSocialLinkedinCircular/></CCol>
              <CCol style={{textAlign:'left'}}><RiTwitterXFill /></CCol>
             </CRow>
            </CContainer>
            <CContainer fluid style={ftr}>
            <CRow>
      <CCol lg={12} xl={12} xxl={12}>
      <CLink href="https://coreui.io">@ Copyright <span id="cprt_yr"></span></CLink>
    <span>&copy;LifelineMulti&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Speciality,All rights reserved.</span>
 
   
      </CCol>
     </CRow>
            </CContainer>
            <CContainer fluid style={cl_lg2z}>
            <CImage onClick={mdl} className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'40em'} height={'40em'}/>
           <CContainer fluid id="rd4" style={cl_lg3z}><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><FaVialVirus style={rb}/></Link></CContainer>
           <CContainer fluid id="rd6" style={cl_lg5z}><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><MdLocalPharmacy style={rb}/></Link></CContainer>
           <CContainer fluid id="rd5" style={cl_lg4z}><Link  style={{color:'black',textDecoration:'none'}} to="/ambulance"><TbAmbulance style={rb}/></Link></CContainer>
            </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                    <CContainer fluid style={{width:'100%',height:'auto',background:'linear-gradient(90deg, #001F3F,#001F3F)',boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}>
             <CRow>
                 <CCol lg={12} xl={12} xxl={12}>
                 <CImage fluid src={"http://localhost/Hospital/image/logo.jpg"} style={irex}/>
                </CCol>
                <CCol lg={12} xl={12} xxl={12}>
                <span style={{fontWeight:'600',color:'white',fontSize:'1.0em'}}> Dedicated to a healthy and hearty life!</span>
                <br/>
                <span style={{fontWeight:'600',color:'aqua',fontSize:'0.96em'}}> Subscribe to our feed<MdOutlineRssFeed /></span>
                <br/>
                </CCol>
                <CCol lg={12} xl={12} xxl={12}>
                <CInputGroup className="mb-3">
  <CFormInput placeholder="Enter your Email Address"  aria-label="Username" aria-describedby="basic-addon1"/>
</CInputGroup>
<CButton color="secondary"   style={{width:'98%'}}><MdOutlineMail/>Subscribe</CButton>
  
</CCol>
<CCol lg={12} xl={12} xxl={12}>
                 <CListGroup  layout={`horizontal`}>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="/health_bulletin"><MdHealthAndSafety/>&nbsp;Health<br/>Bulletin</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="/hospital_network"><FaNetworkWired/>&nbsp;Hospital<br/>Network</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="/latest_fac"><MdOutlineWallpaper/>&nbsp;Latest<br/>Facilities</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="/home_care"><FaHome/>&nbsp;Home<br/>Care</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="/patient_care"><MdMedicalServices/>&nbsp;Patient<br/>Care</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="/hospital_jobs"><MdOutlineAddTask/>&nbsp;Hospital<br/>Jobs</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnut}><Link style={{color:'white',textDecoration:'underline'}} to="https://hair.lifelinehealth.in/"><BsFileMedical/>&nbsp;Tie<br/>Ups</Link></CListGroupItem>
</CListGroup>
                 </CCol> 
                
             </CRow>
             </CContainer>
             <CContainer fluid style={{background:'linear-gradient(90deg, #FFF4B5,#FFF4B5)'}}>
             <CRow>
              <CCol style={{textAlign:'left'}}><TiSocialFacebookCircular /></CCol>
              <CCol style={{textAlign:'left'}}><TiSocialInstagramCircular/></CCol>
              <CCol style={{textAlign:'left'}}><TiSocialLinkedinCircular/></CCol>
              <CCol style={{textAlign:'left'}}><RiTwitterXFill /></CCol>
             </CRow>
            </CContainer>
            <CContainer fluid style={ftr}>
            <CRow>
      <CCol lg={12} xl={12} xxl={12}>
      <CLink href="https://coreui.io">@ Copyright <span id="cprt_yr"></span></CLink>
    <span>&copy;LifelineMulti&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Speciality,All rights reserved.</span>
 
   
      </CCol>
     </CRow>
            </CContainer>
            <CContainer fluid style={cl_lg2ztb}>
            <CImage onClick={mdl} className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'40em'} height={'40em'}/>
           <CContainer fluid id="rd4" style={cl_lg3zt}><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><FaVialVirus style={rb}/></Link></CContainer>
           <CContainer fluid id="rd6" style={cl_lg5zt}><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><MdLocalPharmacy style={rb}/></Link></CContainer>
           <CContainer fluid id="rd5" style={cl_lg4zt}><Link  style={{color:'black',textDecoration:'none'}} to="/ambulance"><TbAmbulance style={rb}/></Link></CContainer>
            </CContainer>
               </>
              )}
      {isMobile && (
      <>
          <CContainer fluid style={{width:'100%',height:'auto',background:'linear-gradient(90deg, #001F3F,#001F3F)',boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}>
             <CRow>
                 <CCol xs={12}>
                 <CImage fluid src={"http://localhost/Hospital/image/logo.jpg"} style={ire}/>
                <span style={{fontWeight:'600',color:'white',fontSize:'1.0em'}}> Dedicated to a healthy and hearty life!</span>
                <br/>
                <span style={{fontWeight:'600',color:'aqua',fontSize:'0.96em'}}> Subscribe to our feed<MdOutlineRssFeed /></span>
                <br/>
                <CInputGroup className="mb-3">
  <CFormInput placeholder="Enter your Email Address"  aria-label="Username" aria-describedby="basic-addon1"/>
</CInputGroup>
</CCol>
<CCol xs={12}>
<CButton color="secondary"   style={{width:'98%'}}><MdOutlineMail/>Subscribe</CButton>
                 </CCol>
                
             </CRow>
             <CContainer fluid style={{width:'100%'}}>
         <CCol xs={12}>
                 <CListGroup>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/health_bulletin"><MdHealthAndSafety/>&nbsp;Health Bulletin</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/hospital_network"><FaNetworkWired/>&nbsp;Hospital  Network</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/latest_fac"><MdOutlineWallpaper/>&nbsp;Latest Facilities</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/home_care"><FaHome/>&nbsp;Home Care</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/patient_care"><MdMedicalServices/>&nbsp;Patient Care</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="/hospital_jobs"><MdOutlineAddTask/>&nbsp;Hospital Jobs</Link></CListGroupItem>
  <CListGroupItem className="dfj" style={dwn_mnu}><Link style={{color:'white',textDecoration:'underline'}} to="https://hair.lifelinehealth.in/"><BsFileMedical/>&nbsp;Tie Ups</Link></CListGroupItem>
</CListGroup>
                 </CCol> 
                 </CContainer>
            </CContainer>
           
                   <CContainer fluid style={{background:'linear-gradient(90deg, #FFF4B5,#FFF4B5)'}}>
             <CRow>
              <CCol style={{textAlign:'left'}}><TiSocialFacebookCircular /></CCol>
              <CCol style={{textAlign:'left'}}><TiSocialInstagramCircular/></CCol>
              <CCol style={{textAlign:'left'}}><TiSocialLinkedinCircular/></CCol>
              <CCol style={{textAlign:'left'}}><RiTwitterXFill /></CCol>
             </CRow>
            </CContainer>
            <CContainer style={ftr}>
            <CRow>
      <CCol xs={12}>
      <CLink href="https://coreui.io">@ Copyright <span id="cprt_yr"></span></CLink>
    <span>&copy;&nbsp;LifelineMulti&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Speciality,All rights reserved.</span>
 
   
      </CCol>
     </CRow>
            </CContainer>
            <CContainer fluid style={cl_lg2}>
            <CImage onClick={mdl} className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'40em'} height={'40em'}/>
           <CContainer fluid id="rd4" style={cl_lg3}><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><FaVialVirus style={rb}/></Link></CContainer>
           <CContainer fluid id="rd6" style={cl_lg5}><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><MdLocalPharmacy style={rb}/></Link></CContainer>
           <CContainer fluid id="rd5" style={cl_lg4}><Link  style={{color:'black',textDecoration:'none'}} to="/ambulance"><TbAmbulance style={rb}/></Link></CContainer>
            </CContainer>
      </>
     )}
    </div>
                     );
}
export default Footer;