import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance,TbArrowBadgeRightFilled } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Urology() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const jwl={
            border:'none',
            fontWeight:'500'
        }
        const vml={
            color:'red'
        }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="urology">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Urology<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                         <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/urology.png"}/>Urology</h5>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/urology_doc.jfif"}/>Urologists (Doctors)
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Our esteemed Doctors specialize in urology. They diagnose and treat urinary tract disorders in both men and women, and conditions of the male reproductive system.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Kidney stone removal</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Prostate surgeries (e.g., prostatectomy)</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Bladder surgeries</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Treatment of urinary incontinence</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Vasectomy and vasectomy reversals</CListGroupItem>                       
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/urology_surgeon.jfif"}/>Urologic Surgeons
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Subspecialists trained to perform complex surgical procedures related to urological conditions, such as cancerous tumors, kidney transplants, or reconstructive surgeries.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Robotic-assisted surgeries</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Kidney removal (nephrectomy)</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Removal of bladder cancer</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/pa.jfif"}/>Nurse Practitioner (NP) / Physician Assistant (PA)
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Provide care under the supervision of a urologist. They conduct exams, prescribe medications, assist in minor procedures, and manage follow-up care.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Performing catheter placements.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Assisting in diagnostic tests (e.g., ultrasounds, cystoscopies).</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Managing patient post-operative care.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/nurse.jfif"}/>Urology Nurses
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Registered nurses (RNs) specialized in caring for patients with urological conditions. They assist with procedures, patient education, and recovery.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Assisting during surgeries and procedures.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Post-operative patient care.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Patient education on disease management (e.g., lifestyle changes for incontinence).</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/urodynamic.jfif"}/>Urodynamic Technicians
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specialize in urodynamic studies, which assess how well the bladder, sphincters, and urethra are storing and releasing urine.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Conducting tests to measure bladder function.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Monitoring pressure, flow rates, and volume in the urinary tract.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/radiology.jfif"}/>Radiologists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Diagnostic imaging specialists who interpret X-rays, MRIs, CT scans, and ultrasounds to help diagnose urological issues.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Analyzing imaging for kidney stones, tumors, or blockages.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Collaborating with urologists for treatment planning.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/administrative.jfif"}/>Urology Administrative Staff
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Manage the day-to-day operations of the department, including scheduling appointments, patient check-ins, billing, and maintaining medical records.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Scheduling patient consultations and surgeries.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Handling patient insurance claims and billing.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Coordinating communication between doctors and patients.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/assistant.jfif"}/>Medical Assistants
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Assist with the administrative and clinical tasks of the urology office.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Preparing patients for exams.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Taking patient histories and vital signs.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Assisting during minor procedures in outpatient settings.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/Phlebotomists.jfif"}/>Phlebotomists
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specialize in drawing blood samples, often required for urological tests.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Drawing blood for testing hormone levels, kidney function, or cancer markers.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>   
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/oncology.png"}/>Oncologists (Urological Oncology)
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Work closely with urologists to treat cancers of the urinary tract and male reproductive system, including prostate, bladder, and kidney cancer.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Overseeing cancer treatment plans.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Collaborating with urologic surgeons for tumor removals or biopsies.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                       </CListGroup>
                       </CCol>
                   </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Urology;