import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js'
function LatestFacility() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
        const mnu_icn_tbllpt={
            fontSize:'1.8em',
            color:'#921A40',
            float:'left',
            position:'relative',
            fontWeight:'900'
        }
          const oph={
              fontWeight:'600',
              textAlign:'left',
              background:'linear-gradient(180deg,#F1DEC6, #FF8343, #FF8343,#F1DEC6)',
              color:'#921A40'
          }
          const mbl_mnu_img={
            position:'relative',
            float:'right',
           
          }
          const hsptl_nm_lptp={
            fontFamily:'Baskerville',
            fontWeight:'700',
            textAlign:'center',
            fontSize:'1.4em'
        }
        const tblt_nm_lptp={
            fontFamily:'Baskerville',
            fontWeight:'700',
            textAlign:'center',
            fontSize:'1.2em'
        }
        const tblt_lst_vw={
            textAlign:'center',
            fontWeight:'600',
            border:'none',
           width:'60%',
            fontSize:'76%' ,
            textDecoration:'underline',
           cursor:'pointer'
          }
          const lptp_lst_vw={
            cursor:'pointer',
            background:'linear-gradient(90deg, white, white)',
            color:'black',
            fontFamily:'Montserrat',
            textAlign:'left',
            fontWeight:'800',
            boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'
    
          }
          const mbl_sd_vw={
            fontFamily:'Montserrat',
            zIndex:2999,
            position:'absolute',
            float:'left',
            width:'40%',
            marginLeft:'-50%',
            top:'5.6%',
           height:'auto',
            visibility:'hidden',
            display:'none',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
          }
          const tbl_sd_vw={
            fontFamily:'Montserrat',
            zIndex:2999,
            position:'absolute',
            float:'left',
            width:'70%',
            marginLeft:'-50%',
            top:'5.6%',
           height:'auto',
            visibility:'hidden',
            display:'none',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
          }
          function mnu_ovr(e){
            e.preventDefault();
           if($("#lptp_vw").css('visibility')!='visible'){
                document.getElementById("lptp_vw").style.visibility='visible';
                document.getElementById("lptp_vw").style.display='inline-block';
                document.getElementById("mnu_hsp").style.color="white";
            }
                else
                {
                  document.getElementById("lptp_vw").style.visibility='hidden';
                  document.getElementById("lptp_vw").style.display='none';
                  document.getElementById("mnu_hsp").style.color="#921A40";
                }
            return false;
        }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="latest_facility">
     {isDesktopOrLaptop && (
             <>
            <CContainer fluid style={hsptl_mbl_hdr}>
                   <CRow>
                          <CCol lg={12} xl={12} xxl={12} style={hsptl_nm_lptp}><MenuIcon  onClick={mnu_ovr}  id="mnu_hsp" style={mnu_icn_tbllpt}/>Lifeline  Multi-Speciality Hospital Latest Facility<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer   id="lptp_vw" style={mbl_sd_vw}>
         <CListGroup>
         <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Emergency and Trauma Care</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Outpatient Department (OPD)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Inpatient Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Diagnostic and Imaging Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Surgical Facilities</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Specialty Clinics</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Rehabilitation and Physiotherapy</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Pharmacy Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Labor and Delivery Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Preventive Health Check-ups</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Dialysis and Renal Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Cancer Care (Oncology)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Mental Health Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Health and Wellness Programs</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Telemedicine and Online Consultations</CListGroupItem>
    </CListGroup>
    </CContainer>
                   <CContainer fluid style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                   <CListGroup>
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Emergency and Trauma Care
                   <CListGroup>
                   <CListGroupItem>24/7 emergency services for critical injuries, illnesses, and accidents.</CListGroupItem> 
                   <CListGroupItem>Well-equipped ambulances with advanced life support systems.</CListGroupItem> 
                   <CListGroupItem>Trauma units for severe injuries requiring immediate medical attention.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>    
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Outpatient Department (OPD)
                   <CListGroup>
                   <CListGroupItem>Consultations with specialists across various fields such as cardiology, orthopedics, neurology, etc.</CListGroupItem> 
                   <CListGroupItem>Routine health check-ups and follow-ups.</CListGroupItem> 
                   <CListGroupItem>Diagnostic and screening services.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>    
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Inpatient Services
                   <CListGroup>
                   <CListGroupItem>General wards, semi-private, and private rooms for patients requiring hospitalization.</CListGroupItem> 
                   <CListGroupItem>Intensive Care Units (ICUs) including specialized units like NICU (Neonatal Intensive Care), PICU (Pediatric Intensive Care), and Cardiac ICUs.</CListGroupItem> 
                   <CListGroupItem>High Dependency Units (HDUs) for patients needing close monitoring.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Diagnostic and Imaging Services
                   <CListGroup>
                   <CListGroupItem>Advanced imaging technologies like MRI, CT scan, X-ray, ultrasound, and mammography.</CListGroupItem> 
                   <CListGroupItem>Laboratory services for blood tests, biopsy, and pathology.</CListGroupItem> 
                   <CListGroupItem>Diagnostic endoscopy, colonoscopy, and other non-invasive procedures.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Surgical Facilities
                   <CListGroup>
                   <CListGroupItem>Operation theaters equipped for various types of surgeries including general, orthopedic, cardiac, neuro, and minimally invasive surgeries.</CListGroupItem> 
                   <CListGroupItem>Pre-operative and post-operative care units.</CListGroupItem> 
                   <CListGroupItem>Day-care surgery facilities for minor procedures.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Specialty Clinics
                   <CListGroup>
                   <CListGroupItem>Cardiology</CListGroupItem> 
                   <CListGroupItem>Neurology</CListGroupItem> 
                   <CListGroupItem>Oncology (cancer care)</CListGroupItem> 
                   <CListGroupItem>Gastroenterology</CListGroupItem> 
                   <CListGroupItem>Pulmonology</CListGroupItem> 
                   <CListGroupItem>Dermatology</CListGroupItem> 
                   <CListGroupItem>Ophthalmology</CListGroupItem> 
                   <CListGroupItem>ENT (Ear, Nose, and Throat)</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Rehabilitation and Physiotherapy
                   <CListGroup>
                   <CListGroupItem>Physical therapy, occupational therapy, and speech therapy services for recovery post-surgery, injury, or illness.</CListGroupItem> 
                   <CListGroupItem>Specialized rehabilitation programs for stroke, cardiac, and post-orthopedic surgeries.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Pharmacy Services
                   <CListGroup>
                   <CListGroupItem>24-hour pharmacy services providing medications prescribed by in-house doctors and specialists.</CListGroupItem> 
                   <CListGroupItem>On-site pharmacists for consultations and advice.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Labor and Delivery Services
                   <CListGroup>
                   <CListGroupItem>Maternity care with delivery rooms, prenatal and postnatal care.</CListGroupItem> 
                   <CListGroupItem>Neonatal care for newborns, including NICU services if required.</CListGroupItem> 
                   <CListGroupItem>Gynecology and obstetrics services for women's health.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Preventive Health Check-ups
                   <CListGroup>
                   <CListGroupItem>Comprehensive health screening packages for early detection of diseases.</CListGroupItem> 
                   <CListGroupItem>Vaccination services for children and adults.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Dialysis and Renal Care
                   <CListGroup>
                   <CListGroupItem>Advanced dialysis units for patients with chronic kidney conditions.</CListGroupItem> 
                   <CListGroupItem>Consultation and treatment for nephrology-related issues.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Cancer Care (Oncology)
                   <CListGroup>
                   <CListGroupItem>Chemotherapy, radiation therapy, and surgical oncology.</CListGroupItem> 
                   <CListGroupItem>Palliative care for terminal-stage cancer patients.</CListGroupItem> 
                   <CListGroupItem>Supportive care and counseling for patients and families.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Mental Health Services
                   <CListGroup>
                   <CListGroupItem>Psychiatry, psychology, and counseling services.</CListGroupItem> 
                   <CListGroupItem>Addiction treatment programs.</CListGroupItem> 
                   <CListGroupItem>Cognitive-behavioral therapy and other mental health interventions.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Health and Wellness Programs
                   <CListGroup>
                   <CListGroupItem>Nutritional counseling and diet planning.</CListGroupItem> 
                   <CListGroupItem>Weight management programs.</CListGroupItem> 
                   <CListGroupItem>Smoking cessation and stress management workshops.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Telemedicine and Online Consultations 
                   <CListGroup>
                   <CListGroupItem>Remote consultations via video calls or online platforms for patients who cannot visit the hospital.</CListGroupItem> 
                   <CListGroupItem>Weight management programs.</CListGroupItem> 
                   <CListGroupItem>Digital health monitoring for chronic conditions.</CListGroupItem>
                   </CListGroup> 
                   </CListGroupItem>                  
                   </CListGroup>
                   </CContainer>
                   <Footer/>
              </>
               )}
               {isTabletOrMobile && (
               <>
                   <CContainer fluid style={hsptl_mbl_hdr}>
                   <CRow>
                          <CCol lg={12} xl={12} xxl={12} style={tblt_nm_lptp}><MenuIcon  onClick={mnu_ovr}  id="mnu_hsp" style={mnu_icn_tbllpt}/>Lifeline  Multi-Speciality Hospital Latest Facility<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer   id="lptp_vw" style={tbl_sd_vw}>
         <CListGroup>
         <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Emergency and Trauma Care</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Outpatient Department (OPD)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Inpatient Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Diagnostic and Imaging Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Surgical Facilities</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Specialty Clinics</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Rehabilitation and Physiotherapy</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Pharmacy Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Labor and Delivery Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Preventive Health Check-ups</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Dialysis and Renal Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Cancer Care (Oncology)</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Mental Health Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Health and Wellness Programs</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Telemedicine and Online Consultations</CListGroupItem>
    </CListGroup>
    </CContainer>
                   <CContainer fluid style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                   <CListGroup>
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Emergency and Trauma Care
                   <CListGroup>
                   <CListGroupItem>24/7 emergency services for critical injuries, illnesses, and accidents.</CListGroupItem> 
                   <CListGroupItem>Well-equipped ambulances with advanced life support systems.</CListGroupItem> 
                   <CListGroupItem>Trauma units for severe injuries requiring immediate medical attention.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>    
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Outpatient Department (OPD)
                   <CListGroup>
                   <CListGroupItem>Consultations with specialists across various fields such as cardiology, orthopedics, neurology, etc.</CListGroupItem> 
                   <CListGroupItem>Routine health check-ups and follow-ups.</CListGroupItem> 
                   <CListGroupItem>Diagnostic and screening services.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>    
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Inpatient Services
                   <CListGroup>
                   <CListGroupItem>General wards, semi-private, and private rooms for patients requiring hospitalization.</CListGroupItem> 
                   <CListGroupItem>Intensive Care Units (ICUs) including specialized units like NICU (Neonatal Intensive Care), PICU (Pediatric Intensive Care), and Cardiac ICUs.</CListGroupItem> 
                   <CListGroupItem>High Dependency Units (HDUs) for patients needing close monitoring.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Diagnostic and Imaging Services
                   <CListGroup>
                   <CListGroupItem>Advanced imaging technologies like MRI, CT scan, X-ray, ultrasound, and mammography.</CListGroupItem> 
                   <CListGroupItem>Laboratory services for blood tests, biopsy, and pathology.</CListGroupItem> 
                   <CListGroupItem>Diagnostic endoscopy, colonoscopy, and other non-invasive procedures.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Surgical Facilities
                   <CListGroup>
                   <CListGroupItem>Operation theaters equipped for various types of surgeries including general, orthopedic, cardiac, neuro, and minimally invasive surgeries.</CListGroupItem> 
                   <CListGroupItem>Pre-operative and post-operative care units.</CListGroupItem> 
                   <CListGroupItem>Day-care surgery facilities for minor procedures.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Specialty Clinics
                   <CListGroup>
                   <CListGroupItem>Cardiology</CListGroupItem> 
                   <CListGroupItem>Neurology</CListGroupItem> 
                   <CListGroupItem>Oncology (cancer care)</CListGroupItem> 
                   <CListGroupItem>Gastroenterology</CListGroupItem> 
                   <CListGroupItem>Pulmonology</CListGroupItem> 
                   <CListGroupItem>Dermatology</CListGroupItem> 
                   <CListGroupItem>Ophthalmology</CListGroupItem> 
                   <CListGroupItem>ENT (Ear, Nose, and Throat)</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Rehabilitation and Physiotherapy
                   <CListGroup>
                   <CListGroupItem>Physical therapy, occupational therapy, and speech therapy services for recovery post-surgery, injury, or illness.</CListGroupItem> 
                   <CListGroupItem>Specialized rehabilitation programs for stroke, cardiac, and post-orthopedic surgeries.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Pharmacy Services
                   <CListGroup>
                   <CListGroupItem>24-hour pharmacy services providing medications prescribed by in-house doctors and specialists.</CListGroupItem> 
                   <CListGroupItem>On-site pharmacists for consultations and advice.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Labor and Delivery Services
                   <CListGroup>
                   <CListGroupItem>Maternity care with delivery rooms, prenatal and postnatal care.</CListGroupItem> 
                   <CListGroupItem>Neonatal care for newborns, including NICU services if required.</CListGroupItem> 
                   <CListGroupItem>Gynecology and obstetrics services for women's health.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Preventive Health Check-ups
                   <CListGroup>
                   <CListGroupItem>Comprehensive health screening packages for early detection of diseases.</CListGroupItem> 
                   <CListGroupItem>Vaccination services for children and adults.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Dialysis and Renal Care
                   <CListGroup>
                   <CListGroupItem>Advanced dialysis units for patients with chronic kidney conditions.</CListGroupItem> 
                   <CListGroupItem>Consultation and treatment for nephrology-related issues.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Cancer Care (Oncology)
                   <CListGroup>
                   <CListGroupItem>Chemotherapy, radiation therapy, and surgical oncology.</CListGroupItem> 
                   <CListGroupItem>Palliative care for terminal-stage cancer patients.</CListGroupItem> 
                   <CListGroupItem>Supportive care and counseling for patients and families.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Mental Health Services
                   <CListGroup>
                   <CListGroupItem>Psychiatry, psychology, and counseling services.</CListGroupItem> 
                   <CListGroupItem>Addiction treatment programs.</CListGroupItem> 
                   <CListGroupItem>Cognitive-behavioral therapy and other mental health interventions.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Health and Wellness Programs
                   <CListGroup>
                   <CListGroupItem>Nutritional counseling and diet planning.</CListGroupItem> 
                   <CListGroupItem>Weight management programs.</CListGroupItem> 
                   <CListGroupItem>Smoking cessation and stress management workshops.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Telemedicine and Online Consultations 
                   <CListGroup>
                   <CListGroupItem>Remote consultations via video calls or online platforms for patients who cannot visit the hospital.</CListGroupItem> 
                   <CListGroupItem>Weight management programs.</CListGroupItem> 
                   <CListGroupItem>Digital health monitoring for chronic conditions.</CListGroupItem>
                   </CListGroup> 
                   </CListGroupItem>                  
                   </CListGroup>
                   </CContainer>
                   <Footer/>
               </>
              )}
              {isMobile && (
                   <>
                   <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Latest Facility<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                   <CListGroup>
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Emergency and Trauma Care
                   <CListGroup>
                   <CListGroupItem>24/7 emergency services for critical injuries, illnesses, and accidents.</CListGroupItem> 
                   <CListGroupItem>Well-equipped ambulances with advanced life support systems.</CListGroupItem> 
                   <CListGroupItem>Trauma units for severe injuries requiring immediate medical attention.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>    
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Outpatient Department (OPD)
                   <CListGroup>
                   <CListGroupItem>Consultations with specialists across various fields such as cardiology, orthopedics, neurology, etc.</CListGroupItem> 
                   <CListGroupItem>Routine health check-ups and follow-ups.</CListGroupItem> 
                   <CListGroupItem>Diagnostic and screening services.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>    
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Inpatient Services
                   <CListGroup>
                   <CListGroupItem>General wards, semi-private, and private rooms for patients requiring hospitalization.</CListGroupItem> 
                   <CListGroupItem>Intensive Care Units (ICUs) including specialized units like NICU (Neonatal Intensive Care), PICU (Pediatric Intensive Care), and Cardiac ICUs.</CListGroupItem> 
                   <CListGroupItem>High Dependency Units (HDUs) for patients needing close monitoring.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Diagnostic and Imaging Services
                   <CListGroup>
                   <CListGroupItem>Advanced imaging technologies like MRI, CT scan, X-ray, ultrasound, and mammography.</CListGroupItem> 
                   <CListGroupItem>Laboratory services for blood tests, biopsy, and pathology.</CListGroupItem> 
                   <CListGroupItem>Diagnostic endoscopy, colonoscopy, and other non-invasive procedures.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Surgical Facilities
                   <CListGroup>
                   <CListGroupItem>Operation theaters equipped for various types of surgeries including general, orthopedic, cardiac, neuro, and minimally invasive surgeries.</CListGroupItem> 
                   <CListGroupItem>Pre-operative and post-operative care units.</CListGroupItem> 
                   <CListGroupItem>Day-care surgery facilities for minor procedures.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Specialty Clinics
                   <CListGroup>
                   <CListGroupItem>Cardiology</CListGroupItem> 
                   <CListGroupItem>Neurology</CListGroupItem> 
                   <CListGroupItem>Oncology (cancer care)</CListGroupItem> 
                   <CListGroupItem>Gastroenterology</CListGroupItem> 
                   <CListGroupItem>Pulmonology</CListGroupItem> 
                   <CListGroupItem>Dermatology</CListGroupItem> 
                   <CListGroupItem>Ophthalmology</CListGroupItem> 
                   <CListGroupItem>ENT (Ear, Nose, and Throat)</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Rehabilitation and Physiotherapy
                   <CListGroup>
                   <CListGroupItem>Physical therapy, occupational therapy, and speech therapy services for recovery post-surgery, injury, or illness.</CListGroupItem> 
                   <CListGroupItem>Specialized rehabilitation programs for stroke, cardiac, and post-orthopedic surgeries.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Pharmacy Services
                   <CListGroup>
                   <CListGroupItem>24-hour pharmacy services providing medications prescribed by in-house doctors and specialists.</CListGroupItem> 
                   <CListGroupItem>On-site pharmacists for consultations and advice.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Labor and Delivery Services
                   <CListGroup>
                   <CListGroupItem>Maternity care with delivery rooms, prenatal and postnatal care.</CListGroupItem> 
                   <CListGroupItem>Neonatal care for newborns, including NICU services if required.</CListGroupItem> 
                   <CListGroupItem>Gynecology and obstetrics services for women's health.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Preventive Health Check-ups
                   <CListGroup>
                   <CListGroupItem>Comprehensive health screening packages for early detection of diseases.</CListGroupItem> 
                   <CListGroupItem>Vaccination services for children and adults.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Dialysis and Renal Care
                   <CListGroup>
                   <CListGroupItem>Advanced dialysis units for patients with chronic kidney conditions.</CListGroupItem> 
                   <CListGroupItem>Consultation and treatment for nephrology-related issues.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Cancer Care (Oncology)
                   <CListGroup>
                   <CListGroupItem>Chemotherapy, radiation therapy, and surgical oncology.</CListGroupItem> 
                   <CListGroupItem>Palliative care for terminal-stage cancer patients.</CListGroupItem> 
                   <CListGroupItem>Supportive care and counseling for patients and families.</CListGroupItem> 
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Mental Health Services
                   <CListGroup>
                   <CListGroupItem>Psychiatry, psychology, and counseling services.</CListGroupItem> 
                   <CListGroupItem>Addiction treatment programs.</CListGroupItem> 
                   <CListGroupItem>Cognitive-behavioral therapy and other mental health interventions.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Health and Wellness Programs
                   <CListGroup>
                   <CListGroupItem>Nutritional counseling and diet planning.</CListGroupItem> 
                   <CListGroupItem>Weight management programs.</CListGroupItem> 
                   <CListGroupItem>Smoking cessation and stress management workshops.</CListGroupItem>
                   </CListGroup>
                   </CListGroupItem>  
                   <CListGroupItem style={oph}><IoIosArrowDroprightCircle/>Telemedicine and Online Consultations 
                   <CListGroup>
                   <CListGroupItem>Remote consultations via video calls or online platforms for patients who cannot visit the hospital.</CListGroupItem> 
                   <CListGroupItem>Weight management programs.</CListGroupItem> 
                   <CListGroupItem>Digital health monitoring for chronic conditions.</CListGroupItem>
                   </CListGroup> 
                   </CListGroupItem>                  
                   </CListGroup>
                   </CContainer>
                   <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default LatestFacility;