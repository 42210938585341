import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Pediatric() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
            fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="pediatric">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline Multi-Speciality Hospital Pediatric<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/baby.jfif"}/>General Pediatrics:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Well-child checkup</span>:Routine health checkups that track growth and development, administer vaccinations, and address any health concerns.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Vaccinations</span>: Immunizations to prevent common childhood diseases such as measles, mumps, rubella, polio, and influenza.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Common childhood illnesses</span>: Treatment for illnesses like ear infections, colds, flu, asthma, allergies, and skin conditions like eczema.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Nutritional guidance</span>: Support for healthy eating habits and managing issues like obesity or malnutrition.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Developmental assessments</span>: Monitoring of physical, emotional, and cognitive development to ensure children meet important milestones.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neonatology.jfif"}/>Neonatology:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Premature infants</span>: Management of premature babies who require special medical attention for underdeveloped organs.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>NICU (Neonatal Intensive Care Unit)</span>: Complete or partial removal of the breast to treat or prevent breast cancer.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Congenital disorders</span>:Specialized care for critically ill or premature newborns, including respiratory support, feeding assistance, and monitoring for developmental issues.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/cardiology.png"}/>Pediatric Cardiology:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Congenital heart disease</span>: Diagnosis and management of heart conditions present at birth, including defects in the heart's structure or rhythm.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cardiac surgeries</span>: Pediatric cardiac surgeons may perform procedures to correct heart defects.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/oncology.png"}/>Pediatric Oncology:-</h5>
                       <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Childhood cancers</span>: Diagnosis and treatment of cancers like leukemia, lymphoma, neuroblastoma, and brain tumors. Treatment may involve chemotherapy, radiation, surgery, and other therapies.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Bone marrow transplant</span>: Used in treating certain types of cancer or blood disorders.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neuorology.png"}/>Pediatric Neurology:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Seizure disorders and epilepsy</span>:  Diagnosis and management of neurological conditions that affect the brain’s electrical activity.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Developmental delays</span>: Assessment and treatment of delayed milestones, speech difficulties, and learning disabilities.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cerebral palsy</span>:  Management of this condition, which affects movement and muscle tone.</CListGroupItem> 
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/pulmonology.jfif"}/>Pediatric Pulmonology:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Asthma and respiratory conditions</span>:  Diagnosis and management of chronic respiratory conditions like asthma, cystic fibrosis, and sleep apnea.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Infections</span>: Treatment of lung infections such as pneumonia and bronchiolitis.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/endocrology.jfif"}/>Pediatric Endocrinology:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Growth disorders</span>: Diagnosis and treatment of hormonal imbalances that affect a child’s growth or development.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Diabetes</span>: Management of Type 1 and Type 2 diabetes in children, including insulin therapy and diet.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Thyroid disorders</span>: Conditions affecting the thyroid gland, which controls metabolism and growth.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/gastrology.png"}/>Pediatric Gastroenterology:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Digestive disorders</span>: Diagnosis and treatment of conditions affecting the digestive tract, such as celiac disease, Crohn’s disease, and irritable bowel syndrome (IBS).</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Feeding and nutrition problems</span>:  Addressing issues like failure to thrive, feeding intolerance, and reflux.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/surgery.png"}/>Pediatric Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Congenital abnormalities</span>: Surgery to correct birth defects such as cleft palate, spina bifida, and heart malformations.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Emergency surgery</span>: Procedures for trauma, appendicitis, or other urgent medical conditions.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/infection.jfif"}/>Pediatric Infectious Diseases:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Infectious disease management</span>: Diagnosis and treatment of serious infections in children, such as meningitis, tuberculosis, and MRSA.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Immunocompromised children</span>: Special care for children with weakened immune systems, such as those undergoing chemotherapy or organ transplants.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/adoles.jfif"}/>Adolescent Medicine:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Mental health</span>: Addressing issues like depression, anxiety, ADHD, and eating disorders in teenagers.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Puberty and reproductive health</span>: Guidance and care related to puberty, menstrual issues, and reproductive health concerns.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Substance Abuse</span>: Treatment and prevention of drug, alcohol, and tobacco use in adolescents.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/orthopedic.jfif"}/>Pediatric Orthopedics:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Bone fractures and injuries</span>: Treatment of broken bones and joint injuries.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Congenital musculoskeletal conditions</span>: Management of conditions like clubfoot, scoliosis, and limb deformities.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                    </CRow>
                    <CRow>
                       <CCol xs={12} style={{padding:'6%'}}>
                         <CListGroup><h5 style={lml}>Services Provided:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Growth and developmental monitoring</span>: Ensuring that children grow and develop appropriately.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Acute and chronic illness management</span>:Treatment for common infections, chronic conditions, and more severe illnesses.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Behavioral health</span>: Evaluation and treatment of emotional, social, and behavioral concerns.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Pediatric emergency care</span>: Treatment for acute illnesses, injuries, or accidents.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Pediatric;