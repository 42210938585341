import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Homecare() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.2em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
        const mnu_icn_tbllpt={
          fontSize:'1.8em',
          color:'#921A40',
          float:'left',
          position:'relative',
          fontWeight:'900'
      }
      const lptp_lst_vw={
        cursor:'pointer',
        background:'linear-gradient(90deg, white, white)',
        color:'black',
        fontFamily:'Montserrat',
        textAlign:'left',
        fontWeight:'800',
        boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'

      }
      const tbl_sd_vw={
        fontFamily:'Montserrat',
        zIndex:2999,
        position:'absolute',
        float:'left',
        width:'70%',
        marginLeft:'-50%',
        top:'5.6%',
       height:'auto',
        visibility:'hidden',
        display:'none',
        boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
      }
      function mnu_ovr(e){
        e.preventDefault();
       if($("#lptp_vw").css('visibility')!='visible'){
            document.getElementById("lptp_vw").style.visibility='visible';
            document.getElementById("lptp_vw").style.display='inline-block';
            document.getElementById("mnu_hsp").style.color="white";
        }
            else
            {
              document.getElementById("lptp_vw").style.visibility='hidden';
              document.getElementById("lptp_vw").style.display='none';
              document.getElementById("mnu_hsp").style.color="#921A40";
            }
        return false;
    }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             var tmo=$(".rdz");
             var amo=tmo.length;
             var dmv=0;
             while(amo!=0){
               var kbm=amo-1;
               var klm1=$(".rdz").eq(kbm).height();
               if(klm1>dmv){
                 dmv=klm1;
               }
                amo--;
             }
            
             $(".rdz").css({"height" : dmv+"px"});
             var tmo1=$(".rfz");
             var amo1=tmo1.length;
             var dmv1=0;
             while(amo1!=0){
               var kbm=amo1-1;
               var klm1=$(".rfz").eq(kbm).height();
               if(klm1>dmv1){
                 dmv1=klm1;
               }
                amo1--;
             }
            
             $(".rfz").css({"height" : dmv1+"px"});
            }
          }, [isLoaded]);
        
          return (
            <div className="home_care">
          {isDesktopOrLaptop && (
             <>
            <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol lg={12} xl={12} xxl={12} style={hsptl_nm}><MenuIcon  onClick={mnu_ovr}  id="mnu_hsp" style={mnu_icn_tbllpt}/>Lifeline  Multi-Speciality Hospital Home Care<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer   id="lptp_vw" style={tbl_sd_vw}>
         <CListGroup>
         <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Nursing Care Home</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Doctor Home Visit</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Physiotherapy at Home</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based Rehabilitation</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based Palliative and Hospice Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Health Aide Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Dialysis</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;IV Therapy and Medication Administration</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based DIagnostic Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based Respiratory Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Wound Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home-Based Chemotherapy</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Telemedicine and  Remote Monitoring</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Medical Equipment Rental  and Support</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Pain Managment and Supportive Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Counseling and Emotional Support</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Nutritional and Dietary Support</CListGroupItem>
    </CListGroup>
    </CContainer>
                   <CContainer style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                    <CRow>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/nursing_care.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Nursing Care at Home</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Skilled Nursing Services</span>: Qualified nurses visit the patient’s home to provide medical care such as wound dressing, medication administration, injections, IV therapy, and monitoring vital signs.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Post-Surgical Care</span>: Assistance with post-operative care, including dressing changes, pain management, and recovery monitoring.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/doctor_home_visit.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Doctor Home Visits</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Regular check-ups or specialist visits for patients who are bedridden, elderly, or unable to visit the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Emergency consultations or assessments when hospital visits are not immediately possible.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/physio.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Physiotherapy at Home</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Physical therapy for patients recovering from surgeries, injuries, or neurological conditions like stroke.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Tailored exercise plans, mobility assistance, and rehabilitation programs in a home setting.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/hoembased.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Rehabilitation</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Speech therapy, occupational therapy, and cognitive rehabilitation for patients with conditions like stroke, brain injury, or speech disorders.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Care plans designed for gradual improvement within the home environment.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/pallative.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Palliative and Hospice Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>End-of-life care for patients with terminal illnesses, focusing on pain management and comfort.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Emotional, psychological, and spiritual support for both the patient and family members.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/home.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home Health Aide Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Assistance with daily living activities such as bathing, dressing, feeding, and mobility for elderly or disabled patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Trained caregivers help maintain hygiene, nutrition, and overall well-being of the patient.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/dialysis.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Dialysis</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>At-home dialysis services for patients with kidney conditions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Hospital staff sets up equipment and trains family members or caregivers to assist with dialysis sessions.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/iv.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>IV Therapy and Medication Administration</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Administration of intravenous (IV) medications, fluids, or nutrition (TPN) at home for patients who require ongoing treatment.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring and managing treatment for conditions such as infections, dehydration, or chronic diseases.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/diagnostic.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Diagnostic Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Collection of blood samples, urine, or other tests by trained professionals at the patient’s home.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Portable diagnostic equipment for tests like ECG, X-rays, and ultrasounds, reducing the need to visit the hospital.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/respiratory.jpg'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Respiratory Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Oxygen therapy, nebulization, and tracheostomy care for patients with respiratory conditions like COPD, asthma, or post-ICU recovery.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring and managing ventilators and other respiratory support systems at home.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/wound.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Wound Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Professional care for patients with chronic wounds, bedsores, or surgical incisions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Dressing changes, infection control, and promoting wound healing under professional supervision.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/chemo.jpg'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Chemotherapy</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Chemotherapy administration for cancer patients who can’t frequently visit the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring for side effects and ensuring that the patient is comfortable during and after the treatment.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/telemedicne.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Telemedicine and Remote Monitoring</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Virtual consultations with doctors or specialists through telemedicine platforms.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Remote monitoring of health parameters such as blood pressure, glucose levels, heart rate, and oxygen saturation using wearable devices.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/medical_equipment.jpeg'} />
  <CCardBody>
    <CCardText>
    <h5>Medical Equipment Rental and Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Provision of essential medical equipment such as hospital beds, wheelchairs, oxygen cylinders, or ventilators.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Training for family members on how to use and maintain the equipment safely.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/pain.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Pain Management and Supportive Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Assistance in managing chronic pain due to conditions like arthritis, cancer, or post-surgery recovery.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Guidance in adjusting medications and lifestyle for improved comfort and pain control.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/brain.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Counseling and Emotional Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Psychological counseling and support for patients and families dealing with chronic illness, disability, or end-of-life care.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Support groups or therapy for managing mental health challenges during recovery.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol lg={4} xl={4} xxl={4}>
                        <CCard className="rfz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/nutrition.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Nutritional and Dietary Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Diet planning and nutrition counseling for patients with specific dietary needs, such as diabetes, heart disease, or after major surgeries.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Specialized feeding services for patients who are unable to eat normally.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                    </CRow>
                   </CContainer>
                   <Footer/>
              </>
               )}
               {isTabletOrMobile && (
                    <>
              <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol sm={12} md={12} lg={12} style={hsptl_nm}><MenuIcon  onClick={mnu_ovr}  id="mnu_hsp" style={mnu_icn_tbllpt}/>Lifeline  Multi-Speciality Hospital Home Care<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer   id="lptp_vw" style={tbl_sd_vw}>
         <CListGroup>
         <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Nursing Care Home</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Doctor Home Visit</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Physiotherapy at Home</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based Rehabilitation</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based Palliative and Hospice Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Health Aide Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Dialysis</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;IV Therapy and Medication Administration</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based DIagnostic Services</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home Based Respiratory Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Wound Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Home-Based Chemotherapy</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Telemedicine and  Remote Monitoring</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Medical Equipment Rental  and Support</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Pain Managment and Supportive Care</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Counseling and Emotional Support</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><IoIosArrowDroprightCircle/>&nbsp;Nutritional and Dietary Support</CListGroupItem>
    </CListGroup>
    </CContainer>
                   <CContainer style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                    <CRow>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/nursing_care.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Nursing Care at Home</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Skilled Nursing Services</span>: Qualified nurses visit the patient’s home to provide medical care such as wound dressing, medication administration, injections, IV therapy, and monitoring vital signs.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Post-Surgical Care</span>: Assistance with post-operative care, including dressing changes, pain management, and recovery monitoring.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/doctor_home_visit.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Doctor Home Visits</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Regular check-ups or specialist visits for patients who are bedridden, elderly, or unable to visit the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Emergency consultations or assessments when hospital visits are not immediately possible.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/physio.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Physiotherapy at Home</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Physical therapy for patients recovering from surgeries, injuries, or neurological conditions like stroke.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Tailored exercise plans, mobility assistance, and rehabilitation programs in a home setting.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/hoembased.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Rehabilitation</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Speech therapy, occupational therapy, and cognitive rehabilitation for patients with conditions like stroke, brain injury, or speech disorders.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Care plans designed for gradual improvement within the home environment.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/pallative.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Palliative and Hospice Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>End-of-life care for patients with terminal illnesses, focusing on pain management and comfort.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Emotional, psychological, and spiritual support for both the patient and family members.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/home.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home Health Aide Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Assistance with daily living activities such as bathing, dressing, feeding, and mobility for elderly or disabled patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Trained caregivers help maintain hygiene, nutrition, and overall well-being of the patient.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/dialysis.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Dialysis</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>At-home dialysis services for patients with kidney conditions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Hospital staff sets up equipment and trains family members or caregivers to assist with dialysis sessions.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/iv.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>IV Therapy and Medication Administration</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Administration of intravenous (IV) medications, fluids, or nutrition (TPN) at home for patients who require ongoing treatment.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring and managing treatment for conditions such as infections, dehydration, or chronic diseases.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/diagnostic.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Diagnostic Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Collection of blood samples, urine, or other tests by trained professionals at the patient’s home.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Portable diagnostic equipment for tests like ECG, X-rays, and ultrasounds, reducing the need to visit the hospital.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/respiratory.jpg'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Respiratory Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Oxygen therapy, nebulization, and tracheostomy care for patients with respiratory conditions like COPD, asthma, or post-ICU recovery.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring and managing ventilators and other respiratory support systems at home.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/wound.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Wound Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Professional care for patients with chronic wounds, bedsores, or surgical incisions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Dressing changes, infection control, and promoting wound healing under professional supervision.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/chemo.jpg'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Chemotherapy</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Chemotherapy administration for cancer patients who can’t frequently visit the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring for side effects and ensuring that the patient is comfortable during and after the treatment.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/telemedicne.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Telemedicine and Remote Monitoring</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Virtual consultations with doctors or specialists through telemedicine platforms.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Remote monitoring of health parameters such as blood pressure, glucose levels, heart rate, and oxygen saturation using wearable devices.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/medical_equipment.jpeg'} />
  <CCardBody>
    <CCardText>
    <h5>Medical Equipment Rental and Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Provision of essential medical equipment such as hospital beds, wheelchairs, oxygen cylinders, or ventilators.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Training for family members on how to use and maintain the equipment safely.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/pain.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Pain Management and Supportive Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Assistance in managing chronic pain due to conditions like arthritis, cancer, or post-surgery recovery.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Guidance in adjusting medications and lifestyle for improved comfort and pain control.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/brain.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Counseling and Emotional Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Psychological counseling and support for patients and families dealing with chronic illness, disability, or end-of-life care.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Support groups or therapy for managing mental health challenges during recovery.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol sm={6} md={6} lg={6}>
                        <CCard className="rdz" style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/nutrition.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Nutritional and Dietary Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Diet planning and nutrition counseling for patients with specific dietary needs, such as diabetes, heart disease, or after major surgeries.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Specialized feeding services for patients who are unable to eat normally.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                    </CRow>
                   </CContainer>
                   <Footer/>
               </>
              )}
              {isMobile && (
                   <>
                  <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Home Care<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={{height:'auto',width:'99%',padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                    <CRow>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/nursing_care.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Nursing Care at Home</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Skilled Nursing Services</span>: Qualified nurses visit the patient’s home to provide medical care such as wound dressing, medication administration, injections, IV therapy, and monitoring vital signs.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/><span style={{color:'blue',fontWeight:'600'}}>Post-Surgical Care</span>: Assistance with post-operative care, including dressing changes, pain management, and recovery monitoring.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/doctor_home_visit.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Doctor Home Visits</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Regular check-ups or specialist visits for patients who are bedridden, elderly, or unable to visit the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Emergency consultations or assessments when hospital visits are not immediately possible.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/physio.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Physiotherapy at Home</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Physical therapy for patients recovering from surgeries, injuries, or neurological conditions like stroke.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Tailored exercise plans, mobility assistance, and rehabilitation programs in a home setting.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/hoembased.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Rehabilitation</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Speech therapy, occupational therapy, and cognitive rehabilitation for patients with conditions like stroke, brain injury, or speech disorders.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Care plans designed for gradual improvement within the home environment.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/pallative.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Palliative and Hospice Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>End-of-life care for patients with terminal illnesses, focusing on pain management and comfort.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Emotional, psychological, and spiritual support for both the patient and family members.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/home.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home Health Aide Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Assistance with daily living activities such as bathing, dressing, feeding, and mobility for elderly or disabled patients.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Trained caregivers help maintain hygiene, nutrition, and overall well-being of the patient.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/dialysis.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Dialysis</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>At-home dialysis services for patients with kidney conditions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Hospital staff sets up equipment and trains family members or caregivers to assist with dialysis sessions.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/iv.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>IV Therapy and Medication Administration</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Administration of intravenous (IV) medications, fluids, or nutrition (TPN) at home for patients who require ongoing treatment.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring and managing treatment for conditions such as infections, dehydration, or chronic diseases.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/diagnostic.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Diagnostic Services</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Collection of blood samples, urine, or other tests by trained professionals at the patient’s home.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Portable diagnostic equipment for tests like ECG, X-rays, and ultrasounds, reducing the need to visit the hospital.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/respiratory.jpg'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Respiratory Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Oxygen therapy, nebulization, and tracheostomy care for patients with respiratory conditions like COPD, asthma, or post-ICU recovery.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring and managing ventilators and other respiratory support systems at home.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/wound.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Wound Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Professional care for patients with chronic wounds, bedsores, or surgical incisions.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Dressing changes, infection control, and promoting wound healing under professional supervision.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/chemo.jpg'} />
  <CCardBody>
    <CCardText>
    <h5>Home-Based Chemotherapy</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Chemotherapy administration for cancer patients who can’t frequently visit the hospital.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Monitoring for side effects and ensuring that the patient is comfortable during and after the treatment.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/telemedicne.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Telemedicine and Remote Monitoring</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Virtual consultations with doctors or specialists through telemedicine platforms.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Remote monitoring of health parameters such as blood pressure, glucose levels, heart rate, and oxygen saturation using wearable devices.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/medical_equipment.jpeg'} />
  <CCardBody>
    <CCardText>
    <h5>Medical Equipment Rental and Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Provision of essential medical equipment such as hospital beds, wheelchairs, oxygen cylinders, or ventilators.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Training for family members on how to use and maintain the equipment safely.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/pain.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Pain Management and Supportive Care</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Assistance in managing chronic pain due to conditions like arthritis, cancer, or post-surgery recovery.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Guidance in adjusting medications and lifestyle for improved comfort and pain control.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/brain.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Counseling and Emotional Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Psychological counseling and support for patients and families dealing with chronic illness, disability, or end-of-life care.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Support groups or therapy for managing mental health challenges during recovery.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                        <CCol xs={12}>
                        <CCard style={{padding:'1%',boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" style={{width:'100%',height:'350px'}} src={'http://localhost/hospital/image/nutrition.jfif'} />
  <CCardBody>
    <CCardText>
    <h5>Nutritional and Dietary Support</h5>
    <CListGroup>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Diet planning and nutrition counseling for patients with specific dietary needs, such as diabetes, heart disease, or after major surgeries.</CListGroupItem>
      <CListGroupItem style={{textAlign:'left'}}><IoIosArrowDroprightCircle/>Specialized feeding services for patients who are unable to eat normally.</CListGroupItem>
    </CListGroup>
    </CCardText>
  </CCardBody>
</CCard>
                        </CCol>
                    </CRow>
                   </CContainer>
                   <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Homecare;