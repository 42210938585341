import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function HospitalNetwork() {
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
        const adrs={
            padding:'1%',
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px'
        }
        const mps={
            height:'auto',
            boxShadow:'rgba(0, 0, 0, 0.09) 0px 2px 1px 0px, rgba(0, 0, 0, 0.09) 0px 4px 2px 0px, rgba(0, 0, 0, 0.09) 0px 8px 4px 0px, rgba(0, 0, 0, 0.09) 0px 16px 8px 0px, rgba(0, 0, 0, 0.09) 0px 32px 16px 0px'
        }
        const klw={
            padding:'1%',
            boxShadow:'rgba(17, 17, 26, 0.1) 0px 1px 0px 0px'
        }
        const klwl={
            background: 'linear-gradient(90deg, #B6FFA1,#B6FFA1)',   
        }
        const hsptl_nm_lptp={
            fontFamily:'Baskerville',
            fontWeight:'700',
            textAlign:'center',
            fontSize:'1.4em'
        }
        const tblt_nm_lptp={
            fontFamily:'Baskerville',
            fontWeight:'700',
            textAlign:'center',
            fontSize:'1.2em'
        }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="hospital_network">
         {isDesktopOrLaptop && (
             <>
               <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol lg={12} xl={12} xxl={12} style={hsptl_nm_lptp}>Lifeline  Multi-Speciality Hospital Network<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                  <CContainer style={adrs}>
                  <CRow>
                     <CCol  lg={6} xl={6} xxl={6} style={mps}>
                     <CContainer fluid style={klw}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.607791292!2d80.79348417543079!3d26.661037376798085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c092e05e694cb%3A0x97097a54a09434fe!2sLifeline%20Multi%20Speciality%20Hospital!5e0!3m2!1sen!2sin!4v1728452105330!5m2!1sen!2sin" width="98%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <h5>Lifeline Multi Speciality Hospital</h5><br/>
                       </CContainer>
                       <CContainer fluid style={klwl}>
                       <h6>
                       <span style={{color:'blue',fontWeight:'600'}}>Address</span>: Infront of PTC Company, Near Sarai Sahjadi, Mohan Road, Katibagiya, Lucknow, Uttar Pradesh 226401 
                       </h6>
                       </CContainer>
                     </CCol> 
                     <CCol lg={6} xl={6} xxl={6} style={mps}>
                     <CContainer fluid style={klw}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.530298275948!2d80.82767297531002!3d26.69550067677721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bf95af5506c71%3A0x6dd9af98a2972cc4!2sLife%20Line%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1728453760863!5m2!1sen!2sin" width="98%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <h5>Life Line Nursing Home</h5><br/>
                       </CContainer>
                       <CContainer fluid style={klwl}>
                       <h6>
                       <span style={{color:'blue',fontWeight:'600'}}>Address</span>: Banthra Sikander Pur, Uttar Pradesh 226401 
                       </h6>
                       </CContainer>
                     </CCol>
                  </CRow>
                  </CContainer>
                   <Footer/>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol sm={12} md={12} lg={12} style={tblt_nm_lptp}>Lifeline  Multi-Speciality Hospital Network<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                  <CContainer fluid style={adrs}>
                  <CRow>
                     <CCol  sm={6} md={6} lg={6} style={mps}>
                     <CContainer fluid style={klw}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.607791292!2d80.79348417543079!3d26.661037376798085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c092e05e694cb%3A0x97097a54a09434fe!2sLifeline%20Multi%20Speciality%20Hospital!5e0!3m2!1sen!2sin!4v1728452105330!5m2!1sen!2sin" width="98%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <h5>Lifeline Multi Speciality Hospital</h5><br/>
                       </CContainer>
                       <CContainer fluid style={klwl}>
                       <h6>
                       <span style={{color:'blue',fontWeight:'600'}}>Address</span>: Infront of PTC Company, Near Sarai Sahjadi, Mohan Road, Katibagiya, Lucknow, Uttar Pradesh 226401 
                       </h6>
                       </CContainer>
                     </CCol> 
                     <CCol sm={6} md={6} lg={6} style={mps}>
                     <CContainer fluid style={klw}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.530298275948!2d80.82767297531002!3d26.69550067677721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bf95af5506c71%3A0x6dd9af98a2972cc4!2sLife%20Line%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1728453760863!5m2!1sen!2sin" width="98%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <h5>Life Line Nursing Home</h5><br/>
                       </CContainer>
                       <CContainer fluid style={klwl}>
                       <h6>
                       <span style={{color:'blue',fontWeight:'600'}}>Address</span>: Banthra Sikander Pur, Uttar Pradesh 226401 
                       </h6>
                       </CContainer>
                     </CCol>
                  </CRow>
                  </CContainer>
                   <Footer/>
               </>
              )}
              {isMobile && (
                   <>
                   <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Network<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                  <CContainer fluid style={adrs}>
                  <CRow>
                     <CCol  xs={12} style={mps}>
                     <CContainer fluid style={klw}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.607791292!2d80.79348417543079!3d26.661037376798085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c092e05e694cb%3A0x97097a54a09434fe!2sLifeline%20Multi%20Speciality%20Hospital!5e0!3m2!1sen!2sin!4v1728452105330!5m2!1sen!2sin" width="98%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <h5>Lifeline Multi Speciality Hospital</h5><br/>
                       </CContainer>
                       <CContainer fluid style={klwl}>
                       <h6>
                       <span style={{color:'blue',fontWeight:'600'}}>Address</span>: Infront of PTC Company, Near Sarai Sahjadi, Mohan Road, Katibagiya, Lucknow, Uttar Pradesh 226401 
                       </h6>
                       </CContainer>
                     </CCol>
                     <CCol  xs={12} style={mps}>
                     <CContainer fluid style={klw}>
                     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3564.530298275948!2d80.82767297531002!3d26.69550067677721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bf95af5506c71%3A0x6dd9af98a2972cc4!2sLife%20Line%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1728453760863!5m2!1sen!2sin" width="98%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                       <h5>Life Line Nursing Home</h5><br/>
                       </CContainer>
                       <CContainer fluid style={klwl}>
                       <h6>
                       <span style={{color:'blue',fontWeight:'600'}}>Address</span>: Banthra Sikander Pur, Uttar Pradesh 226401 
                       </h6>
                       </CContainer>
                     </CCol>
                  </CRow>
                  </CContainer>
                   <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default HospitalNetwork;