import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';
import React,{ useState, useEffect }  from 'react';
import { CFormSwitch,CFormSelect,CCard,CCardImage,CCardBody,CCardTitle,CCardText,CFormCheck,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import MenuIcon from '@mui/icons-material/Menu';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import TextField from '@mui/material/TextField';
import $ from "jquery";
import { MdLocalPharmacy,MdDoneOutline,MdElderly,MdOutlineHome,MdMyLocation,MdOutlineReportProblem,MdOutlineWorkspacePremium,MdOutlineTrackChanges } from "react-icons/md";
import { FaHospitalAlt,FaWhatsapp,FaSave,FaSchool,FaRegUser,FaHistory,FaRegHospital } from "react-icons/fa";
import { TbAmbulance } from "react-icons/tb";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { PiGavel,PiOfficeChair } from "react-icons/pi";
import { BiCustomize } from "react-icons/bi";
import { FaVialVirus }  from "react-icons/fa6";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
function Ambulance() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
    
    useEffect(() => {
      
        setIsLoaded(true);
    }, []);
    
    useEffect(() => {//on load
        if (isLoaded) {
              
        }
        },[isLoaded]);
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const ambu_hdr={
        padding:'1%',
        fontFamily:'Montserrat',
        boxShadow:'rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 2px 2px 0px, rgba(0, 0, 0, 0.07) 0px 4px 4px 0px, rgba(0, 0, 0, 0.07) 0px 8px 8px 0px, rgba(0, 0, 0, 0.07) 0px 16px 16px 0px'
      }
      const ambu_map={
        padding:'1%',
        boxShadow:'rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 2px 2px 0px, rgba(0, 0, 0, 0.07) 0px 4px 4px 0px, rgba(0, 0, 0, 0.07) 0px 8px 8px 0px, rgba(0, 0, 0, 0.07) 0px 16px 16px 0px',
        width:'99%',
        height:'15.4em'
      }
      const ambu_type={
        padding:'1%',
        boxShadow:'rgba(0, 0, 0, 0.09) 0px 3px 12px 0px',
        width:'99%',
        height:'3em'
      }
      const ambu_dest={
        paddingTop:'2%',
        padding:'1%',
        boxShadow:'rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 2px 2px 0px, rgba(0, 0, 0, 0.07) 0px 4px 4px 0px, rgba(0, 0, 0, 0.07) 0px 8px 8px 0px, rgba(0, 0, 0, 0.07) 0px 16px 16px 0px',
        width:'99%',
        height:'14em'
      }
      const ambu_dest_inr={
        paddingTop:'2%',
        boxShadow:'rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 2px 2px 0px, rgba(0, 0, 0, 0.07) 0px 4px 4px 0px, rgba(0, 0, 0, 0.07) 0px 8px 8px 0px, rgba(0, 0, 0, 0.07) 0px 16px 16px 0px',
        width:'99%',
        height:'13em'
      }

      const orgn_bx={
          width:'100%',
      }
      const hdr_icn={
          fontSize:'3.0em',
          color:'#87A2FF'
      }
      
      const ambu_typ_lst={
          fontSize:'0.58em',
          fontFamily:'Consolas',
          fontWeight:'600',
          padding:'1%',
          boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px'
      }
      const drl={
          textAlign:'left',
          color:'black',
        fontSize:'1.0em',
        width:'99%',
        boxShadow:'rgba(0, 0, 0, 0.15) 0px 2px 8px 0px'
    }
    const mbl_sd_vw={
        fontFamily:'Montserrat',
        zIndex:2999,
        position:'absolute',
        float:'left',
        width:'40%',
        marginLeft:'-52%',
        top:'8.6%',
       height:'32.4em',
        visibility:'hidden',
        display:'none',
        boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
      }
    const mbl_mrk_vw={
        background:'linear-gradient(90deg, white, white)',
        fontFamily:'Montserrat',
        zIndex:2999,
        position:'absolute',
        float:'left',
        width:'100%',
        marginLeft:'-49%',
        top:'0%', 
        height:'36.9em',
        visibility:'hidden',
        display:'none',
        padding:'1%',
        boxShadow:'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'
      }
    const lml={
        cursor:'pointer',
        background:'linear-gradient(90deg, white, white)',
        color:'black',
        fontFamily:'Montserrat',
        textAlign:'left',
        fontWeight:'800',
        boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'
      }
      const home_stl={
        height:'2em',
        width:'90%',
        fontSize:'1em',
        boxShadow:'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',  
        background:'linear-gradient(90deg, #FFAD60, #FFAD60)',
        color:'black'
      }
      const wrk_stl={
        height:'2em',
        width:'90%',
        fontSize:'1em',
        boxShadow:'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',  
        background:'linear-gradient(90deg, #AFD198, #AFD198)',
        color:'black'
      }
      const scl_stl={
        height:'2em',
        width:'90%',
        fontSize:'1em',
        boxShadow:'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',  
        background:'linear-gradient(90deg, #F9E897, #F9E897)',
        color:'black'
      }
      const eld_stl={
        height:'2em',
        width:'90%',
        fontSize:'1em',
        boxShadow:'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',  
        background:'linear-gradient(90deg, #7FDBDA, #7FDBDA)',
        color:'black'
      }
      const cl_lg={
        // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
         borderRadius:'1%',
         width:'auto',
         float:'left',
         zIndex:'3999 !important',
         position:'fixed',
         bottom:'3.4%',
         left:'87.3%',
         height:'auto',
         color:'black',
         fontWeight:'700',
         cursor:'pointer'
       }
       const cl_lg2={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'0.0%',  
        left:'46%', 
        width: '50px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'100px',
        borderTopRightRadius:'100px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg3={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'36%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg4={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'3.0%',  
        left:'60%', 
        visibility:'hidden',
        display:'none',
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const cl_lg5={
        zIndex:'3999 !important',
        position:'fixed',
        bottom:'4.8%',  
        left:'48%',
        visibility:'hidden',
        display:'none', 
        width: '40px',
        height: '25px', /* as the half of the width */
        background: 'linear-gradient(90deg, white, white)',
        borderTopLeftRadius:'30px',
        borderTopRightRadius:'30px', 
        boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
       }
       const rb={
           color:'green'
       }
       function mdl(e){
        e.preventDefault();
        if($("#rd4").css('visibility')!='visible'){
        document.getElementById("rd4").style.visibility='visible';
        document.getElementById("rd4").style.display="inline-block";
        document.getElementById("rd6").style.visibility='visible';
        document.getElementById("rd6").style.display="inline-block";
        document.getElementById("rd5").style.visibility='visible';
        document.getElementById("rd5").style.display="inline-block";
        }
        else
        {
            document.getElementById("rd4").style.visibility='hidden';
            document.getElementById("rd4").style.display="none";
            document.getElementById("rd6").style.visibility='hidden';
            document.getElementById("rd6").style.display="none";
            document.getElementById("rd5").style.visibility='hidden';
            document.getElementById("rd5").style.display="none";
        }
        return false;
       }
       function mnu_ovr(e){
        e.preventDefault();
       if($("#sd_vw").css('visibility')!='visible'){
            document.getElementById("sd_vw").style.visibility='visible';
            document.getElementById("sd_vw").style.display='inline-block';
            document.getElementById("mnu_ambu").style.color="red";
        }
            else
            {
              document.getElementById("sd_vw").style.visibility='hidden';
              document.getElementById("sd_vw").style.display='none';
              document.getElementById("mnu_ambu").style.color="#87A2FF";
            }
        return false;
    }
    function mrk_ovr(e){
        e.preventDefault();
              document.getElementById("sd_vw").style.visibility='hidden';
              document.getElementById("sd_vw").style.display='none';
              document.getElementById("mnu_ambu").style.color="black";
        if($("#mrk_vw").css('visibility')!='visible'){
            document.getElementById("mrk_vw").style.visibility='visible';
            document.getElementById("mrk_vw").style.display='inline-block';
            document.getElementById("mrk_ambu").style.color="red";
        }
            else
            {
              document.getElementById("mrk_vw").style.visibility='hidden';
              document.getElementById("mrk_vw").style.display='none';
              document.getElementById("mrk_ambu").style.color="black";
            }
        return false;
    }
    function dn_clk(e){
        e.preventDefault();
        mrk_ovr(e);
        return false;
      }
    return (
        <div className="ambulance">
     {isDesktopOrLaptop && (
         <>
        
        <CContainer fluid>
        <CRow>
        <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
        Ambulance
        </CCol>
        </CRow>
        </CContainer>
         </>
          )}
          {isTabletOrMobile && (
               <>
                <CContainer fluid>
        <CRow>
        <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
        Ambulance
        </CCol>
        </CRow>
        </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                     <CContainer fluid style={ambu_hdr}>
                     <CRow>
                         <CCol  xs={2}><MenuIcon  id="mnu_ambu" onClick={mnu_ovr}  style={hdr_icn}/></CCol>
                         <CCol  xs={8}><TextField id="orgn_txt" label="Origin" color="warning" focused placeholder="Fetching Origin" style={orgn_bx}/></CCol>
                         <CCol  xs={2}><StarsRoundedIcon  id="mrk_ambu" onClick={mrk_ovr} style={hdr_icn}/></CCol>
                     </CRow>
                     </CContainer>
                     <CContainer fluid id="sd_vw" style={mbl_sd_vw}>
              <CListGroup>
  <CListGroupItem style={lml}><MdOutlineTrackChanges/>&nbsp;Current Booking</CListGroupItem>
  <CListGroupItem style={lml}><FaRegHospital/>&nbsp;Nearby Hospitals</CListGroupItem>
  <CListGroupItem style={lml}><FaHistory/>&nbsp;Booking History</CListGroupItem>
  <CListGroupItem style={lml}><MdOutlineWorkspacePremium/>&nbsp;Premium Bookings</CListGroupItem>
  <CListGroupItem style={lml}><TbAmbulance/>&nbsp;Ambulance Types</CListGroupItem>
  <CListGroupItem style={lml}><MdOutlineReportProblem/>&nbsp;Grievances</CListGroupItem>
  <CListGroupItem style={lml}><HiOutlineCurrencyRupee/>&nbsp;Refunds</CListGroupItem>
  <CListGroupItem style={lml}><PiGavel/>&nbsp;Terms & Condition</CListGroupItem>
  <CListGroupItem style={lml}><FaRegUser/>&nbsp;Login</CListGroupItem>
  
</CListGroup>
              </CContainer>
              <CContainer fluid id="mrk_vw" style={mbl_mrk_vw}>
                <CRow>
                <CCol xs={7} style={{visibility:'hidden'}}>A</CCol>
                <CCol xs={5} style={{textAlign:'left'}}>
                <CFormSwitch checked size="xl" label="Booking for Self" id="t55"/>
                </CCol>
                    <CCol xs={12}>
                    <CInputGroup className="mb-3">
  <CFormInput placeholder="Origin" aria-label="Origin" aria-describedby="basic-addon2"/>
  <CInputGroupText id="basic-addon2"><MdMyLocation/></CInputGroupText>
</CInputGroup>
                    </CCol>
                </CRow>
                <CRow>
                <CCol xs={6}><CBadge style={home_stl}><MdOutlineHome/>&nbsp;Home</CBadge></CCol>
                <CCol xs={6}><CBadge style={wrk_stl}><PiOfficeChair/>&nbsp;Work</CBadge></CCol>
                </CRow>
                <br/>
                <CRow>
                <CCol xs={6}><CBadge style={scl_stl}><FaSchool/>&nbsp;Kid's School</CBadge></CCol>    
                <CCol xs={6}><CBadge style={eld_stl}><MdElderly/>&nbsp;Elderly Parents</CBadge></CCol>
                </CRow>
                <br/>
                <CButton onClick={dn_clk} color="danger"  variant="outline" style={{width:'90%',boxShadow:'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'}}><MdDoneOutline/>&nbsp;Done</CButton>
                <br/>
                <br/>
                <CContainer fluid style={{padding:'1%',boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}>
                <CRow>
                <CCol xs={12}>
                <CInputGroup className="mb-3">
                <CInputGroupText id="basic-addon3"><BiCustomize/></CInputGroupText>
  <CFormInput placeholder="Enter Custom Mark" aria-label="Mark" aria-describedby="basic-addon3"/>

</CInputGroup>
                </CCol>
                </CRow>
                <br/>
                <CButton color="primary"  variant="outline" style={{width:'90%',boxShadow:'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'}}><FaSave/>&nbsp;Save</CButton>
                </CContainer>
                <br/>
                <CContainer fluid style={{height:'12em',padding:'1%',boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'}}>
               No Custom Mark Entered yet.......
                </CContainer>
              </CContainer>
                     <CContainer fluid style={ambu_map}>
                      map
                     </CContainer>
                     <CContainer fluid style={ambu_type}>
                    <CRow>
                     <CCol style={ambu_typ_lst} xs={2}>BLS<CImage fluid rounded src="http://localhost/Hospital/image/bls_ambulance.png" /></CCol>
                     <CCol style={ambu_typ_lst}  xs={2}>ALS<CImage fluid rounded src="http://localhost/Hospital/image/als_ambulance.png" /></CCol>
                     <CCol style={ambu_typ_lst}  xs={2}>ICU<CImage fluid rounded src="http://localhost/Hospital/image/icu_ambulance.png" /></CCol>
                     <CCol style={ambu_typ_lst}  xs={2}>Non Emergency<CImage fluid rounded src="http://localhost/Hospital/image/non-emergency_ambulance.png" /></CCol>
                     <CCol style={ambu_typ_lst}  xs={2}>2/3 Wheeler<CImage fluid rounded src="http://localhost/Hospital/image/three.jfif" /></CCol>
                     <CCol style={ambu_typ_lst}  xs={2}>Dead Body<CImage fluid rounded src="http://localhost/Hospital/image/dead_body_ambulance.png" /></CCol>
                     </CRow>
                     </CContainer>
                     <br/>
                     <CContainer fluid style={ambu_dest}>
                       <CContainer fluid style={ambu_dest_inr}>
                       <CRow>
                         <CCol  xs={12}><TextField id="dest_txt" label="Destination" color="warning" focused placeholder="Enter your Destination" style={orgn_bx}/></CCol>
                         </CRow>
                         <CRow>
                         <CCol  xs={12}>
                         <CListGroup>
                         <CListGroupItem style={drl}><CBadge style={{color:'black'}}>Destination 1</CBadge></CListGroupItem>
                         <CListGroupItem style={drl}><CBadge style={{color:'black'}}>Destination 2</CBadge></CListGroupItem>
                         <CListGroupItem style={drl}><CBadge style={{color:'black'}}>Destination 3</CBadge></CListGroupItem>
                         </CListGroup>
                         </CCol>
                         </CRow>
                       </CContainer>
                     </CContainer>
                     <CContainer fluid style={cl_lg}>
            <CRow>
             <CCol  lg={12} xl={12} xxl={12} style={{background:'linear-gradient(90deg, white, white)'}}>
           <FaWhatsapp style={{color:'#006769',fontSize:'2.0em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
            <CContainer fluid style={cl_lg2}>
            <CImage onClick={mdl} className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'40em'} height={'40em'}/>
           <CContainer fluid id="rd4" style={cl_lg3}><Link  style={{color:'black',textDecoration:'none'}} to="/hospital"><FaHospitalAlt style={rb}/></Link></CContainer>
           <CContainer fluid id="rd6" style={cl_lg5}><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><MdLocalPharmacy style={rb}/></Link></CContainer>
           <CContainer fluid id="rd5" style={cl_lg4}><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><FaVialVirus style={rb}/></Link></CContainer>
            </CContainer>
                   </>
              )}
              </div>
    );
}

export default Ambulance;