import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {CContainer} from '@coreui/react';
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import $ from "jquery";
import Home from './home';
import Ambulance from './ambulance';
import Hospital from './hospital';
import Pathalogy from './pathalogy';
import Pharmacy from './pharmacy';
import Bulletin from './health_bulletin';
import Homecare from './home_care';
import Hospjob from './hospital_jobs';
import Research from './research_dev';
import Network from './hospital_network';
import Latest from './latest_fac';
import Patient from './patient_care';
import Tie from './tie_ups';
import  Obstretic from './obstretic';
import  Orthopedic from './orthopedic';
import General_Surgery from './general_surgery';
import Pediatric from './pediatric';
import Gastroentrology from './gastroenterology';
import Neurology from './neurology';
import Urology from './urology';
import Ent from './ent';
import Oncology from './oncology';
import Cardiology from './cardiology';
import PlasticSurgery from './plastic_surgery';
import Dental from './dental';
import Psycology from './psycology';
import { Route, Routes,HashRouter as Router } from 'react-router-dom';
function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 999px)'
  })
 var abb=window.location.href;
 
 const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
 const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
 const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
 const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
 const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const component_style={
    visibility:'hidden',
    display:'none'
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
 
  useEffect(() => {
      setIsLoaded(true);
  }, []);
  
  useEffect(() => {//on load
    if (isLoaded) {
   
    }
    }, [isLoaded]);
//alert(abbh+" "+bcd);
 
  return (
    <div className="App">
    {isDesktopOrLaptop && (
     <>
     <Router>
                    <Routes>
                     <Route path='/' element={<Home/>} />
                     <Route path='/ambulance' element={<Ambulance/>} />
                     <Route path='/hospital' element={<Hospital/>} />
                     <Route path='/pathalogy' element={<Pathalogy/>} />
                     <Route path='/pharmacy' element={<Pharmacy/>} />
                     <Route path='/health_bulletin' element={<Bulletin/>} />
                     <Route path='/home_care' element={<Homecare/>} />
                     <Route path='/hospital_jobs' element={<Hospjob/>} />
                     <Route path='/research_dev' element={<Research/>} />
                     <Route path='/hospital_network' element={<Network/>} />
                     <Route path='/latest_fac' element={<Latest/>} />
                     <Route path='/patient_care' element={<Patient/>} />
                     <Route path='/tie_ups' element={<Tie/>} />
                     <Route path='/obstretic' element={<Obstretic/>} />
                     <Route path='/orthopedic' element={<Orthopedic/>} />
                     <Route path='/general_surgery' element={<General_Surgery/>} />
                     <Route path='/pediatric' element={<Pediatric/>} />
                     <Route path='/gastroenterology' element={<Gastroentrology/>} />
                     <Route path='/ent' element={<Ent/>} />
                     <Route path='/neurology' element={<Neurology/>} />
                     <Route path='/oncology' element={<Oncology/>} />
                     <Route path='/cardiology' element={<Cardiology/>} />
                     <Route path='/plastic_surgery' element={<PlasticSurgery/>} />
                     <Route path='/dental' element={<Dental/>} />
                     <Route path='/psycology' element={<Psycology/>} />
                     <Route path='/urology' element={<Urology/>} />
                    </Routes>
                </Router>
      </>
    )}
       {isTabletOrMobile && (
         <>
      
      <Router>
                    <Routes>
                     <Route path='/' element={<Home/>} />
                     <Route path='/ambulance' element={<Ambulance/>} />
                     <Route path='/hospital' element={<Hospital/>} />
                     <Route path='/pathalogy' element={<Pathalogy/>} />
                     <Route path='/pharmacy' element={<Pharmacy/>} />
                     <Route path='/health_bulletin' element={<Bulletin/>} />
                     <Route path='/home_care' element={<Homecare/>} />
                     <Route path='/hospital_jobs' element={<Hospjob/>} />
                     <Route path='/research_dev' element={<Research/>} />
                     <Route path='/hospital_network' element={<Network/>} />
                     <Route path='/latest_fac' element={<Latest/>} />
                     <Route path='/patient_care' element={<Patient/>} />
                     <Route path='/tie_ups' element={<Tie/>} />
                     <Route path='/obstretic' element={<Obstretic/>} />
                     <Route path='/orthopedic' element={<Orthopedic/>} />
                     <Route path='/general_surgery' element={<General_Surgery/>} />
                     <Route path='/pediatric' element={<Pediatric/>} />
                     <Route path='/gastroenterology' element={<Gastroentrology/>} />
                     <Route path='/ent' element={<Ent/>} />
                     <Route path='/neurology' element={<Neurology/>} />
                     <Route path='/oncology' element={<Oncology/>} />
                     <Route path='/cardiology' element={<Cardiology/>} />
                     <Route path='/plastic_surgery' element={<PlasticSurgery/>} />
                     <Route path='/dental' element={<Dental/>} />
                     <Route path='/psycology' element={<Psycology/>} />
                     <Route path='/urology' element={<Urology/>} />
                    </Routes>
                </Router>
      
     
      </>
    )}
     {isMobile && (
        <>
     
     <Router>
                    <Routes>
                     <Route path='/' element={<Home/>} />
                     <Route path='/ambulance' element={<Ambulance/>} />
                     <Route path='/hospital' element={<Hospital/>} />
                     <Route path='/pathalogy' element={<Pathalogy/>} />
                     <Route path='/pharmacy' element={<Pharmacy/>} />
                     <Route path='/health_bulletin' element={<Bulletin/>} />
                     <Route path='/home_care' element={<Homecare/>} />
                     <Route path='/hospital_jobs' element={<Hospjob/>} />
                     <Route path='/research_dev' element={<Research/>} />
                     <Route path='/hospital_network' element={<Network/>} />
                     <Route path='/latest_fac' element={<Latest/>} />
                     <Route path='/patient_care' element={<Patient/>} />
                     <Route path='/tie_ups' element={<Tie/>} />
                     <Route path='/obstretic' element={<Obstretic/>} />
                     <Route path='/orthopedic' element={<Orthopedic/>} />
                     <Route path='/general_surgery' element={<General_Surgery/>} />
                     <Route path='/pediatric' element={<Pediatric/>} />
                     <Route path='/gastroenterology' element={<Gastroentrology/>} />
                     <Route path='/ent' element={<Ent/>} />
                     <Route path='/neurology' element={<Neurology/>} />
                     <Route path='/oncology' element={<Oncology/>} />
                     <Route path='/cardiology' element={<Cardiology/>} />
                     <Route path='/plastic_surgery' element={<PlasticSurgery/>} />
                     <Route path='/dental' element={<Dental/>} />
                     <Route path='/psycology' element={<Psycology/>} />
                     <Route path='/urology' element={<Urology/>} />
                    </Routes>
                </Router>
     
     
      </>
    )}
    </div>
  );
}

export default App;
     
