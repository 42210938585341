import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function General_Surgery() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
            fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="generalsurgery">
          {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital General Surgery<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/abdominal.jfif"}/>Abdominal Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Appendectomy</span>:Removal of the appendix, often due to appendicitis.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Gallbladder surgery (Cholecystectomy)</span>: Removal of the gallbladder, typically for gallstones or gallbladder disease.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Hernia repair</span>: Surgical treatment of hernias, including inguinal, umbilical, and incisional hernias.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Bowel resection</span>: Surgery to remove part of the small or large intestine, often due to cancer, diverticulitis, or Crohn’s disease.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Gastrointestinal surgery</span>: Procedures on the stomach, intestines, esophagus, and other parts of the digestive system, including surgeries for ulcers, tumors, or blockages.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/breast.jfif"}/>Breast Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Lumpectomy</span>: Removal of a breast tumor and some surrounding tissue, often used to treat breast cancer.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Mastectomy</span>: Complete or partial removal of the breast to treat or prevent breast cancer.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/thyroid.jfif"}/>Thyroid and Endocrine Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Thyroidectomy</span>:  Removal of all or part of the thyroid gland, often due to thyroid cancer, hyperthyroidism, or large goiters.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Adrenalectomy</span>: Surgery to remove one or both adrenal glands, usually because of tumors or overproduction of hormones.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/trauma.jfif"}/>Trauma Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/>General surgeons often handle emergency trauma cases, including those related to accidents, blunt force trauma, or penetrating injuries like stab wounds or gunshots.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Exploratory laparotomy</span>: A procedure to open the abdomen and explore for internal injuries after trauma.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/colorectal.jfif"}/>Colorectal Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Hemorrhoidectomy</span>:  Removal of hemorrhoids.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Surgery for colorectal cancer</span>: Removal of cancerous sections of the colon or rectum.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Anal fistula repair</span>:  Treatment of abnormal connections between the anal canal and surrounding skin.</CListGroupItem> 
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/minimal.jfif"}/>Minimally Invasive Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Laparoscopic surgery</span>:  Use of small incisions and specialized tools, often resulting in shorter recovery times. Common laparoscopic surgeries include appendectomies, hernia repairs, and gallbladder removals.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Robotic surgery</span>: Surgeons use robotic systems to perform precise operations through tiny incisions, often used for complex procedures.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/soft.jfif"}/>Skin and Soft Tissue Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Removal of cysts or tumors</span>:  Excision of benign or malignant growths on the skin or in soft tissues.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Wound care</span>: Surgical management of chronic or severe wounds, including debridement or skin grafts.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/batriatic.png"}/>Bariatric Surgery:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Gastric bypass</span>:  Surgical treatment for obesity, where the size of the stomach is reduced and the digestive system is rerouted.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Gastric sleeve</span>: Reducing the stomach size to aid weight loss.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                    </CRow>
                    <CRow>
                       <CCol xs={12} style={{padding:'6%'}}>
                         <CListGroup><h5 style={lml}>Common Procedures:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Emergency surgeries</span>: Often performed on patients in critical condition due to trauma, acute infections, or other urgent conditions.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Elective surgeries</span>: Planned procedures to address chronic issues such as hernias or gallbladder disease.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Cancer surgeries</span>:  Including the removal of tumors from the breast, colon, and other organs.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       </CRow>
                    <CRow>
                       <CCol xs={12} style={{padding:'6%'}}>
                         <CListGroup><h5 style={lml}>Services and Specializations:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Pre-operative care</span>: Assessment and preparation before surgery.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Post-operative care</span>: Monitoring and managing recovery after surgery.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Wound management</span>: Including both minor procedures and complex cases.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Pain management</span>: Administering anesthesia and post-surgical pain control methods.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default General_Surgery;