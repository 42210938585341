import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
function Home() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
          const mbl_hm_hdr={
            boxShadow:'rgba(0, 0, 0, 0.18) 0px 2px 4px',
            padding:'1%',
            background:'linear-gradient(45deg, #FF143C 30%, #FF6666 70%)',
            color:'#F0FFFF',
            fontFamily:'Montserrat',
            height:'2.5em',
            position:'absolute'
          }
          const lptp_hm_hdr={
            boxShadow:'rgba(0, 0, 0, 0.18) 0px 2px 4px',
            padding:'1%',
            background:'linear-gradient(45deg, #FF143C 30%, #FF6666 70%)',
            color:'#F0FFFF',
            fontFamily:'Montserrat',
            height:'auto',
            
          }
          const mbl_mnu={
            position:'relative',
            float:'left',
            fontSize:'1.9em',
            fontWeight:'900',
            color:'#D3D3D3'
          }
          const tbl_mnu={
            position:'relative',
            float:'left',
            fontSize:'1.9em',
            fontWeight:'900',
            color:'#D3D3D3'
          }
          const lptp_mnu={
            position:'relative',
            float:'left',
            fontSize:'2.6em',
            fontWeight:'900',
            color:'#D3D3D3'
          }
          const mbl_mnu_img={
            position:'relative',
            float:'right',
           
          }
          const mn_wndw={
            position:'sticky',
            paddingTop:'11%', 
            height:'35em',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
          }
          const tbl_wndw={
            position:'sticky',
            height:'45em',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
          }
          const lptp_wndw={
            position:'sticky',
            height:'45em',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px'
          }
          const md_mn_wndw={
            padding:'1%',
            height:'14em',
            textAlign:'center',
            paddingTop:'21%',
            paddingBottom:'7%',
            background:'linear-gradient(45deg,#E5D9F2,#FFFBE6,#F5F5F7)',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
          }
          const tbl_mn_wndw={
            padding:'1%',
            height:'45em',
            textAlign:'center',
            paddingTop:'21%',
            paddingBottom:'7%',
            background:'linear-gradient(45deg,#E5D9F2,#FFFBE6,#F5F5F7)',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
          }
          const lptp_mn_wndw={
            padding:'1%',
            height:'45em',
            textAlign:'center',
            paddingTop:'16%',
            paddingBottom:'7%',
            background:'linear-gradient(45deg,#E5D9F2,#FFFBE6,#F5F5F7)',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
          }
          const lptp_lst_vw={
            textAlign:'center',
            fontWeight:'600',
            border:'none',
           width:'60%',
            fontSize:'95%' ,
            textDecoration:'underline',
           cursor:'pointer'

          }
          const tbl_lst_vw={
            textAlign:'center',
            fontWeight:'600',
            border:'none',
            width:'60%',
            fontSize:'0.90em',
            textDecoration:'underline',
           cursor:'pointer'
          }
          const icn={
            fontSize:'3.3em'
          }
          const icn_tbl={
            fontSize:'3.3em'
          }
          const icn_lptp={
            fontSize:'5.3em'
          }
          const icn_btn={
            fontSize:'1.0em',
            fontWeight:'700'
          }
          const icn_btn_tbl={
            fontSize:'0.75em',
            fontWeight:'700'
          }
          const ftr_wndw={
            position:'fixed',
            bottom:'0.1%',
            background:'linear-gradient(45deg, #FF143C 30%, #FF6666 70%)',
            color:'#F0FFFF',
            fontFamily:'Montserrat',
          }
          const ftr_wndw1={
            position:'fixed',
            bottom:'0.1%',
            background:'linear-gradient(45deg, #FF143C 30%, #FF6666 70%)',
            color:'#F0FFFF',
            fontFamily:'Montserrat',
            
          }
          const mbl_sd_vw={
            fontFamily:'Montserrat',
            zIndex:2999,
            position:'absolute',
            float:'left',
            width:'40%',
            marginLeft:'-52%',
            top:'7%',
           height:'32.4em',
            visibility:'hidden',
            display:'none',
            boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px'
          }
          const lml={
            background:'linear-gradient(45deg, #FF143C 30%, #FF6666 70%)',
            color:'#F0FFFF',
            fontFamily:'Montserrat',
            textAlign:'left',
            color:'white',
            fontWeight:'800'
          }
          function mnu_lst(e){
            e.preventDefault();
            if($("#sd_vw").css('visibility')!='visible'){
            document.getElementById("sd_vw").style.visibility='visible';
            document.getElementById("sd_vw").style.display='inline-block';
            }
            else
            {
              document.getElementById("sd_vw").style.visibility='hidden';
              document.getElementById("sd_vw").style.display='none';
            }
            return false;
          }
          function  ambu_cntnr(e){
            e.preventDefault();
           var element = document.getElementById('wd1');
           var delay = 2000;
           var delay2=2100;  // 2 seconds delay
            setTimeout(function() {
              element.style.transform = 'rotateX(45deg)';
              element.style.transition = 'transform 1s'; 
             element.style.transform = 'rotateY(45deg)';
             element.style.transition = 'transform 1s';  // Optional smooth transition
             element.style.transform = 'rotateZ(45deg)';
             element.style.transition = 'transform 1s';
             element.style.transform = 'rotateX(600deg)';
             element.style.transition = 'transform 1s'; 
            element.style.transform = 'rotateY(60deg)';
            element.style.transition = 'transform 1s';  // Optional smooth transition
            element.style.transform = 'rotateZ(60deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateZ(75deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateY(90deg)';
            element.style.transition = 'transform 1s';
            setTimeout(function() {
              window.location.href = 'http://localhost:3000/#/ambulance';
            },delay2);
            }, delay)
           return false;
          }
          function  hsptl_cntnr(e){
            e.preventDefault();
           var element = document.getElementById('wd2');
           var delay = 2000;
           var delay2=2100;  // 2 seconds delay
            setTimeout(function() {
              element.style.transform = 'rotateX(45deg)';
              element.style.transition = 'transform 1s'; 
             element.style.transform = 'rotateY(45deg)';
             element.style.transition = 'transform 1s';  // Optional smooth transition
             element.style.transform = 'rotateZ(45deg)';
             element.style.transition = 'transform 1s';
             element.style.transform = 'rotateX(600deg)';
             element.style.transition = 'transform 1s'; 
            element.style.transform = 'rotateY(60deg)';
            element.style.transition = 'transform 1s';  // Optional smooth transition
            element.style.transform = 'rotateZ(60deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateZ(75deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateY(90deg)';
            element.style.transition = 'transform 1s';
            setTimeout(function() {
              window.location.href = 'http://localhost:3000/#/hospital';
            },delay2);
            }, delay)
           return false;
          }
          function  phrmcy_cntnr(e){
            e.preventDefault();
           var element = document.getElementById('wd3');
           var delay = 2000;
           var delay2=2100;  // 2 seconds delay
            setTimeout(function() {
              element.style.transform = 'rotateX(45deg)';
              element.style.transition = 'transform 1s'; 
             element.style.transform = 'rotateY(45deg)';
             element.style.transition = 'transform 1s';  // Optional smooth transition
             element.style.transform = 'rotateZ(45deg)';
             element.style.transition = 'transform 1s';
             element.style.transform = 'rotateX(600deg)';
             element.style.transition = 'transform 1s'; 
            element.style.transform = 'rotateY(60deg)';
            element.style.transition = 'transform 1s';  // Optional smooth transition
            element.style.transform = 'rotateZ(60deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateZ(75deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateY(90deg)';
            element.style.transition = 'transform 1s';
            setTimeout(function() {
              window.location.href = 'http://localhost:3000/#/pharmacy';
            },delay2);
            }, delay)
           return false;
          }
          function  pthlgy_cntnr(e){
            e.preventDefault();
           var element = document.getElementById('wd4');
           var delay = 2000;
           var delay2=2100;  // 2 seconds delay
            setTimeout(function() {
              element.style.transform = 'rotateX(45deg)';
              element.style.transition = 'transform 1s'; 
             element.style.transform = 'rotateY(45deg)';
             element.style.transition = 'transform 1s';  // Optional smooth transition
             element.style.transform = 'rotateZ(45deg)';
             element.style.transition = 'transform 1s';
             element.style.transform = 'rotateX(600deg)';
             element.style.transition = 'transform 1s'; 
            element.style.transform = 'rotateY(60deg)';
            element.style.transition = 'transform 1s';  // Optional smooth transition
            element.style.transform = 'rotateZ(60deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateZ(75deg)';
            element.style.transition = 'transform 1s';
            element.style.transform = 'rotateY(90deg)';
            element.style.transition = 'transform 1s';
            setTimeout(function() {
              window.location.href = 'http://localhost:3000/#/pathalogy';
            },delay2);
            }, delay)
           return false;
          }
      return (
        <div className="home">
         {isDesktopOrLaptop && (
              <>
                 <CContainer fluid style={lptp_hm_hdr}>
                <span  className="tgsh" style={{fontSize:'1.9em'}}>Lifeline Acute Care</span>
                <CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'}  style={mbl_mnu_img}/>
              </CContainer>
              <CContainer   id="lptp_vw" style={{padding:'0.6%',textAlign:'center'}}>
         <CListGroup layout={`horizontal`}>
           
    <CListGroupItem style={lptp_lst_vw}><MdAddHomeWork/>Jobs</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><MdOutlineLocalOffer/>Offers</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><MdHealthAndSafety/>Insurance</CListGroupItem>
  <CListGroupItem style={lptp_lst_vw}><RiCameraLensFill/>AI Crash Detection Cam</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><MdContactPage/>Contact</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><MdManageAccounts/>Login</CListGroupItem>
    </CListGroup>
    </CContainer>
                  <CContainer fluid style={lptp_wndw}>
                  <CRow>
                    <CCol lg={3} xl={3} xxl={3} id="wd1" style={lptp_mn_wndw}><FaAmbulance  className="md_wndw" style={icn_lptp}/><br/><Link style={{color:'black',textDecoration:'none'}} to="/ambulance"><CButton onClick={ambu_cntnr}  color="primary" variant="outline" style={icn_btn}>Book Ambulance</CButton></Link></CCol>
                    <CCol lg={3} xl={3} xxl={3} id="wd2" style={lptp_mn_wndw}><FaHospitalAlt className="md_wndw" style={icn_lptp}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/hospital"><CButton onClick={hsptl_cntnr}  color="primary" variant="outline" style={icn_btn}>Consultation/Bed</CButton></Link></CCol>
                    <CCol lg={3} xl={3} xxl={3} id="wd3" style={lptp_mn_wndw}><MdLocalPharmacy className="md_wndw" style={icn_lptp}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><CButton onClick={phrmcy_cntnr}  color="primary" variant="outline" style={icn_btn}>Buy Pharmacy</CButton></Link></CCol>
                    <CCol lg={3} xl={3} xxl={3} id="wd4" style={lptp_mn_wndw}><FaVialVirus className="md_wndw" style={icn_lptp}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><CButton onClick={pthlgy_cntnr}  color="primary" variant="outline" style={icn_btn}>Collect Pathology</CButton></Link></CCol>
                  </CRow>
                    </CContainer>
              <CContainer fluid style={ftr_wndw1}>
              <CRow>
              <CCol lg={1} xl={1} xxl={1}><TiFlash className="flsh" style={{fill:'#FFEB55',float:'left',fontSize:'2.2em'}} /></CCol>
              <CCol lg={11} xl={11} xxl={11} style={{textAlign:'center'}}><h4  className="tgsh">Powered By HindCare MedTech</h4></CCol>
               </CRow>
               </CContainer>
                    </>
         )}
           {isTabletOrMobile && (
              <>
              <CContainer fluid style={lptp_hm_hdr}>
                <span  className="tgsh" style={{fontSize:'1.9em'}}>Lifeline Acute Care</span>
                <CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'}  style={mbl_mnu_img}/>
              </CContainer>
              <CContainer   id="lptp_vw" style={{padding:'0.6%'}}>
         <CListGroup layout={`horizontal`}>
           
    <CListGroupItem style={tbl_lst_vw}><MdAddHomeWork/><br/>Jobs</CListGroupItem>
   <CListGroupItem style={tbl_lst_vw}><MdOutlineLocalOffer/><br/>Offers</CListGroupItem>
   <CListGroupItem style={tbl_lst_vw}><MdHealthAndSafety/><br/>Insurance</CListGroupItem>
  <CListGroupItem style={tbl_lst_vw}><RiCameraLensFill/><br/>AI Crash Detection Cam</CListGroupItem>
   <CListGroupItem style={tbl_lst_vw}><MdContactPage/><br/>Contact</CListGroupItem>
   <CListGroupItem style={tbl_lst_vw}><MdManageAccounts/><br/>Login</CListGroupItem>
    </CListGroup>
    </CContainer>
    <CContainer fluid style={tbl_wndw}>
                  <CRow>
                    <CCol sm={3} md={3} lg={3} id="wd1" style={tbl_mn_wndw}><FaAmbulance  className="md_wndw" style={icn_tbl}/><br/><Link style={{color:'black',textDecoration:'none'}} to="/ambulance"><CButton onClick={ambu_cntnr}  color="primary" variant="outline" style={icn_btn_tbl}>Book Ambulance</CButton></Link></CCol>
                    <CCol sm={3} md={3} lg={3} id="wd2" style={tbl_mn_wndw}><FaHospitalAlt className="md_wndw" style={icn_tbl}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/hospital"><CButton onClick={hsptl_cntnr}  color="primary" variant="outline" style={icn_btn_tbl}>Consultation/Bed</CButton></Link></CCol>
                    <CCol sm={3} md={3} lg={3} id="wd3" style={tbl_mn_wndw}><MdLocalPharmacy className="md_wndw" style={icn_tbl}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><CButton onClick={phrmcy_cntnr}  color="primary" variant="outline" style={icn_btn_tbl}>Buy Pharmacy</CButton></Link></CCol>
                    <CCol sm={3} md={3} lg={3} id="wd4" style={tbl_mn_wndw}><FaVialVirus className="md_wndw" style={icn_tbl}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><CButton onClick={pthlgy_cntnr}  color="primary" variant="outline" style={icn_btn_tbl}>Collect Pathology</CButton></Link></CCol>
                  </CRow>
                    </CContainer>
    <CContainer fluid style={ftr_wndw1}>
              <CRow>
              <CCol sm={1} md={1} lg={1}><TiFlash className="flsh" style={{fill:'#FFEB55',float:'left',fontSize:'2.2em'}} /></CCol>
              <CCol sm={11} md={11} lg={11} style={{textAlign:'center'}}><h4  className="tgsh">Powered By HindCare MedTech</h4></CCol>
               </CRow>
               </CContainer>
                          </>
         )} 
          {isMobile && (
              <>
              <CContainer fluid style={mbl_hm_hdr}>
              <RxHamburgerMenu onClick={mnu_lst}  className="mnu_mbl" style={mbl_mnu}/>
                <span  className="tgsh">Lifeline Acute Care</span>
                <CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'40em'} height={'40em'}  style={mbl_mnu_img}/>
              </CContainer>
              <CContainer fluid id="sd_vw" style={mbl_sd_vw}>
              <CListGroup>
  <CListGroupItem style={lml}><MdAddHomeWork/>Find Jobs</CListGroupItem>
  <CListGroupItem style={lml}><MdOutlineLocalOffer/>Offers</CListGroupItem>
  <CListGroupItem style={lml}><MdHealthAndSafety/>View Insurance</CListGroupItem>
  <CListGroupItem style={lml}><RiCameraLensFill/>AI powered Crash Detection Dash Cam</CListGroupItem>
  <CListGroupItem style={lml}><MdContactPage/>Contact Us</CListGroupItem>
  <CListGroupItem style={lml}><MdManageAccounts/>Login</CListGroupItem>
</CListGroup>
              </CContainer>
                <br/>
              <CContainer fluid style={mn_wndw}>
                <CContainer fluid>
                  <CRow>
                    <CCol id="wd1" style={md_mn_wndw}><FaAmbulance  className="md_wndw" style={icn}/><br/><Link style={{color:'black',textDecoration:'none'}} to="/ambulance"><CButton onClick={ambu_cntnr}  color="primary" variant="outline" style={icn_btn}>Book Ambulance</CButton></Link></CCol>
                    <CCol id="wd2" style={md_mn_wndw}><FaHospitalAlt className="md_wndw" style={icn}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/hospital"><CButton onClick={hsptl_cntnr}  color="primary" variant="outline" style={icn_btn}>Consultation/Bed</CButton></Link></CCol>
                  </CRow>
                  <CRow>
                    <CCol id="wd3" style={md_mn_wndw}><MdLocalPharmacy className="md_wndw" style={icn}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/pharmacy"><CButton onClick={phrmcy_cntnr}  color="primary" variant="outline" style={icn_btn}>Buy Pharmacy</CButton></Link></CCol>
                    <CCol id="wd4" style={md_mn_wndw}><FaVialVirus className="md_wndw" style={icn}/><br/><Link  style={{color:'black',textDecoration:'none'}} to="/pathalogy"><CButton onClick={pthlgy_cntnr}  color="primary" variant="outline" style={icn_btn}>Collect Pathology</CButton></Link></CCol>
                  </CRow>
                </CContainer>
              </CContainer>
              <CContainer style={ftr_wndw}><TiFlash className="flsh" style={{fill:'#FFEB55',float:'left',position:'relative',fontSize:'1.2em'}} /><h6  className="tgsh">Powered By HindCare MedTech</h6></CContainer>
              </>
         )} 
    </div>
                     );
}
export default Home;