import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios,SiWorldhealthorganization } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired,FaBowlFood,FaBrain}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function HealthBulletin() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const rb={
            color:'green'
        }
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           }
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
        const cl_lg2={
            zIndex:'3999 !important',
            position:'fixed',
            bottom:'0.0%',  
            left:'47%', 
            width: '50px',
            height: '25px', /* as the half of the width */
            background: 'linear-gradient(90deg, white, white)',
            borderTopLeftRadius:'100px',
            borderTopRightRadius:'100px', 
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm_lptp={
            fontFamily:'Baskerville',
            fontWeight:'700',
            textAlign:'center',
            fontSize:'1.4em'
        }
           const cl_lg3={
            zIndex:'3999 !important',
            position:'fixed',
            bottom:'3.0%',  
            left:'36%', 
            visibility:'hidden',
            display:'none',
            width: '40px',
            height: '25px', /* as the half of the width */
            background: 'linear-gradient(90deg, white, white)',
            borderTopLeftRadius:'30px',
            borderTopRightRadius:'30px', 
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const cl_lg4={
            zIndex:'3999 !important',
            position:'fixed',
            bottom:'3.0%',  
            left:'60%', 
            visibility:'hidden',
            display:'none',
            width: '40px',
            height: '25px', /* as the half of the width */
            background: 'linear-gradient(90deg, white, white)',
            borderTopLeftRadius:'30px',
            borderTopRightRadius:'30px', 
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const cl_lg5={
            zIndex:'3999 !important',
            position:'fixed',
            bottom:'4.8%',  
            left:'48%',
            visibility:'hidden',
            display:'none', 
            width: '40px',
            height: '25px', /* as the half of the width */
            background: 'linear-gradient(90deg, white, white)',
            borderTopLeftRadius:'30px',
            borderTopRightRadius:'30px', 
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const ftr={
            padding:'1%',
            fontSize:'0.69em',
            height:'2em',
            width:'100%',
            boxShadow:'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
            background:'linear-gradient(90deg, #C4D7FF, #87A2FF)',
            color:'black',
            fontWeight:'600'
          }
          const lsd={
            background:'linear-gradient(45deg, #E9EFEC, #E9EFEC)',
          }
          const hsptl_facility={
            padding:'2%',
            height:'auto',
            width:'100%'
          }
          const hsptl_facilityt={
            padding:'2%',
            height:'auto',
            width:'100%',
            fontSize:'1.2em'
          }
          const icn_btn={
            fontSize:'1.0em',
            fontWeight:'700',
            width:'99%'
          }
          const lptp_lst_vw={
            textAlign:'center',
            fontWeight:'600',
            border:'none',
           width:'60%',
            fontSize:'100%' ,
            textDecoration:'underline',
           cursor:'pointer'
    
          }
          const tblt_lst_vw={
            textAlign:'center',
            fontWeight:'600',
            border:'none',
           width:'60%',
            fontSize:'76%' ,
            textDecoration:'underline',
           cursor:'pointer'
          }
          const dwn_mnu={
            textAlign:'left',   
            color:'white',
            fontFamily:'Lucida Sans',
            border:'none',
            background:'linear-gradient(90deg, #001F3F,#001F3F)'
           }
           const ire={
            width:'100%',
            height:'80%',  
           }
           const okl={
            border:'none',
            textAlign:'left',
            background:'linear-gradient(90deg, #257180,#257180)',
            color:'white',
        }
        const okj={
          textAlign:'left',
          background:'linear-gradient(90deg, #FFF8E8,#F7EED3)',
        }
        const zzj={
          fontWeight:'600',
          color:'#343131'
        }
        const lmmh={
          width:'100%',
          height:'100%',
          paddingLeft:'1%',
          paddingTop:'1%',
          boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'
         }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
            var ato=window.innerWidth;
                axios.post('http://localhost/hospital/php/global_health_days.php',
            ).then(res=>
            {
              
                var lgn=res.data;
                if(lgn!=null){
                    var rmn=1
                    var amz=lgn.length;
                   
                      for(var i=0;i<amz;i++){
                          var oda=lgn[i];
                          if(ato<=576){
                             document.getElementById("ot"+rmn).innerText=oda[2]+'\n'+oda[0]+","+oda[1];
                          }
                          if(ato>576 && ato<1000){
                            document.getElementById("ooot"+rmn).innerText=oda[2]+'\n'+oda[0]+","+oda[1];
                          }
                          if(ato>1000){
                             document.getElementById("oot"+rmn).innerText=oda[2]+'\n'+oda[0]+","+oda[1];
                          } 
                             rmn=rmn+2;
                      }
                }
                window.scrollTo(0, 0);
            });
            var afo=$(".imhg");
            var ffo=afo.length;
            for(var t=0;t<ffo;t=t+2){
                 var mg1=$(".imhg").eq(t).height();
                 var mg2=$(".imhg").eq(t+1).height(); 
                 if(mg1<mg2){
                   mg2=mg2-300;
                  $(".imhg").eq(t).css({"height":mg2+"px"});
                  $(".imhg").eq(t+1).css({"height":mg2+"px"});
                 }   
                 else
                 {
                   mg1=mg1-300;
                  $(".imhg").eq(t).css({"height":mg1+"px"});
                  $(".imhg").eq(t+1).css({"height":mg1+"px"});
                 }
            }
            var afo=$(".imhgg");
            var ffo=afo.length;
            for(var t=0;t<ffo;t=t+2){
                 var mg1=$(".imhgg").eq(t).height();
                 var mg2=$(".imhgg").eq(t+1).height(); 
                 if(mg1<mg2){
                   mg2=mg2-300;
                  $(".imhgg").eq(t).css({"height":mg2+"px"});
                  $(".imhgg").eq(t+1).css({"height":mg2+"px"});
                 }   
                 else
                 {
                   mg1=mg1-300;
                  $(".imhgg").eq(t).css({"height":mg1+"px"});
                  $(".imhgg").eq(t+1).css({"height":mg1+"px"});
                 }
            }
            }
          }, [isLoaded]);
          function mdl(e){
            e.preventDefault();
            if($("#rd4").css('visibility')!='visible'){
            document.getElementById("rd4").style.visibility='visible';
            document.getElementById("rd4").style.display="inline-block";
            document.getElementById("rd6").style.visibility='visible';
            document.getElementById("rd6").style.display="inline-block";
            document.getElementById("rd5").style.visibility='visible';
            document.getElementById("rd5").style.display="inline-block";
            }
            else
            {
                document.getElementById("rd4").style.visibility='hidden';
                document.getElementById("rd4").style.display="none";
                document.getElementById("rd6").style.visibility='hidden';
                document.getElementById("rd6").style.display="none";
                document.getElementById("rd5").style.visibility='hidden';
                document.getElementById("rd5").style.display="none";
            }
            return false;
           }
        
          return (
            <div className="health_bulletin">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid style={hsptl_mbl_hdr}>
             <CRow>
                          <CCol xs={12} style={hsptl_nm_lptp}>Lifeline  Multi-Speciality Hospital Health Bulletin<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer   id="lptp_vw" style={{padding:'0.6%',textAlign:'center'}}>
         <CListGroup layout={`horizontal`}>
         <CListGroupItem style={lptp_lst_vw}><FaBowlFood style={{color:'#D91656'}}/>&nbsp;Heathy Eating</CListGroupItem>
    <CListGroupItem style={lptp_lst_vw}><FaBrain style={{color:'#D91656'}}/>&nbsp;Handle Mental Stress</CListGroupItem>
   <CListGroupItem style={lptp_lst_vw}><SiWorldhealthorganization style={{color:'#D91656'}}/>&nbsp;Global Health Days</CListGroupItem>
    </CListGroup>
    </CContainer>
                   <CContainer id="drm10" style={hsptl_facilityt}>
                   <CRow>
                         <CCol lg={12} xl={12} xxl={12}>
                         <h5><MdFoodBank style={{color:'red'}}/> Eat healthy,at home.</h5>
                         </CCol>
                         </CRow>
                         <CContainer fluid style={{padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                      <CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Eat a Variety of Foods</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}><span style={zzj}>Fruits and Vegetables</span>: Aim to fill half your plate with fruits and vegetables. These provide vitamins, minerals, and fiber.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Whole Grains</span>: Choose whole grains like brown rice, oats, and whole wheat bread over refined grains.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Protein</span>: Incorporate lean proteins such as chicken, turkey, fish, beans, and plant-based proteins (like tofu or lentils). Limit red and processed meats.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Healthy Fats</span>: Choose unsaturated fats from sources like olive oil, avocados, nuts, and seeds. Limit trans fats and saturated fats found in processed and fried foods.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Watch Portion Sizes</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Eat moderate portions, and pay attention to hunger and fullness cues to avoid overeating.</CListGroupItem>
    <CListGroupItem style={okj}>Use smaller plates and measure portion sizes to avoid large servings.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Limit Added Sugars and Salt</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Avoid sugary drinks and snacks. Opt for water, herbal teas, or drinks without added sugar.</CListGroupItem>
    <CListGroupItem style={okj}>Limit processed and packaged foods, which often contain added sugars and sodium.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Stay Hydrated</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Drink plenty of water throughout the day. Dehydration can sometimes be mistaken for hunger.</CListGroupItem>
 
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Plan Your Meals</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Prepare balanced meals at home, incorporating a mix of protein, carbohydrates, and healthy fats.</CListGroupItem>
    <CListGroupItem style={okj}>Avoid skipping meals, as this can lead to overeating later on.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Mindful Eating</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Eat slowly, savor your meals, and avoid distractions like TV or phones while eating.</CListGroupItem>
    <CListGroupItem style={okj}>Be aware of emotional or stress-driven eating and develop healthier coping mechanisms.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Limit Processed Foods</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Reduce consumption of heavily processed foods that are high in preservatives, artificial ingredients, and unhealthy fats.</CListGroupItem>
    
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol lg={12} xl={12} xxl={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Consider Nutrient Timing</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Spread meals and snacks evenly throughout the day to keep energy levels stable.</CListGroupItem>
    <CListGroupItem style={okj}>Don’t skip breakfast, and aim for balanced meals to sustain you until the next one.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
                         </CContainer>
                         </CContainer>
                   <CContainer id="drm9" style={hsptl_facilityt}>
                   <CRow>
                         <CCol lg={12} xl={12} xxl={12}>
                         <h5><RiMentalHealthLine style={{color:'red'}}/> How to handle mental  stress?</h5>
                         </CCol>
                         </CRow>
                         <CContainer fluid style={{padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                         <CRow>
                         <CCol lg={12} xl={12} xxl={12} style={{textAlign:'left'}}>
                         <h5><SiReasonstudios style={{color:'red'}}/>Cause</h5>
                         </CCol>
                         </CRow> 
                         <CRow>
                           <CCol lg={12} xl={12} xxl={12}>
                           <CListGroup>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Work-Related Stress
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize and Organize Tasks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Clear Boundaries</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Breaks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Manage Time Effectively</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Relaxation Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop Healthy Coping Mechanisms</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Social Support</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Learn to Manage Expectations</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Needed</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Time Off When Necessary</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Financial Concerns
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Budget</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize Debt Repayment</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Build an Emergency Fund</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Cut Unnecessary Expenses</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Increase Your Income</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Financial Plan</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Use Financial Management Tools</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Financial Assistance Programs</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Mindfulness and Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Plan for the Future</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Support</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Relationship Issues
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Identify the Problem</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Communicate Openly and Honestly</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Stay Calm and Avoid Arguments</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Compromise and Solutions</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Work on Trust and Respect</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Responsibility</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Give Space if Needed</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Consider Counseling</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Health Problems
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care (for minor issues)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Immediate Action (if needed)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Consult a Doctor</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care (for minor issues)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Follow the Doctor’s Advice</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Preventive Measures</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Monitor Your Condition</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Life Transitions
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Acknowledge the Change</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reflect and Assess</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Plan</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop Support Systems</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Follow the Doctor’s Advice</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Embrace Growth and Learning</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reevaluate and Adjust</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Necessary</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Environmental Factors
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reduce, Reuse, Recycle</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Energy Efficiency</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Conserve Water</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Air Quality Improvement</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Sustainable Food Choices</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Transportation and Travel</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Land and Habitat Preservation</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Sustainable Living Practices</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Get Involved in Environmental Advocacy</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Educate Yourself and Others</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Internal Pressures
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Identify the Source</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Realistic Expectations</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Self-Compassion</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop a Positive Mindset</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Establish Healthy Boundaries</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Mindfulness and Relaxation Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Balance Academics with Other Activities</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Supportive Environment</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Focus on What You Can Control</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Necessary</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Gratitude</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Limit Comparisons</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Academic Pressure
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Time Management</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Realistic Goals</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Effective Study Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Breaks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Support</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Balance Academics with Other Activities</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Mindset Shift</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Handle Exam Stress</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Long-Term Perspective</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                           </CListGroup>
                           </CCol>
                         </CRow>
                         </CContainer>
                  </CContainer>
                  <CContainer id="drm8" style={hsptl_facility}>
                   <CRow>
                         <CCol  lg={12} xl={12} xxl={12}>
                         <h5><FaGlobe style={{color:'red'}}/> Global Health Days</h5>
                         </CCol>
                         </CRow>
                     <CRow>
                         <CCol  lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/tropical_disease.jpg'}/>
  <CCardBody  style={lsd}>
    <CCardTitle id="oot1"></CCardTitle>
    <CCardText id="oot2">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>

                         </CCol>
                         
                         <CCol  lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/tb.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot3"></CCardTitle>
    <CCardText id="oot4">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                      
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/health_day.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot5"></CCardTitle>
    <CCardText id="oot6">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/chagas.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot7"></CCardTitle>
    <CCardText id="oot8">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/malaria.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot9"></CCardTitle>
    <CCardText id="oot10">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/immunization.png'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot11"></CCardTitle>
    <CCardText id="oot12">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/notobacco.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot13"></CCardTitle>
    <CCardText id="oot14">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/blood_donation.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot15"></CCardTitle>
    <CCardText id="oot16">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/drowning.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot17"></CCardTitle>
    <CCardText id="oot18">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/hepatitis.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot19"></CCardTitle>
    <CCardText id="oot20">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/OIP.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot21"></CCardTitle>
    <CCardText id="oot22">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/blood_sugar.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot23"></CCardTitle>
    <CCardText id="oot24">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/ams.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot25"></CCardTitle>
    <CCardText id="oot26">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol lg={6} xl={6} xxl={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/aids.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="oot27"></CCardTitle>
    <CCardText id="oot28">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                     </CRow>
                   </CContainer>
                  <Footer/>
                   </>
               )}
               {isTabletOrMobile && (
                    <>
                      <CContainer fluid style={hsptl_mbl_hdr}>
             <CRow>
                          <CCol xs={12} style={hsptl_nm_lptp}>Lifeline  Multi-Speciality Hospital Health Bulletin<CImage  className="lgo_img"  fluid rounded src="http://localhost/Hospital/image/logo.jpg" width={'60em'} height={'60em'} style={{float:'right'}}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer   id="lptp_vw" style={{padding:'0.6%',textAlign:'center'}}>
         <CListGroup layout={`horizontal`}>
         <CListGroupItem style={tblt_lst_vw}><FaBowlFood style={{color:'#D91656'}}/>&nbsp;Heathy Eating</CListGroupItem>
    <CListGroupItem style={tblt_lst_vw}><FaBrain style={{color:'#D91656'}}/>&nbsp;Handle Mental Stress</CListGroupItem>
   <CListGroupItem style={tblt_lst_vw}><SiWorldhealthorganization style={{color:'#D91656'}}/>&nbsp;Global Health Days</CListGroupItem>
    </CListGroup>
    </CContainer>
                   <CContainer id="drm10" style={hsptl_facilityt}>
                   <CRow>
                         <CCol sm={12} md={12} lg={12}>
                         <h5><MdFoodBank style={{color:'red'}}/> Eat healthy,at home.</h5>
                         </CCol>
                         </CRow>
                         <CContainer fluid style={{padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                      <CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Eat a Variety of Foods</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}><span style={zzj}>Fruits and Vegetables</span>: Aim to fill half your plate with fruits and vegetables. These provide vitamins, minerals, and fiber.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Whole Grains</span>: Choose whole grains like brown rice, oats, and whole wheat bread over refined grains.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Protein</span>: Incorporate lean proteins such as chicken, turkey, fish, beans, and plant-based proteins (like tofu or lentils). Limit red and processed meats.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Healthy Fats</span>: Choose unsaturated fats from sources like olive oil, avocados, nuts, and seeds. Limit trans fats and saturated fats found in processed and fried foods.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Watch Portion Sizes</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Eat moderate portions, and pay attention to hunger and fullness cues to avoid overeating.</CListGroupItem>
    <CListGroupItem style={okj}>Use smaller plates and measure portion sizes to avoid large servings.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Limit Added Sugars and Salt</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Avoid sugary drinks and snacks. Opt for water, herbal teas, or drinks without added sugar.</CListGroupItem>
    <CListGroupItem style={okj}>Limit processed and packaged foods, which often contain added sugars and sodium.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Stay Hydrated</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Drink plenty of water throughout the day. Dehydration can sometimes be mistaken for hunger.</CListGroupItem>
 
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Plan Your Meals</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Prepare balanced meals at home, incorporating a mix of protein, carbohydrates, and healthy fats.</CListGroupItem>
    <CListGroupItem style={okj}>Avoid skipping meals, as this can lead to overeating later on.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Mindful Eating</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Eat slowly, savor your meals, and avoid distractions like TV or phones while eating.</CListGroupItem>
    <CListGroupItem style={okj}>Be aware of emotional or stress-driven eating and develop healthier coping mechanisms.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Limit Processed Foods</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Reduce consumption of heavily processed foods that are high in preservatives, artificial ingredients, and unhealthy fats.</CListGroupItem>
    
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<br/>
<CRow>
                        <CCol sm={12} md={12} lg={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Consider Nutrient Timing</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Spread meals and snacks evenly throughout the day to keep energy levels stable.</CListGroupItem>
    <CListGroupItem style={okj}>Don’t skip breakfast, and aim for balanced meals to sustain you until the next one.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
                         </CContainer>
                         </CContainer>
                   <CContainer id="drm9" style={hsptl_facilityt}>
                   <CRow>
                         <CCol sm={12} md={12} lg={12}>
                         <h5><RiMentalHealthLine style={{color:'red'}}/> How to handle mental  stress?</h5>
                         </CCol>
                         </CRow>
                         <CContainer fluid style={{padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                         <CRow>
                         <CCol sm={12} md={12} lg={12} style={{textAlign:'left'}}>
                         <h5><SiReasonstudios style={{color:'red'}}/>Cause</h5>
                         </CCol>
                         </CRow> 
                         <CRow>
                           <CCol sm={12} md={12} lg={12}>
                           <CListGroup>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Work-Related Stress
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize and Organize Tasks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Clear Boundaries</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Breaks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Manage Time Effectively</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Relaxation Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop Healthy Coping Mechanisms</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Social Support</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Learn to Manage Expectations</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Needed</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Time Off When Necessary</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Financial Concerns
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Budget</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize Debt Repayment</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Build an Emergency Fund</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Cut Unnecessary Expenses</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Increase Your Income</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Financial Plan</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Use Financial Management Tools</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Financial Assistance Programs</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Mindfulness and Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Plan for the Future</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Support</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Relationship Issues
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Identify the Problem</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Communicate Openly and Honestly</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Stay Calm and Avoid Arguments</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Compromise and Solutions</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Work on Trust and Respect</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Responsibility</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Give Space if Needed</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Consider Counseling</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Health Problems
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care (for minor issues)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Immediate Action (if needed)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Consult a Doctor</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care (for minor issues)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Follow the Doctor’s Advice</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Preventive Measures</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Monitor Your Condition</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Life Transitions
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Acknowledge the Change</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reflect and Assess</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Plan</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop Support Systems</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Follow the Doctor’s Advice</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Embrace Growth and Learning</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reevaluate and Adjust</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Necessary</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Environmental Factors
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reduce, Reuse, Recycle</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Energy Efficiency</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Conserve Water</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Air Quality Improvement</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Sustainable Food Choices</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Transportation and Travel</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Land and Habitat Preservation</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Sustainable Living Practices</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Get Involved in Environmental Advocacy</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Educate Yourself and Others</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Internal Pressures
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Identify the Source</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Realistic Expectations</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Self-Compassion</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop a Positive Mindset</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Establish Healthy Boundaries</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Mindfulness and Relaxation Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Balance Academics with Other Activities</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Supportive Environment</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Focus on What You Can Control</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Necessary</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Gratitude</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Limit Comparisons</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Academic Pressure
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Time Management</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Realistic Goals</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Effective Study Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Breaks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Support</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Balance Academics with Other Activities</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Mindset Shift</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Handle Exam Stress</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Long-Term Perspective</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <br/>
                           </CListGroup>
                           </CCol>
                         </CRow>
                         </CContainer>
                  </CContainer>
                  <CContainer id="drm8" style={hsptl_facility}>
                   <CRow>
                         <CCol  sm={12} md={12} lg={12}>
                         <h5><FaGlobe style={{color:'red'}}/> Global Health Days</h5>
                         </CCol>
                         </CRow>
                     <CRow>
                         <CCol  sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/tropical_disease.jpg'}/>
  <CCardBody  style={lsd}>
    <CCardTitle id="ooot1"></CCardTitle>
    <CCardText id="ooot2">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>

                         </CCol>
                         
                         <CCol  sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/tb.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot3"></CCardTitle>
    <CCardText id="ooot4">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                      
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/health_day.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot5"></CCardTitle>
    <CCardText id="ooot6">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/chagas.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot7"></CCardTitle>
    <CCardText id="ooot8">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/malaria.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot9"></CCardTitle>
    <CCardText id="ooot10">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/immunization.png'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot11"></CCardTitle>
    <CCardText id="ooot12">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg"  style={lmmh} orientation="top" src={'http://localhost/hospital/image/notobacco.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot13"></CCardTitle>
    <CCardText id="ooot14">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/blood_donation.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot15"></CCardTitle>
    <CCardText id="ooot16">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/drowning.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot17"></CCardTitle>
    <CCardText id="ooot18">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/hepatitis.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot19"></CCardTitle>
    <CCardText id="ooot20">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/OIP.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot21"></CCardTitle>
    <CCardText id="ooot22">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/blood_sugar.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot23"></CCardTitle>
    <CCardText id="ooot24">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/ams.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot25"></CCardTitle>
    <CCardText id="ooot26">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol sm={6} md={6} lg={6}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage className="imhgg" style={lmmh} orientation="top" src={'http://localhost/hospital/image/aids.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ooot27"></CCardTitle>
    <CCardText id="ooot28">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                     </CRow>
                   </CContainer>
                  <Footer/>  
                   </>
              )}
              {isMobile && (
                   <>
                   <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Health Bulletin<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid id="drm10" style={hsptl_facility}>
                   <CRow>
                         <CCol xs={12}>
                         <h5><MdFoodBank style={{color:'red'}}/> Eat healthy,at home.</h5>
                         </CCol>
                         </CRow>
                         <CContainer fluid style={{padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                      <CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Eat a Variety of Foods</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}><span style={zzj}>Fruits and Vegetables</span>: Aim to fill half your plate with fruits and vegetables. These provide vitamins, minerals, and fiber.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Whole Grains</span>: Choose whole grains like brown rice, oats, and whole wheat bread over refined grains.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Protein</span>: Incorporate lean proteins such as chicken, turkey, fish, beans, and plant-based proteins (like tofu or lentils). Limit red and processed meats.</CListGroupItem>
    <CListGroupItem style={okj}><span style={zzj}>Healthy Fats</span>: Choose unsaturated fats from sources like olive oil, avocados, nuts, and seeds. Limit trans fats and saturated fats found in processed and fried foods.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Watch Portion Sizes</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Eat moderate portions, and pay attention to hunger and fullness cues to avoid overeating.</CListGroupItem>
    <CListGroupItem style={okj}>Use smaller plates and measure portion sizes to avoid large servings.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Limit Added Sugars and Salt</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Avoid sugary drinks and snacks. Opt for water, herbal teas, or drinks without added sugar.</CListGroupItem>
    <CListGroupItem style={okj}>Limit processed and packaged foods, which often contain added sugars and sodium.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Stay Hydrated</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Drink plenty of water throughout the day. Dehydration can sometimes be mistaken for hunger.</CListGroupItem>
 
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Plan Your Meals</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Prepare balanced meals at home, incorporating a mix of protein, carbohydrates, and healthy fats.</CListGroupItem>
    <CListGroupItem style={okj}>Avoid skipping meals, as this can lead to overeating later on.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Mindful Eating</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Eat slowly, savor your meals, and avoid distractions like TV or phones while eating.</CListGroupItem>
    <CListGroupItem style={okj}>Be aware of emotional or stress-driven eating and develop healthier coping mechanisms.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Limit Processed Foods</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Reduce consumption of heavily processed foods that are high in preservatives, artificial ingredients, and unhealthy fats.</CListGroupItem>
    
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
<CRow>
                        <CCol xs={12}>
                         <CCard style={{ width: '98%',background:'linear-gradient(30deg,#C1D8C3,#D7C3F1)' }}>
  <CCardImage orientation="top" />
  <CCardBody>
    <CCardTitle>Consider Nutrient Timing</CCardTitle>
    <CCardText>
    <CListGroup>
    <CListGroupItem style={okj}>Spread meals and snacks evenly throughout the day to keep energy levels stable.</CListGroupItem>
    <CListGroupItem style={okj}>Don’t skip breakfast, and aim for balanced meals to sustain you until the next one.</CListGroupItem>
</CListGroup>
    </CCardText>
  
  </CCardBody>
</CCard>
</CCol>
</CRow>
                         </CContainer>
                         </CContainer>
                   <CContainer fluid id="drm9" style={hsptl_facility}>
                   <CRow>
                         <CCol xs={12}>
                         <h5><RiMentalHealthLine style={{color:'red'}}/> How to handle mental  stress?</h5>
                         </CCol>
                         </CRow>
                         <CContainer fluid style={{padding:'1%',boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
                         <CRow>
                         <CCol xs={12} style={{textAlign:'left'}}>
                         <h5><SiReasonstudios style={{color:'red'}}/>Cause</h5>
                         </CCol>
                         </CRow> 
                         <CRow>
                           <CCol xs={12}>
                           <CListGroup>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Work-Related Stress
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize and Organize Tasks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Clear Boundaries</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Breaks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Manage Time Effectively</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Relaxation Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop Healthy Coping Mechanisms</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Social Support</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Learn to Manage Expectations</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Needed</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Time Off When Necessary</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Financial Concerns
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Budget</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize Debt Repayment</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Build an Emergency Fund</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Cut Unnecessary Expenses</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Increase Your Income</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Financial Plan</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Use Financial Management Tools</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Financial Assistance Programs</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Mindfulness and Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Plan for the Future</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Support</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Relationship Issues
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Identify the Problem</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Communicate Openly and Honestly</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Stay Calm and Avoid Arguments</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Compromise and Solutions</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Work on Trust and Respect</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Responsibility</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Give Space if Needed</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Consider Counseling</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Health Problems
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care (for minor issues)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Immediate Action (if needed)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Consult a Doctor</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care (for minor issues)</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Follow the Doctor’s Advice</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Preventive Measures</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Monitor Your Condition</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Life Transitions
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Acknowledge the Change</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reflect and Assess</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Plan</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop Support Systems</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Follow the Doctor’s Advice</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Embrace Growth and Learning</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reevaluate and Adjust</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Necessary</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Environmental Factors
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Reduce, Reuse, Recycle</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Energy Efficiency</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Conserve Water</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Air Quality Improvement</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Sustainable Food Choices</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Transportation and Travel</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Land and Habitat Preservation</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Sustainable Living Practices</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Get Involved in Environmental Advocacy</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Educate Yourself and Others</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Internal Pressures
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Identify the Source</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Realistic Expectations</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Self-Compassion</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Develop a Positive Mindset</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Establish Healthy Boundaries</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Mindfulness and Relaxation Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Balance Academics with Other Activities</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Create a Supportive Environment</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Focus on What You Can Control</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Prioritize Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Professional Help if Necessary</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Practice Gratitude</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Limit Comparisons</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                            <CListGroupItem style={okl}><IoIosArrowDroprightCircle/>Academic Pressure
                            <CListGroup>
                            <CListGroupItem style={{textAlign:'center',background:'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',fontWeight:'700'}}><GiHealthPotion style={{color:'red',fontSize:'1.8em'}}/>How to manage?</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Time Management</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Set Realistic Goals</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Effective Study Techniques</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Self-Care</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Take Breaks</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Seek Support</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Balance Academics with Other Activities</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Mindset Shift</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Handle Exam Stress</CListGroupItem>
                            <CListGroupItem><FaStarOfLife/>Long-Term Perspective</CListGroupItem>
                            </CListGroup>
                            </CListGroupItem>
                           </CListGroup>
                           </CCol>
                         </CRow>
                         </CContainer>
                  </CContainer>
                   <CContainer fluid id="drm8" style={hsptl_facility}>
                   <CRow>
                         <CCol xs={12}>
                         <h5><FaGlobe style={{color:'red'}}/> Global Health Days</h5>
                         </CCol>
                         </CRow>
                     <CRow>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/tropical_disease.jpg'}/>
  <CCardBody  style={lsd}>
    <CCardTitle id="ot1"></CCardTitle>
    <CCardText id="ot2">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>

                         </CCol>
                         
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/tb.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot3"></CCardTitle>
    <CCardText id="ot4">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                      
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/health_day.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot5"></CCardTitle>
    <CCardText id="ot6">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/chagas.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot7"></CCardTitle>
    <CCardText id="ot8">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/malaria.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot9"></CCardTitle>
    <CCardText id="ot10">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/immunization.png'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot11"></CCardTitle>
    <CCardText id="ot12">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/notobacco.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot13"></CCardTitle>
    <CCardText id="ot14">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/blood_donation.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot15"></CCardTitle>
    <CCardText id="ot16">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/drowning.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot17"></CCardTitle>
    <CCardText id="ot18">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/hepatitis.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot19"></CCardTitle>
    <CCardText id="ot20">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/OIP.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot21"></CCardTitle>
    <CCardText id="ot22">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/blood_sugar.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot23"></CCardTitle>
    <CCardText id="ot24">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/ams.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot25"></CCardTitle>
    <CCardText id="ot26">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                         <CCol xs={12}>
                         <CCard style={{boxShadow:' rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/aids.jfif'}/>
  <CCardBody style={lsd}>
    <CCardTitle id="ot27"></CCardTitle>
    <CCardText id="ot28">
     
    </CCardText>
  
  </CCardBody>
</CCard><br/><br/>
                         </CCol>
                     </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default HealthBulletin;