import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance,TbArrowBadgeRightFilled } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Neurology() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
               fontWeight:'600',
               color:'green',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const jwl={
            border:'none',
            fontWeight:'500'
        }
        const vml={
            color:'red'
        }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="neurology">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Neurology<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                         <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neuorology.png"}/>Neurology</h5>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neurologist.jfif"}/>Neurologist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Our Medical doctors specialize in diagnosing and treating neurological disorders.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Diagnose and manage disorders like stroke, epilepsy, Parkinson’s disease, Alzheimer’s, multiple sclerosis, migraines, and more.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Perform neurological exams to assess motor and sensory skills, balance, coordination, and mental status.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Order diagnostic tests like MRIs, CT scans, EEGs, and lumbar punctures.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Develop treatment plans including medications, therapies, and surgery referrals.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neurosurgeon.jfif"}/>Neurosurgeon
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:We have experienced team of Surgeons who specialize in performing surgeries on the nervous system, including the brain and spinal cord.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Perform surgeries to treat conditions like brain tumors, spinal cord injuries, aneurysms, and epilepsy.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Work closely with neurologists to assess cases where surgical intervention is required.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Provide post-operative care and follow-up treatment.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neuro44.jfif"}/>Neurophysiologist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Specializes in the study and testing of electrical functions in the nervous system.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Conduct and interpret tests like electroencephalograms (EEGs) and electromyography (EMG) to assess electrical activity in the brain and nerves.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Help diagnose conditions like epilepsy, neuromuscular disorders, and sleep disorders.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/pa.jfif"}/>Nurse Practitioner (NP) / Physician Assistant (PA)
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Advanced practice providers who assist neurologists in patient care.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Perform initial patient assessments, take medical histories, and perform physical exams.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Order and interpret diagnostic tests under the supervision of neurologists.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Provide treatment, prescribe medications, and educate patients on managing neurological conditions.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/nurse.jfif"}/>Neurology Nurse
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Registered nurses (RNs) specializing in caring for patients with neurological disorders.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Provide direct patient care, including administering medications and monitoring neurological status.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Assist in performing diagnostic tests and procedures.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Educate patients and families on treatment plans, rehabilitation, and lifestyle changes for neurological conditions.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/psycology.png"}/>Neuropsychologist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Psychologists specializing in understanding how neurological disorders affect behavior and cognitive function.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Conduct cognitive tests to assess memory, attention, language, and executive functioning.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Diagnose conditions like dementia, brain injuries, and learning disabilities.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Provide cognitive rehabilitation and develop strategies to improve quality of life.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/radiology.jfif"}/>Neuroradiologist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Radiologists specializing in interpreting imaging of the brain, spinal cord, and nervous system.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Analyze MRI, CT, and PET scan images to diagnose neurological conditions.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Collaborate with neurologists and neurosurgeons to determine the best course of treatment based on imaging findings.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/oncology.png"}/>Neuro-oncologist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:A neurologist or oncologist who specializes in diagnosing and treating cancers of the brain and nervous system.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Diagnose and manage brain tumors, spinal cord cancers, and neurological complications from cancer treatments.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Coordinate care with neurosurgeons, radiologists, and radiation oncologists.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Provide chemotherapy, radiation therapy, and other treatments specific to neuro-oncology.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/rehab.jfif"}/>Rehabilitation Specialist / Physiotherapist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Healthcare professionals who assist in the rehabilitation of patients with neurological injuries or diseases.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Help patients regain motor function, strength, and mobility through physical therapy.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Provide post-stroke rehabilitation, work with spinal cord injury patients, and offer therapies for movement disorders.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Design personalized rehabilitation programs to improve the patient’s quality of life.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/coordinator.jfif"}/>Clinical Neurology Coordinator
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Administrative professionals who organize and coordinate the activities within the neurology department.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Schedule patient appointments, procedures, and follow-ups.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Maintain communication between neurologists, patients, and other departments.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Ensure that the department complies with healthcare regulations and standards.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>   
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/Neurovascular.jfif"}/>Neurovascular Technologist
                         <CListGroup>
                         <CListGroupItem style={jwl}><span style={lml}>Role</span>:Technologists who assist in the imaging and diagnosis of neurovascular conditions.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lml}>Responsibilities</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Operate ultrasound, Doppler, and other imaging equipment to assess blood flow and detect conditions like aneurysms or clots.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Assist in interventional procedures and surgeries involving neurovascular issues.</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         </CListGroup>
                         </CListGroupItem>
                       </CListGroup>
                       </CCol>
                   </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Neurology;