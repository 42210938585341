import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaChevronCircleRight,FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Dental() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
                fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const ado={
            fontSize:'1.3em', 
           } 
           const mkm={
          color:'black',
          fontWeigt:'600',
          fontSize:'1.1em',
           }
           const mkm1={
           color:'black',
           fontSize:'1.6em',
           }
           const ghj={
            backgroundPosition:'center',
            width:'100%',
            height:'300px',
            boxShadow:'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
           }
           const dgg={
             fontWeight:'600'
           }
           const hji={
            backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',
             padding:'1%',
             boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
           }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="dental">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Dental<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={hji}>
      <CRow>
        <CCol sm={12} md={12} lg={12} style={{textAlign:'center'}}>
        <CBadge style={mkm1}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/dental.png"}/>Procedure</CBadge>
        </CCol>
          </CRow>
          <CRow>
            <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/scaling.jfif'} src={'http://localhost/hospital/image/rct.jfif'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>RCT</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Treat infection at the center of a tooth
   <br/>
   <FaChevronCircleRight/> Preserve the tooth without pain and infection
   <br/>
   <FaChevronCircleRight/> Comfortable chewing and maintaining normal biting force and sensation.
    <br/>
     <FaChevronCircleRight/> Protection of other teeth from excessive wear or strain
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/scaling.jfif'} src={'http://localhost/hospital/image/scaling.jfif'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Scaling</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Clear plaque and tartar (also known as calculus) that has accumulated on the teeth
   <br/>
   <FaChevronCircleRight/> To help maintain healthy gums and teeth
   <br/>
   <FaChevronCircleRight/> Prevent gum diseases such as gingivitis and periodontitis.
    <br/>
     <FaChevronCircleRight/> Safe and non-invasive procedure with minimal risks.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/dentalimplant.jfif'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dental Implants</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Popular and effective solution for replacing missing teeth
   <br/>
   <FaChevronCircleRight/> Regains the ability to eat virtually anything and can smile with confidence.
   <br/>
   <FaChevronCircleRight/> Improved Speech and comfort.
    <br/>
     <FaChevronCircleRight/>  Implants are very convenient and durable thus will last many years,
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/dentalcrown.jfif'}  style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Crown & Bridge</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Restorative treatments used to repair the appearance and function of your teeth
   <br/>
   <FaChevronCircleRight/> Help maintain dental functionality and improve the overall aesthetics of your smile
   <br/>
   <FaChevronCircleRight/> Bridges span the space where the teeth are missing.
    <br/>
     <FaChevronCircleRight/> Porcelain or ceramic crowns can be matched to the color of your natural teeth.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/dentures.jfif'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dentures</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Replacements for missing teeth and surrounding tissues
   <br/>
   <FaChevronCircleRight/> Enhance your smile and facial appearance
   <br/>
   <FaChevronCircleRight/> Improved Chewing and Speech.
    <br/>
     <FaChevronCircleRight/> Support the structure of the face, preventing the cheeks and lips from sagging.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/tooth_extract.jpg'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Tooth Extraction</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Remove teeth damaged beyond repair due to decay, disease, or trauma
   <br/>
   <FaChevronCircleRight/> Help alleviate the issue of crowdin
   <br/>
   <FaChevronCircleRight/> Gum disease can cause the teeth to loosen, thus affected teeth need to be extracted.
    <br/>
     <FaChevronCircleRight/> Both simple and surgical extraction done.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/cosmetic_gum.jfif'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Cosmetic & Gum Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Teeth Whitening to make them lighter and remove discoloration and stains.
   <br/>
   <FaChevronCircleRight/> Deep-cleaning, non-surgical procedure, used to treat gum disease
   <br/>
   <FaChevronCircleRight/> Remove black spots or patches on the gums caused by excessive melanin
    <br/>
     <FaChevronCircleRight/> Reshape the gums and improve the appearance of the smile.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" src={'http://localhost/hospital/image/orthodic_treatment.jfif'} style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Orthodontic Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Improve irregularities of the teeth and jaw.
   <br/>
   <FaChevronCircleRight/>Take care of Crooked teeth, Overcrowded teeth, Overbites, Underbites and Crossbites
   <br/>
   <FaChevronCircleRight/>  Improves the appearance of your smile.
    <br/>
     <FaChevronCircleRight/> Enhances your oral health and function.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
          </CRow>
      </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Dental;