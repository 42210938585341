import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import { CgEditBlackPoint } from "react-icons/cg";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Ent() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const vml={
               color:'red'
           }
           const jwl={
               border:'none'
           }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="ent">
       {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital ENT(Ear Nose Throat)<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   <CRow>
                       <CCol xs={12}>
                       <CListGroup><h5 style={lml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/ent.png"}/><span style={lml}>Areas of Focus</span><span style={{color:'black'}}> in the ENT Department</span>:-</h5>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/ear.png"}/><span style={lml}>Ear (Otology)</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Hearing loss</CListGroupItem> 
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Ear infections (otitis)</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Balance disorders (vertigo)</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Tinnitus (ringing in the ears)</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Ear trauma or deformities</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem> 
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/nose.png"}/><span style={lml}>Nose (Rhinology)</span>
                         <CListGroup>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Sinus infections (sinusitis)</CListGroupItem> 
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Allergies</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Nasal obstructions or deformities</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Nosebleeds</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Smell disorders</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/throat.png"}/><span style={lml}>Throat (Laryngology)</span>
                         <CListGroup>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Sore throat and tonsillitis</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Voice and swallowing disorders</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Throat infections</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Hoarseness</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Throat cancers</CListGroupItem>
                         </CListGroup>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/neck.jfif"}/><span style={lml}>Head and Neck</span>
                         <CListGroup>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Thyroid and parathyroid disorders</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Tumors or cancers of the head and neck region</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Facial trauma or deformities</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/> Salivary gland issues</CListGroupItem>
                        </CListGroup>>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/sleep.jfif"}/><span style={lml}>Sleep Medicine</span>
                         <CListGroup>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Sleep apnea</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Snoring</CListGroupItem>
                         <CListGroupItem style={jwl}><CgEditBlackPoint style={vml}/>Breathing difficulties during sleep</CListGroupItem>
                         </CListGroup>
                          </CListGroupItem>
                         </CListGroup>
                       </CCol>
                   </CRow>
                   <CRow>
                       <CCol xs={12} style={{padding:'6%'}}>
                         <CListGroup><h5 style={lml}><span style={lml}>Common Procedures</span><span style={{color:'black'}}> in the ENT Department</span>:-</h5>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Tonsillectomy</span>: Removal of the tonsils.</CListGroupItem> 
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Adenoidectomy</span>: Removal of the adenoids.</CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Septoplasty</span>: Correction of a deviated nasal septum. </CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Sinus Surgery</span>: For chronic sinusitis or nasal polyps. </CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Myringotomy</span>: Ear tube insertion for chronic ear infections. </CListGroupItem>
                         <CListGroupItem style={kml}><IoIosArrowDroprightCircle/><span style={lml}>Thyroidectomy</span>: Surgical removal of the thyroid gland.</CListGroupItem>
                         </CListGroup>
                       </CCol>
                       </CRow>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Ent;