import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import MenuIcon from '@mui/icons-material/Menu';
import { CCardTitle,CCardImage,CCard,CCardHeader,CCardBody,CCardText,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaStarOfLife,FaHome,FaGlobe,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaVialVirus,FaBedPulse,FaUserDoctor,FaHospitalUser } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdFoodBank,MdOutlineMail,MdOutlineRssFeed,MdOutlineAddTask,MdMedicalServices,MdOutlineWallpaper,MdDoneOutline,MdManageAccounts,MdContactPage,MdHealthAndSafety,MdOutlineLocalOffer,MdAddHomeWork,MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { ImLab } from "react-icons/im";
import { TbAmbulance,TbArrowBadgeRightFilled } from "react-icons/tb";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { bounce } from 'react-animations';
import $ from "jquery";
import { TiFlash } from "react-icons/ti";
import { RiCameraLensFill,RiMentalHealthLine } from "react-icons/ri";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { SiRoamresearch,SiReasonstudios } from "react-icons/si";
import { BsFileMedical } from "react-icons/bs";
import { FaNetworkWired}  from "react-icons/fa6";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Footer from './footer.js';
function Cardiology() {
   
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
            const [isLoaded, setIsLoaded] = useState(false);
        const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
        const hsptl_mbl_hdr={
            background: 'linear-gradient(90deg, #87A2FF, #C4D7FF,#15B392)',   
            boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px 0px',
           }
           const hsptl_nm={
               fontFamily:'Baskerville',
               fontWeight:'700',
               textAlign:'center',
               fontSize:'1.0em'
           } 
           const mnu_icn={
            fontSize:'1.8em',
            color:'#FFD700',
            float:'right',
            position:'relative',
            fontWeight:'900'
        }
           const bdy={
               padding:'1%',
               width:'100%',
               height:'auto',
               boxShadow:'rgba(67, 71, 85, 0.27) 0px 0px 4px 0px, rgba(90, 125, 188, 0.05) 0px 4px 16px 0px'
           }
           const lml={
               color:'blue',
               fontWeight:'600',
               fontFamily:'Montserrat'
           }
           const kml={
            fontWeight:'600',
               fontFamily:'Lucida',
               boxShadow:'rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px',
               padding:'1%',
               textAlign:'left'
           }
           const lmc={
               height:'45px',
               width:'45px'
           }
           const jwl={
            border:'none',
            fontWeight:'500'
        }
        const vml={
            color:'red'
        }
        const lmk={
            color:'green',
            fontWeight:'600'
        }
        useEffect(() => {
            setIsLoaded(true);
        }, []);
        
        useEffect(() => {//on load
            if (isLoaded) {
             
            }
          }, [isLoaded]);
        
          return (
            <div className="cardiology">
         {isDesktopOrLaptop && (
             <>
             <CContainer fluid>
             <CRow>
             <CCol lg={2} xl={2} xxl={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
              </>
               )}
               {isTabletOrMobile && (
                    <>
                     <CContainer fluid>
             <CRow>
             <CCol sm={2} mb={2} lg={2} style={{textAlign:'left'}}>
             Ambulance
             </CCol>
             </CRow>
             </CContainer>
               </>
              )}
              {isMobile && (
                   <>
                 <CContainer fluid style={hsptl_mbl_hdr}>
                      <CRow>
                          <CCol xs={12} style={hsptl_nm}>Lifeline  Multi-Speciality Hospital Cardiology<MenuIcon  id="mnu_hsp" style={mnu_icn}/></CCol>
                     </CRow>
                   </CContainer>
                   <CContainer fluid style={bdy}>
                   
                         <CListGroup>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/heart.jfif"}/>Diagnosis of Heart Conditions    
                         <CListGroupItem style={jwl}><span style={lml}>Purpose</span>:Identifying heart problems through a variety of diagnostic tests and procedures.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lmk}>Common Diagnostic Tests</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Electrocardiogram (ECG/EKG)</span>:Measures electrical activity of the heart to detect abnormalities like arrhythmias, heart attacks, or other issues.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Echocardiogram</span>:An ultrasound of the heart to assess its structure and function, including how well the heart pumps blood.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Stress Test</span>:Monitors the heart's response to physical activity to detect issues like coronary artery disease (CAD).</CListGroupItem>                 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Holter Monitoring</span>: A continuous EKG recording over 24-48 hours to catch irregular heart rhythms.</CListGroupItem>  
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Cardiac Catheterization/Angiogram</span>: Invasive procedure to visualize the coronary arteries and assess blockages.</CListGroupItem>  
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Cardiac MRI or CT Scan</span>: Advanced imaging techniques to create detailed pictures of the heart and blood vessels.</CListGroupItem>  
                         </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/heart_treat.jfif"}/>Treatment of Heart Conditions    
                         <CListGroupItem style={jwl}><span style={lmk}>Medications</span>:Cardiology departments often prescribe medications to manage heart conditions, including.
                         <CListGroupItem style={jwl}>
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Blood Pressure Medications</span>:ACE inhibitors, beta-blockers, and diuretics for hypertension.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Cholesterol-lowering Drug</span>:Statins to reduce high cholesterol and prevent atherosclerosis.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Antiarrhythmic Drugs</span>:Medications to correct irregular heartbeats (arrhythmias).</CListGroupItem>                 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Anticoagulants</span>: Blood thinners to prevent clots in patients with atrial fibrillation or those at high risk for strokes.</CListGroupItem>  
                          </CListGroup>
                         </CListGroupItem> 
                         </CListGroupItem>

                         <CListGroupItem style={jwl}><span style={lmk}>Interventions and Procedures</span>
                         <CListGroupItem style={jwl}>
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Angioplasty and Stenting</span>:Non-surgical procedure to open blocked coronary arteries, often followed by placing a stent to keep the artery open.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Pacemaker/Implantable Defibrillator Implantation</span>:Devices implanted in the chest to regulate heartbeat or correct life-threatening arrhythmias.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Cardioversion</span>:Procedure to restore a normal heart rhythm in patients with arrhythmias like atrial fibrillation.</CListGroupItem>                 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Ablation</span>:Minimally invasive procedure to destroy small areas of the heart tissue causing abnormal electrical signals.</CListGroupItem> 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Coronary Artery Bypass Surgery (CABG)</span>:A major surgical procedure to bypass blocked coronary arteries using a graft from another part of the body.</CListGroupItem>  
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Valve Repair/Replacement</span>:Surgery to repair or replace damaged heart valves.</CListGroupItem>   
                          </CListGroup>
                         </CListGroupItem> 
                         </CListGroupItem>

                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/chronic_heart.jfif"}/>Management of Chronic Heart Conditions  
                         <CListGroupItem style={jwl}>
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Heart Failure</span>:Managing patients with heart failure through medications, lifestyle changes, and advanced treatments like ventricular assist devices (VADs).</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Hypertension (High Blood Pressure)</span>:Regular monitoring, medications, and lifestyle counseling to manage chronic high blood pressure.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Atrial Fibrillation</span>:Long-term management of irregular heart rhythms through medications, blood thinners, and sometimes catheter ablation or cardioversion.</CListGroupItem>                 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Coronary Artery Disease (CAD)</span>: Lifestyle modifications, medications, and interventional procedures to manage clogged or narrowed arteries.</CListGroupItem>  
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/preventive.jfif"}/>Preventive Cardiology   
                         <CListGroupItem style={jwl}><span style={lml}>Purpose</span>:Focuses on preventing heart disease before it develops by identifying and reducing risk factors.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lmk}>Services</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Screening for risk factors like high cholesterol, high blood pressure, and diabetes.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Counseling on diet, exercise, and lifestyle changes to lower heart disease risk.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Smoking cessation programs to help reduce heart attack and stroke risk.</CListGroupItem>                 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/>Preventive medications for high-risk patients (e.g., statins, aspirin therapy).</CListGroupItem>  
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/cardio_rehab.jfif"}/>Cardiac Rehabilitation 
                         <CListGroupItem style={jwl}><span style={lml}>Purpose</span>:A structured program of exercise and education aimed at helping patients recover after a heart attack, surgery, or diagnosis of heart disease.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lmk}>Components</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Supervised Exercise</span>: Gradual, monitored exercise programs to improve cardiovascular fitness.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Dietary Counseling</span>: Nutritional education to help patients adopt heart-healthy diets.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Stress Management</span>: Support in managing stress, which can impact heart health.</CListGroupItem>                 
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Risk Factor Reduction</span>: Addressing modifiable risk factors such as obesity, high cholesterol, and hypertension.</CListGroupItem>  
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/emergency_heart.jfif"}/>Emergency Care for Heart Conditions 
                         <CListGroupItem style={jwl}><span style={lml}>Purpose</span>:Immediate care for life-threatening heart emergencies.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lmk}>Common Emergencies</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Heart Attack (Myocardial Infarction)</span>: Immediate treatment to restore blood flow to the heart, often through angioplasty, stenting, or thrombolytic drugs (clot-busting drugs).</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Cardiac Arrest</span>: Emergency resuscitation, including CPR and the use of defibrillators to restart the heart.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Arrhythmias</span>: Emergency treatment for life-threatening irregular heartbeats with medications or electric shock (defibrillation).</CListGroupItem>                 
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/cardiac_surgery.jfif"}/>Cardiac Surgery
                         <CListGroupItem style={jwl}><span style={lml}>Cardiothoracic Surgeons</span>:Perform surgeries for complex heart conditions like bypass surgery, valve replacement, and heart transplants.</CListGroupItem> 
                         <CListGroupItem style={jwl}><span style={lmk}>Common Procedures</span>:
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Coronary Artery Bypass Graft (CABG)</span>: Creates new paths for blood to flow around blocked arteries.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Heart Valve Repair/Replacement</span>: Corrects defective heart valves.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Heart Transplant</span>: Replaces a failing heart with a donor heart.</CListGroupItem>                 
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/heart_failure.jfif"}/>Heart Failure Management and Advanced Therapies  
                         <CListGroupItem style={jwl}>
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Heart Failure Clinics</span>:Specialized programs for patients with advanced heart failure, providing regular monitoring, medications, and in some cases, device implantation.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Ventricular Assist Devices (VADs)</span>:Mechanical devices used to support heart function in patients awaiting heart transplants or those with severe heart failure.</CListGroupItem>
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         <CListGroupItem style={kml}><CImage style={lmc} fluid src={"http://localhost/Hospital/image/rd.jfif"}/>Research and Clinical Trials  
                         <CListGroupItem style={jwl}>
                         <CListGroup>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Purpose</span>:Many cardiology departments conduct clinical trials to test new medications, devices, and treatments for heart disease.</CListGroupItem>
                         <CListGroupItem style={jwl}><TbArrowBadgeRightFilled style={vml}/><span style={lml}>Involvement</span>:Patients may participate in research studies to access cutting-edge treatments and contribute to the advancement of cardiac care.</CListGroupItem>
                          </CListGroup>
                         </CListGroupItem>
                         </CListGroupItem>
                         </CListGroup>
                         <CListGroup><b>We have a team of experienced</b>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Cardiologist</CListGroupItem>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Cardiothoracic Surgeon</CListGroupItem>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Electrophysiologists</CListGroupItem>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Interventional Cardiologists</CListGroupItem>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Cardiology Nurses</CListGroupItem>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Cardiac Rehabilitation Specialists</CListGroupItem>
                         <CListGroupItem style={kml}><TbArrowBadgeRightFilled style={vml}/>Cardiac Technicians</CListGroupItem>
                         </CListGroup>
                   </CContainer>
                  <Footer/>
                   </>
                       )}
                       </div>
             );
         }
         
         export default Cardiology;